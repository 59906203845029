import { PropsWithChildren, useEffect, useReducer } from 'react';
import { useAuth } from '../Auth';
import Context from './Context';
import * as actions from './store/actions';
import reducer, { initialState } from './store/reducer';
import { IncidentType } from './store/types';

export default function Provider({
  persistKey = 'incidents',
  children,
}: PropsWithChildren<{ persistKey?: string }>) {
  const { auth } = useAuth();

  const data = localStorage.getItem(persistKey);
  const json = data && JSON.parse(data);

  const [incidents, dispatch] = useReducer(reducer, json || initialState);

  useEffect(() => {
    try {
      if (auth.isAuthenticated) {
        localStorage.setItem(persistKey, JSON.stringify(incidents));
      }
    } catch (error) {
      console.warn(error);
    }
  }, [auth.isAuthenticated, incidents, persistKey]);

  useEffect(() => {
    if (!auth.isAuthenticated) {
      try {
        localStorage.removeItem(persistKey);
      } catch (error) {
        console.warn(error);
      }  
      dispatch(actions.reset());
    }
  }, [auth.isAuthenticated, persistKey]);

  const addIncident = (incident: IncidentType) => {
    dispatch(actions.addIncident(incident));
  };

  const updateIncident = (incident: IncidentType) => {
    dispatch(actions.updateIncident(incident));
  };

  const synchroniseIncidents = (incidents: IncidentType[]) => {
    dispatch(actions.synchronise(incidents));
  };

  return (
    <Context.Provider value={{ incidents, addIncident, updateIncident, synchroniseIncidents }}>
      {children}
    </Context.Provider>
  );
}
