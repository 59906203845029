import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Image from '../../components/Image';
import SectionHeader from '../../components/SectionHeader';
import HeroShaped from '../../components/HeroShaped';
import { ResetPasswordForm, ResetPasswordSubmitForm } from './components';

const useStyles = makeStyles(theme => {
  const toolbar = theme.mixins.toolbar as any;

  return ({
    root: {
      '& .hero-shaped': {
        borderBottom: 0,
      },
      '& .hero-shaped__wrapper': {
        [theme.breakpoints.up('md')]: {
          minHeight: `calc(100vh - ${toolbar['@media (min-width:600px)'].minHeight}px)`,
        },
      },
    },
    formContainer: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        maxWidth: 500,
        margin: `0 auto`,
      },
    },
    image: {
      objectFit: 'cover',
    },
  });
});

export default function ResetPassword() {
  const classes = useStyles();
  const [state, setState] = useState<{ email: string; destination: string; }>();

  return (
    <div className={classes.root}>
      <HeroShaped
        leftSide={
          !state ? (
            <div className={classes.formContainer}>
              <SectionHeader
                title="Reset Password"
                subtitle="Enter your email address and we will send you an email with instructions to reset your password."
                subtitleProps={{
                  variant: 'body1',
                  color: 'textPrimary',
                }}
                align="left"
              />
              <ResetPasswordForm onForgotPasswordSubmit={
                (email, destination) => setState({ email, destination })
              } />
            </div>
          ) : (
            <div className={classes.formContainer}>
              <SectionHeader
                title="Reset Password"
                subtitle={`Please insert the code sent to ${state.destination} and your new password. The new password must have at least 8 characters, with at least one lowercase letter, one uppercase letter and one digit.`}
                subtitleProps={{
                  variant: 'body1',
                  color: 'textPrimary',
                }}
                align="left"
              />
              <ResetPasswordSubmitForm email={state.email} />
            </div>
          )
        }
        rightSide={
          <Image
            src="https://source.unsplash.com/random"
            className={classes.image}
            lazy={false}
          />
        }
      />
    </div>
  );
}
