import { UsersActionType, UserType, UsersStateType } from './types';

export function setUsers(users: UserType[]) {
  return {
    type: UsersActionType.SetUsers as const, 
    users,
  };
}

export function updateUser(user: UserType) {
  return {
    type: UsersActionType.UpdateUser as const, 
    user,
  };
}

export function addUser(user: UserType) {
  return {
    type: UsersActionType.AddUser as const, 
    user,
  };
}

export function reset(state: UsersStateType) {
  return {
    type: UsersActionType.Reset as const,
    state,
  };
}
