import { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AddIcon from '@material-ui/icons/Add';
import TreeItem from '@material-ui/lab/TreeItem';
import type {
  FurtherSubcategory,
  Subcategory,
  Category,
  Location,
} from '../../../../providers/Locations/types';
import Loading from '../../../../components/Loading';
import * as ApiClient from '../../../../services/ApiClient';
import { useLocations } from '../../../../providers/Locations';
import { useNotifications } from '../../../../providers/Notifications';
import AddCategoryDialog from './AddCategoryDialog';
import AddSubcategoryDialog from './AddSubcategoryDialog';
import AddFurtherSubcategoryDialog from './AddFurtherSubcategoryDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    tree: {
      flexGrow: 1,
    },
    labelRoot: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0.5, 0)
    },
    labelText: {
      fontWeight: "inherit",
      flexGrow: 1
    },  
  }),
);

function FurtherSubcategoryItem({ furtherSubcategory }: { furtherSubcategory: FurtherSubcategory }) {
  const classes = useStyles();

  return (
    <TreeItem
      nodeId={furtherSubcategory.id}
      label={
        <div className={classes.labelRoot}>
          <Typography variant="subtitle1" className={classes.labelText}>
            { furtherSubcategory.title }
          </Typography>
        </div>
      }
    />
  );
}

function SubcategoryItem({ location, category, subcategory }: { location: Location; category: Category; subcategory: Subcategory }) {
  const classes = useStyles();
  const showAlert = useNotifications();
  const [showAdd, setShowAdd] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { updateLocations, locations } = useLocations();

  return (
    <>
      <TreeItem
        nodeId={subcategory.id}
        label={
          <div className={classes.labelRoot}>
            <Typography variant="subtitle1" className={classes.labelText}>
              { subcategory.title }
            </Typography>
            <IconButton
              aria-label="Add"
              color="inherit"
              onClick={(event: any) => {
                event.preventDefault();
                setShowAdd(true);              
              }}
            >
              <AddIcon />
            </IconButton>
          </div>
        }
      >
        {
          Array.isArray(subcategory.furtherSubcategories)
            ? subcategory.furtherSubcategories.map(furtherSubcategory => (<FurtherSubcategoryItem key={furtherSubcategory.id} furtherSubcategory={furtherSubcategory} />))
            : null
        }
      </TreeItem>
      {
        isLoading && (
          <Loading fullScreen />
        )
      }
      <AddFurtherSubcategoryDialog
        open={showAdd}
        onConfirm={async (title: string) => {
          setShowAdd(false);
          setIsLoading(true);

          try {
            if (title.replace(/[^\w]+/g, '_') === '' || subcategory.furtherSubcategories.map(value => value.id).includes(title.replace(/[^\w]+/g, '_'))) {
              throw new Error();
            }

            const newLocations = locations.map(otherLocation => otherLocation.id === location.id ? {
              ...location,
              categories: location.categories.map(otherCategory => otherCategory.id === category.id ? {
                ...category,
                subcategories: category.subcategories.map(otherSubcategory => otherSubcategory.id === subcategory.id ? {
                  ...subcategory,
                  furtherSubcategories: subcategory.furtherSubcategories.concat({
                    id: title.replace(/[^\w]+/g, '_'),
                    title,
                  })
                } : otherSubcategory),
              } : otherCategory),
            } : otherLocation);

            const result = await ApiClient.updateLocations(newLocations);

            updateLocations(result);

            showAlert({
              message: 'Added subcategory level 2 successfully.',
              severity: 'success',
            });
          } catch (error) {
            showAlert({
              message: 'Failed to add subcategory level 2.',
              severity: 'error',
            });
          }

          setIsLoading(false);
        }}
        onCancel={() => setShowAdd(false)}
      />
    </>
  );
}

function CategoryItem({ location, category }: { location: Location, category: Category }) {
  const classes = useStyles();
  const showAlert = useNotifications();
  const [showAdd, setShowAdd] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { updateLocations, locations } = useLocations();

  return (
    <>
      <TreeItem
        nodeId={category.id}
        label={
          <div className={classes.labelRoot}>
            <Typography variant="subtitle1" className={classes.labelText}>
              { category.title }
            </Typography>
            <IconButton
              aria-label="Add"
              color="inherit"
              onClick={(event: any) => {
                event.preventDefault();
                setShowAdd(true);              
              }}
            >
              <AddIcon />
            </IconButton>
          </div>
        }
      >
        {
          Array.isArray(category.subcategories)
            ? category.subcategories.map(subcategory => (
              <SubcategoryItem
                key={subcategory.id}
                location={location}
                category={category}
                subcategory={subcategory}
              />))
            : null
        }
      </TreeItem>
      {
        isLoading && (
          <Loading fullScreen />
        )
      }
      <AddSubcategoryDialog
        open={showAdd}
        onConfirm={async (title: string) => {
          setShowAdd(false);
          setIsLoading(true);

          try {
            if (title.replace(/[^\w]+/g, '_') === '' || category.subcategories.map(value => value.id).includes(title.replace(/[^\w]+/g, '_'))) {
              throw new Error();
            }

            const newLocations = locations.map(otherLocation => otherLocation.id === location.id ? {
              ...location,
              categories: location.categories.map(otherCategory => otherCategory.id === category.id ? {
                ...category,
                subcategories: category.subcategories.concat({
                  id: title.replace(/[^\w]+/g, '_'),
                  title,
                  furtherSubcategories: [],
                }),
              } : otherCategory),
            } : otherLocation);

            const result = await ApiClient.updateLocations(newLocations);

            updateLocations(result);

            showAlert({
              message: 'Added subcategory level 1 successfully.',
              severity: 'success',
            });
          } catch (error) {
            showAlert({
              message: 'Failed to add subcategory level 1.',
              severity: 'error',
            });
          }

          setIsLoading(false);
        }}
        onCancel={() => setShowAdd(false)}
      />
    </>
  );
}

function LocationItem({ location }: { location: Location }) {
  const classes = useStyles();
  const showAlert = useNotifications();
  const [showAdd, setShowAdd] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { updateLocations, locations } = useLocations();
  
  return (
    <>
      <TreeItem
        nodeId={location.id}
        label={
          <div className={classes.labelRoot}>
            <Typography variant="subtitle1" className={classes.labelText}>
              { location.title }
            </Typography>
            <IconButton
              aria-label="Add"
              color="inherit"
              onClick={(event: any) => {
                event.preventDefault();
                setShowAdd(true);              
              }}
            >
              <AddIcon />
            </IconButton>
          </div>
        }
      >
        {
          Array.isArray(location.categories)
            ? location.categories.map(category => (
              <CategoryItem
                key={category.id}
                location={location}
                category={category}
              />
            ))
            : null
        }
      </TreeItem>
      {
        isLoading && (
          <Loading fullScreen />
        )
      }
      <AddCategoryDialog
        open={showAdd}
        onConfirm={async (title: string) => {
          setShowAdd(false);
          setIsLoading(true);

          try {
            if (title.replace(/[^\w]+/g, '_') === '' || location.categories.map(value => value.id).includes(title.replace(/[^\w]+/g, '_'))) {
              throw new Error();
            }

            const newLocations = locations.map(otherLocation => otherLocation.id === location.id ? {
              ...location,
              categories: location.categories.concat({
                id: title.replace(/[^\w]+/g, '_'),
                title,
                subcategories: [],
              }),
            } : otherLocation);

            const result = await ApiClient.updateLocations(newLocations);

            updateLocations(result);

            showAlert({
              message: 'Added category successfully.',
              severity: 'success',
            });
          } catch (error) {
            showAlert({
              message: 'Failed to add category.',
              severity: 'error',
            });
          }

          setIsLoading(false);
        }}
        onCancel={() => setShowAdd(false)}
      />
    </>
  );
}

interface Props {
  locations: Location[];
}

export default function LocationTree({ locations }: Props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TreeView
        className={classes.tree}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
      >
        {
          locations.map(location => (
            <LocationItem
              key={location.id}
              location={location}
            />
          ))
        }
      </TreeView>
    </div>
  );
}