import { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Button, Typography, useMediaQuery } from '@material-ui/core';
import { useAuth } from '../../../../providers/Auth';
import { useTemplates } from '../../../../providers/Templates';
import { useNotifications } from '../../../../providers/Notifications';
import * as ApiClient from '../../../../services/ApiClient';
import { buildAttachment } from '../../../../utils/attachments';
import Loading from '../../../../components/Loading';
import FileUpload from '../../../../components/FileUpload';
import DiscardChangesDialog from './DiscardChangesDialog';
import SaveChangesDialog from './SaveChangesDialog';

const useStyles = makeStyles(theme => ({
  form: {
    '& .MuiTextField-root': {
      background: theme.palette.background.paper,
    },
    '& .MuiOutlinedInput-input': {
      background: theme.palette.background.paper,
    },
  },
  inputTitle: {
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
  uploadButton: {
    display: 'flex',
    justifyContent: 'center',
    border: '1px solid transparent',
    background: theme.palette.alternate.dark,
    textTransform: 'lowercase',
    '& .icon-text': {
      width: 'auto',
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start',
    },
  },
  submitButton: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start',
    },
  },
  closeContainer: {
    marginTop: theme.spacing(12),
  },
  closeButton: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start',
    },
  }
}));

type FormState = {
  attachments: {
    id: string;
    name: string;
    type: string;
    size: number;
    url: string;
  }[];
  files: File[];
};

interface Props {
  templates: FormState;
}

export default function TemplatesForm({ templates }: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const showAlert = useNotifications();
  const { auth } = useAuth();
  const { updateTemplates } = useTemplates();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [showDiscard, setShowDiscard] = useState(false);
  const [showSave, setShowSave] = useState(false);

  if (!auth.isAuthenticated) {
    return null;
  }

  if (hasSubmitted) {
    return <Redirect to="/admin" />;
  }

  return (
    <div className={classes.form}>
      <Formik
        initialValues={templates}
        onSubmit={
          async (values) => {
            const attachments = [...values.attachments];

            for (let file of values.files) {
              const attachment = buildAttachment(file);
              await ApiClient.uploadImage(attachment.key, file);
              attachments.push({
                id: attachment.id,
                name: attachment.name,
                type: attachment.type,
                size: attachment.size,
                url: attachment.url,
              });
            }
                
            const result = await ApiClient.updateTemplates(attachments);

            showAlert({
              message: 'Updated templates successfully.',
              severity: 'success',
            });

            updateTemplates(result);

            Promise.resolve().then(() => setHasSubmitted(true));
          }
        }
      >
        {
          ({ submitForm, isSubmitting, values, setFieldValue, setSubmitting }) => (
            <>
              <Form>
                <Grid container spacing={isMd ? 4 : 2}>
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle1"
                      color="textPrimary"
                      className={classes.inputTitle}
                    >
                      Attachments
                    </Typography>
                    <Field
                      component={FileUpload}
                      name="files"
                      variant="outlined"
                      disabled={isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button
                      size="large"
                      variant="outlined"
                      type="button"
                      color="primary"
                      name="saveDraft"
                      fullWidth
                      disabled={isSubmitting}
                      onClick={() => setShowDiscard(true)}
                      className={classes.submitButton}
                    >
                      Discard changes
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button
                      size="large"
                      variant="contained"
                      type="button"
                      color="primary"
                      name="submit"
                      fullWidth
                      disabled={isSubmitting}
                      onClick={() => setShowSave(true)}
                      className={classes.submitButton}
                    >
                      Save changes
                    </Button>
                  </Grid>
                </Grid>
              </Form>
              {
                isSubmitting && (
                  <Loading fullScreen />
                )
              }
              <DiscardChangesDialog
                open={showDiscard}
                onConfirm={() => {
                  setShowDiscard(false);
                  setHasSubmitted(true);
                }}
                onCancel={() => {
                  setShowDiscard(false);
                }}
              />
              <SaveChangesDialog
                open={showSave}
                onConfirm={async () => {
                  setSubmitting(true);
                  setShowSave(false);
                  await submitForm();
                }}
                onCancel={() => {
                  setShowSave(false);
                }}
              />
            </>
          )
        }
      </Formik>
    </div>
  );
}
