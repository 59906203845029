import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 700,
  },
}));

interface Props {
  className?: string;
  title: string;
  subtitle?: string;
  icon: JSX.Element;
  align?: 'left' | 'right' | 'center';
  titleVariant?: 'inherit' | 'button' | 'overline' | 'caption' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'subtitle1' | 'subtitle2' | 'body1' | 'body2' | 'srOnly' | undefined;
  subtitleVariant?: 'inherit' | 'button' | 'overline' | 'caption' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'subtitle1' | 'subtitle2' | 'body1' | 'body2' | 'srOnly' | undefined;
  titleProps?: object;
  subtitleProps?: object;
}

export default function DescriptionListIcon({
  title,
  subtitle,
  icon,
  align = 'center',
  titleVariant = 'h6',
  subtitleVariant = 'body1',
  className,
  titleProps = {},
  subtitleProps = {},
}: Props) {
  const classes = useStyles();

  let gridJustify: ('center' | 'flex-start' | 'flex-end' | 'space-between' | 'space-around' | 'space-evenly' | undefined) = 'center';

  if (align === 'left') {
    gridJustify = 'flex-start';
  } else if (align === 'right') {
    gridJustify = 'flex-end';
  }

  return (
    <Grid
      container
      spacing={2}
      className={className}
    >
      <Grid
        item
        container
        justify={gridJustify}
        xs={12}
      >
        {icon}
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant={titleVariant}
          color="textPrimary"
          align={align}
          className={classes.title}
          {...titleProps}
        >
          {title}
        </Typography>
      </Grid>
      {subtitle && (
        <Grid item xs={12}>
          <Typography
            variant={subtitleVariant}
            color="textSecondary"
            align={align}
          >
            {subtitle}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}
