import { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Section from '../../../../components/Section';
import SectionHeader from '../../../../components/SectionHeader';
import { useLocations } from '../../../../providers/Locations';
import LocationTree from './LocationTree';
import LocationsFab from './LocationsFab';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  sectionNoPaddingTop: {
    paddingTop: 0,
  },
}));

function Locations() {
  const classes = useStyles();
  const { locations } = useLocations();

  return (
    <Box className={classes.root}>
      <Section className={classes.sectionNoPaddingTop}>
        <div>
          <SectionHeader title="Locations" align="left" />
          <LocationTree locations={locations} />
          <LocationsFab />
        </div>
      </Section>
    </Box>
  );
}

export default memo(Locations);
