import { forwardRef, memo } from 'react';
import {
  BarChart as RawBarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Text,
} from 'recharts';
interface LabelProps {
  x: number;
  y: number;
  width: number;
  value: number;
}

function CustomLabel({ x, y, width, value }: LabelProps) {
  return (
    <Text 
      x={x} 
      y={y}
      dx={width / 2}
      dy={-10}
      fill="#000000"
      textAnchor="middle"
    >
      {value}
    </Text>
  );
}

function CustomLegendText({ value }: { value: string }) {
  return <span style={{ color: '#000000' }}>{value}</span>;
}

interface AxisTickProps {
  x: number;
  y: number;
  payload: { value: number; };
}

function CustomAxisTick({ x, y, payload }: AxisTickProps) {
  return (
    <Text x={x} y={y} width={75} textAnchor="middle" verticalAnchor="start">
      {payload.value}
    </Text>
  );
}

function CustomAxisTickAlternative({ x, y, payload }: AxisTickProps) {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} width={50} textAnchor="end" fill="#666" transform="rotate(-35)">
        {payload.value}
      </text>
    </g>
  );
}

interface Props {
  data: {
    title: string;
    xLabel: string;
    yLabel1: string;
    values: {
      name: string;
      value1: number;
    }[];
  };
}

export default memo(forwardRef(({ data }: Props, ref: any) => (
  <ResponsiveContainer width="99%" aspect={2}>
    <RawBarChart
      ref={ref}
      data={data.values}
      margin={{ top: 25, right: 25, left: 25, bottom: 25 }}
    >
      <CartesianGrid vertical={false} />
      <XAxis
        dataKey="name"
        {...(data.xLabel ? { name: data.xLabel } : {})}
        tick={data.values.length > 8 ? CustomAxisTickAlternative : CustomAxisTick}
        height={100}
        interval={0}
      />
      <YAxis
        dataKey="value1"
        {...(data.yLabel1 ? { name: data.yLabel1 } : {})}
      />
      <Tooltip />
      <Bar
        fill="rgb(17, 82, 147)"
        dataKey="value1"
        label={CustomLabel}
        name={data.yLabel1}
      />
      {
        !!data.xLabel && (
          <Legend
            formatter={() => <CustomLegendText value={data.xLabel} />}
            layout="vertical"
            verticalAlign="bottom"
          />
        )
      }
      
    </RawBarChart>
  </ResponsiveContainer>
)));
