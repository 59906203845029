import { memo } from 'react';
import { Link as RouterLink } from 'react-router-dom'
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import PageviewIcon from '@material-ui/icons/Pageview';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ReactMarkdown from 'react-markdown';
import { useUsers } from '../../providers/Users';
import { useInvestigations } from '../../providers/Investigations';
import { formatDate, formatDateTime } from '../../utils/incidents';
import IncidentStatus from '../IncidentStatus';
import Tooltip from '../Tooltip';
import { DecoratedData, HeadCell } from './types';

const useStyles = makeStyles(() =>
  createStyles({
    rowCell: {
      height: 83,
    },
    rowValue: {
      maxHeight: 70,
      overflow: 'auto',
    },
  }),
);

interface Props {
  row: DecoratedData;
  filteredHeadCells: HeadCell[];
}

type ValueOf<T> = T[keyof T];

function IncidentRow({
  row,
  filteredHeadCells,
}: Props) {
  const classes = useStyles();
  const { userMap } = useUsers();
  const { investigationMap } = useInvestigations();

  const getLabel = (value: ValueOf<DecoratedData>, headCell: HeadCell) => {
    if (headCell.id === 'status' && typeof value === 'string') {
      return <IncidentStatus value={value} />;
    }
  
    if (headCell.id === 'incidentJustified') {
      return value !== false ? 'Yes' : 'No';
    }
  
    if (!value) {
      return '—';
    }

    if (headCell.id === 'reportedBy' && typeof value === 'string') {
      const user = userMap.get(value);

      if (user) {
        return user.name;
      }
    }

    if (headCell.id === 'description' && typeof value === 'string') {
      return (
        <div className={classes.rowValue}>
          <ReactMarkdown
            source={value || '—'}
            renderers={{ paragraph: Typography }}
          />
        </div>
      );
    }

    if (headCell.id === 'investigationId' && typeof value === 'string') {
      const investigation = investigationMap.get(value);

      if (investigation) {
        return (
          <Link component={RouterLink} to={`/investigation/${value}`}>
            { investigation.status === 'opened' ? 'Opened' : 'Completed' }
          </Link>
        );
      }
    }

    if (headCell.id === 'incidentDate' && typeof value === 'string') {
      return formatDate(value);
    }

    if ((headCell.id === 'created_at' || headCell.id === 'updated_at') && typeof value === 'string') {
      return formatDateTime(value);
    }

    return (
      <div className={classes.rowValue}>
        { typeof value === 'object' ? 'title' in value && value.title : value }
      </div>
    );
  };

  return (
    <TableRow
      hover
      tabIndex={-1}
      key={row.id}
    >
      <TableCell style={{ minWidth: 140 }}>
        <div className={classes.rowValue}>
        <Tooltip
          title="Incident details"
          placement="top-start"
        >
          <IconButton component={RouterLink} to={`/incident/${row.id}`}>
            <PageviewIcon />
          </IconButton>
        </Tooltip>
          {
            row.investigationId && (
              <Tooltip
                title="Investigation details"
                placement="top-start"
              >
                <IconButton component={RouterLink} to={`/investigation/${row.investigationId}`}>
                  <AssignmentIcon />
                </IconButton>
              </Tooltip>
            )
          }
        </div>
      </TableCell>
      {
        filteredHeadCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            className={classes.rowCell}
          >
            {getLabel(row[headCell.id], headCell)}
          </TableCell>
        ))
      }
    </TableRow>
  );
}

export default memo(IncidentRow);