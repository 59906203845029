import React from 'react';
import type { Template } from './types';

type ContextType = null | {
  isLoading: boolean;
  templates: Template[];
  updateTemplates: (templates: Template[]) => unknown;
};

export const Context = React.createContext<ContextType>(null);

export default Context;
