import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(4),
    },
  },
  disableGutter: {
    marginBottom: 0,
  },
  title: {
    fontWeight: 'bold',
  },
  cta: {
    marginLeft: theme.spacing(1),
    '&:first-child': {
      marginLeft: theme.spacing(0),
    },
  },
}));

interface Props {
  className?: string;
  title: string | JSX.Element;
  subtitle?: string | JSX.Element;
  label?: string;
  overline?: JSX.Element;
  ctaGroup?: Array<JSX.Element>;
  align?: 'right' | 'left' | 'center';
  disableGutter?: boolean;
  titleClasses?: string;
  titleVariant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  subtitleVariant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'subtitle1' | 'subtitle2' | 'body1' | 'body2';
  subtitleColor?: 'textPrimary' | 'textSecondary' | 'primary' | 'secondary';
  labelProps?: object;
  titleProps?: object;
  subtitleProps?: object;
}

export default function SectionHeader({
  title,
  titleVariant = 'h4',
  subtitleVariant,
  subtitle,
  subtitleColor,
  label,
  overline,
  align,
  ctaGroup,
  disableGutter,
  titleClasses,
  className,
  labelProps = {},
  titleProps = {},
  subtitleProps = {},
}: Props) {
  const classes = useStyles();
  let justifyGrid: ('center' | 'flex-start' | 'flex-end' | 'space-between' | 'space-around' | 'space-evenly' | undefined) = 'center';
  if (align === 'left') {
    justifyGrid = 'flex-start';
  } else if (align === 'right') {
    justifyGrid = 'flex-end';
  }

  return (
    <Grid
      container
      spacing={2}
      className={clsx(
        classes.root,
        disableGutter && classes.disableGutter,
        className,
      )}
    >
      {
        overline && (
          <Grid
            item
            container
            justify={justifyGrid}
            xs={12}
          >
            {overline}
          </Grid>
        )
      }
      {
        label && (
          <Grid item xs={12}>
            <Typography
              variant="overline"
              color="primary"
              component="p"
              align={align || 'center'}
              {...labelProps}
            >
              {label}
            </Typography>
          </Grid>
        )
      }
      <Grid item xs={12}>
        <Typography
          variant={titleVariant}
          align={align || 'center'}
          className={clsx(
            classes.title,
            titleClasses,
          )}
          color="textPrimary"
          {...titleProps}
        >
          {title}
        </Typography>
      </Grid>
      {
        subtitle && (
          <Grid item xs={12}>
            <Typography
              variant={subtitleVariant || 'h6'}
              align={align || 'center'}
              color={subtitleColor || 'textSecondary'}
              {...subtitleProps}
            >
              {subtitle}
            </Typography>
          </Grid>
        )
      }
      {
        ctaGroup && ctaGroup.length && (
          <Grid item xs={12}>
            <Grid
              container
              justify={justifyGrid}
              alignItems="center"
              wrap="nowrap"
            >
              {ctaGroup.map((item, index) => (
                <div
                  key={index}
                  className={classes.cta}
                >
                  {item}
                </div>
              ))}
            </Grid>
          </Grid>
        )
      }
    </Grid>
  );
}
