import { v1 as uuid } from 'uuid';
import mime from 'mime-types';

const {
  REACT_APP_STORAGE_BUCKET,
  REACT_APP_STORAGE_REGION,
} = process.env

const S3_PATH = `https://${REACT_APP_STORAGE_BUCKET}.s3.${REACT_APP_STORAGE_REGION}.amazonaws.com`

export function buildAttachment(file: File) {
  const id = uuid()
  const extension = mime.extension(file.type);
  const key = `organisations/${id}.${extension}`;
  const url = `${S3_PATH}/public/${key}`;

  return {
    id,
    name: file.name,
    type: file.type,
    size: file.size,
    key,
    url,
  }
}
