import { IncidentsActionType, IncidentType } from './types';

export function addIncident(incident: IncidentType) {
  return {
    type: IncidentsActionType.AddIncident as const, 
    incident,
  };
}

export function updateIncident(incident: IncidentType) {
  return {
    type: IncidentsActionType.UpdateIncident as const, 
    incident,
  };
}

export function synchronise(incidents: IncidentType[]) {
  return {
    type: IncidentsActionType.SynchroniseIncidents as const,
    incidents,
  };
}

export function reset() {
  return {
    type: IncidentsActionType.ResetIncidents as const,
  };
}
