import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import Loading from '../../components/Loading';
import { useAuth } from '../../providers/Auth';
import { logout } from '../../services/ApiClient';

export default function Logout() {
  const { auth } = useAuth();

  useEffect(() => {
    logout();
  }, []);

  return auth.isAuthenticated ? (
    <Loading fullScreen />
  ) : (
    <Redirect to={{ pathname: '/login' }} />
  );
}
