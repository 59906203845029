import { ComponentType, ReactNode } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useAuth } from '../../providers/Auth';

interface Props<T extends RouteProps> {
  component: ComponentType<T>;
  layout: ComponentType<{ children: ReactNode }>;
  exact?: boolean;
  path: string;
  redirectTo?: string;
}

export default function AuthenticatedRoute<T extends RouteProps>({
  component: Component,
  layout: Layout,
  redirectTo,
  ...rest
}: Props<T>) {
  const { auth } = useAuth();

  return (
    <Route
      {...rest}
      render={
        ((props: T) =>
          auth.isAuthenticated ? (
            redirectTo ? (
              <Redirect
                to={{
                  pathname: redirectTo,
                  search: `from=${props.location?.pathname}`,
                  state: { from: props.location },
                }}
              />
            ) : (
              <Layout>
                <Component {...props} />
              </Layout>
            )
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                search: `from=${props.location?.pathname}`,
                state: { from: props.location },
              }}
            />
          )
        ) as any
      }
    />
  );
}
