import React, { memo } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    wrapper: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
);

interface Props {
  fullScreen?: boolean;
}

function CircularIndeterminate({ fullScreen = false }: Props) {
  const classes = useStyles();

  if (fullScreen) {
    return (
      <Backdrop className={classes.backdrop} open={true}>
        <CircularProgress color="inherit" size={50} />
      </Backdrop>
    );
  }

  return (
    <div className={classes.wrapper}>
      <CircularProgress size={50} />
    </div>
  );
}

export default memo(CircularIndeterminate);
