import { memo, useEffect, useState } from 'react';
import { Container, Draggable } from 'react-smooth-dnd';
import arrayMove from 'array-move';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Switch from '@material-ui/core/Switch';
import DragHandleIcon from '@material-ui/icons/DragHandle';

interface Item {
  id: string;
  label: string;
  selected: boolean;
}

interface Props<T extends Item> {
  initialItems: T[];
  onUpdate: (items: T[]) => unknown;
}

function SortableFilterList<T extends Item>({ initialItems, onUpdate }: Props<T>) {
  const [items, setItems] = useState(initialItems);

  const onDrop = ({ removedIndex, addedIndex }: {
    removedIndex: number | null;
    addedIndex: number | null;
  }) => {
    setItems(items => arrayMove(items, removedIndex!, addedIndex!));
  };

  const handleSelect = (id: string, selected: boolean) => {
    setItems(items => items.map(item => {
      if (item.id !== id) {
        return item;
      }

      return {
        ...item,
        selected,
      };
    }))
  }

  useEffect(() => {
    if (items !== initialItems) {
      onUpdate(items);
    }
  }, [items]);

  return (
    <List>
      <Container dragHandleSelector='.drag-handle' lockAxis='y' onDrop={onDrop}>
        {
          items.map(({ id, label, selected }) => (
            <Draggable key={id}>
              <ListItem>
                <ListItemText primary={label} />
                <ListItemIcon className='drag-handle'>
                  <Switch
                    color="primary"
                    checked={selected}
                    onChange={() => handleSelect(id, !selected)}
                  />
                </ListItemIcon>
                <ListItemIcon className='drag-handle'>
                  <DragHandleIcon />
                </ListItemIcon>
              </ListItem>
            </Draggable>
          ))
        }
      </Container>
    </List>
  );
}

export default memo(SortableFilterList);
