import { memo, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import type { UserType } from '../../providers/Users/store/types';

interface Props {
  open: boolean;
  initialUser: UserType;
  onConfirm: (role: string) => unknown;
  onCancel: () => unknown;
}

const options = ['user', 'investigator', 'quality', 'admin'];

function EditRoleDialog({ initialUser, open, onConfirm, onCancel }: Props) {
  const [role, setRole] = useState(() => initialUser.role);

  const handleSave = () => {
    onConfirm(role);
  };

  const handleUpdate = (_: object, role: any) => {
    if (role) {
      setRole(role);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      disableBackdropClick
    >
      <DialogTitle id="scroll-dialog-title">User Role</DialogTitle>
      <DialogContent>
        <Autocomplete
          options={options}
          value={role}
          onChange={handleUpdate}
          renderInput={props => <TextField {...props} variant="outlined" />}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Update Role
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default memo(EditRoleDialog);
