import { Redirect, RouteComponentProps } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import DashboardBreadcrums from '../../components/DashboardBreadcrums';
import Section from '../../components/Section';
import { useUsers } from '../../providers/Users';
import { UserDetails } from './components';

const useStyles = makeStyles(theme => {
  return ({
    pagePaddingTop: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      [theme.breakpoints.up('md')]: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
      },
    }, 
    sectionNoPaddingTop: {
      paddingTop: 0,
    },
  });
});

export default function User({ match }: RouteComponentProps<{ id: string }>) {
  const { users } = useUsers();
  const classes = useStyles();

  const userId = match.params.id;
  const user = users.users.find(user => user.user_id === userId);

  if (!user) {
    return <Redirect to="/not-found" />;
  }

  return (
    <div>
      <Section className={classes.pagePaddingTop}>
        <DashboardBreadcrums
          breadcrums={[
            { name: 'Home', path: '/' },
            { name: 'Users', path: '/users' },
            { name: user.name, path: `/users/${user.user_id}` },
          ]}
        />
      </Section>
      <Section className={classes.sectionNoPaddingTop}>
        <div>
          <UserDetails user={user} />
        </div>
      </Section>
    </div>
  );
}
