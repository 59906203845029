import React, { memo, useMemo, useState } from 'react';
import { format } from 'date-fns';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import type { Filter as RawFilter } from '../ValueFilter'
import { Data } from './types';
import IntelligenceRow from './IntelligenceRow';
import FilterCell from './FilterCell';
import useData from './useData';

interface Filter {
  id: keyof Data | 'assignedTo' | 'incidentDescription' | 'incidentJustified' | 'containmentAction' | 'containmentActionStatus' | 'containmentActionSignedBy' | 'containmentActionSignedDate' | 'rootCause' | 'rootCauseCategory' | 'rootCauseStatus' | 'rootCauseSignedBy' | 'rootCauseSignedDate' | 'correctiveAction' | 'correctiveActionStatus' | 'correctiveActionSignedBy' | 'correctiveActionSignedDate' | 'verificationOfEffectiveness' | 'verificationOfEffectivenessStatus' | 'verificationOfEffectivenessSignedBy' | 'verificationOfEffectivenessSignedDate' | 'preventiveAction' | 'preventiveActionStatus' | 'preventiveActionSignedBy' | 'preventiveActionSignedDate' | 'costOfLabour' | 'costOfMaterial' | 'costAdjustment' | 'costTotal' | 'completedStatus' | 'completedSignedBy' | 'completedSignedDate';
}

const filter = [
  { id: 'incidentNumber' },
  { id: 'incidentDate' },
  { id: 'reportedBy' },
  { id: 'product' },
  { id: 'partNumber' },
  { id: 'grnNumber' },
  { id: 'orderNumber' },
  { id: 'defectiveQuantity' },
  { id: 'incidentLocation' },
  { id: 'category' },
  { id: 'subcategory' },
  { id: 'furtherSubcategory' },
  { id: 'description' },
  { id: 'assignedTo' },
  { id: 'incidentDescription' },
  { id: 'incidentJustified' },
  { id: 'containmentAction' },
  { id: 'containmentActionStatus' },
  { id: 'containmentActionSignedBy' },
  { id: 'containmentActionSignedDate' },
  { id: 'rootCause' },
  { id: 'rootCauseCategory' },
  { id: 'rootCauseStatus' },
  { id: 'rootCauseSignedBy' },
  { id: 'rootCauseSignedDate' },
  { id: 'correctiveAction' },
  { id: 'correctiveActionStatus' },
  { id: 'correctiveActionSignedBy' },
  { id: 'correctiveActionSignedDate' },
  { id: 'verificationOfEffectiveness' },
  { id: 'verificationOfEffectivenessStatus' },
  { id: 'verificationOfEffectivenessSignedBy' },
  { id: 'verificationOfEffectivenessSignedDate' },
  { id: 'preventiveAction' },
  { id: 'preventiveActionStatus' },
  { id: 'preventiveActionSignedBy' },
  { id: 'preventiveActionSignedDate' },
  { id: 'costOfLabour' },
  { id: 'costOfMaterial' },
  { id: 'costAdjustment' },
  { id: 'costTotal' },
  { id: 'completedStatus' },
  { id: 'completedSignedBy' },
  { id: 'completedSignedDate' },
  { id: 'status' },
] as Filter[];

const headCells = filter.map(filter => ({
  disablePadding: false,
  id: filter.id,
  label: filter.id,
  numeric: false,
}));

export function applyFilter<T extends (undefined | null | string | number | object)[]>(rows: T[], filter: RawFilter, position: number) {
  if (rows.length > 0) {
    let filteredRows = rows;
    if (!filter.allowEmpty) {
      filteredRows = filteredRows.filter(row => {
        const value = row[position];
        return value !== undefined && value !== null && value !== '';
      });
    }
  
    switch (filter.type) {
      case 'numeric': {
        if ((filter.from === undefined || filter.from === null) && (filter.to === undefined || filter.to === null)) {
          return filteredRows;
        }

        return filteredRows.filter(row => {
          const value = row[position];

          if (filter.from !== undefined && filter.from !== null) {
            if (value !== undefined && value !== null && value < filter.from) {
              return false;
            }
          }

          if (filter.to !== undefined && filter.to !== null) {
            if (value !== undefined && value !== null && value > filter.to) {
              return false;
            }
          }

          return true;
        });
      }
      case 'dateTime': {
        return filteredRows;
      }
      case 'date': {
        if (filter.filter === null) {
          return filteredRows;
        }

        return filteredRows.filter(row => {
          if (!row[position]) {
            return !!filter.allowEmpty;
          }

          const monthYear = format(new Date(row[position] as string), 'MMM yyyy');

          return filter.filter!.includes(monthYear)
        });
      }
      case 'category': {
        if (filter.filter === null) {
          return filteredRows;
        }

        return filteredRows.filter(row => filter.filter!.includes(row[position] as string));
      }
      case 'string': {
        if (!filter.contains) {
          return filteredRows;
        }

        return filteredRows.filter(row => (row[position] as string).includes(filter.contains!));
      }
    }
  }

  return rows;
}

type FieldFilter = { id: keyof Partial<Data>, selected: boolean };

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  headCells: typeof headCells;
  rows: any[];
  onUpdate: (items: Item[]) => unknown;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    headCells,
    rows,
    onUpdate,
  } = props;

  const getMinWidth = (id: string) => {
    switch (id) {
      case 'product':
        return 200;
      case 'reportedBy':
        return 200;
      case 'incidentLocation':
        return 240;
      case 'category':
        return 200;
      case 'subcategory':
        return 240;
      case 'furtherSubcategory':
        return 240;
      case 'description':
        return 350;
      case 'investigationId':
        return 220;
      case 'created_at':
      case 'updated_at':
        return 180;
      default:
        return 200;
    }
  };

  const handleUpdate = (newHeadCell: any) => {
    onUpdate(headCells.map(headCell => headCell.id === newHeadCell.id ? newHeadCell : headCell));
  };

  return (
    <TableHead>
      <TableRow>
        {
          headCells.map((headCell, index) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'default'}
              style={{ minWidth: getMinWidth(headCell.id) }}
            >
              {headCell.id}
              <FilterCell
                cell={headCell}
                data={rows}
                id={headCell.id}
                index={index}
                onUpdate={handleUpdate}
              />
            </TableCell>
          ))
        }
      </TableRow>
    </TableHead>
  );
}

const MemoisedEnhancedTableHead = memo(EnhancedTableHead);

interface Item {
  id: string;
  label: string;
  selected: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }),
);

interface Props {
  fieldFilter: FieldFilter[];
  onUpdate: (items: FieldFilter[]) => unknown;
}

function IntelligenceTable({ fieldFilter, onUpdate }: Props) {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const data = useData();

  const chartData = useMemo(() => data.length === 0 || !(fieldFilter?.length)
    ? data
    : fieldFilter.reduce(
      (incidents: any, rawFilter: any) => rawFilter
        ? applyFilter(incidents, rawFilter, filter.findIndex((value: any)  => value.id === rawFilter.key))
        : incidents,
        data,
    ),
    [data, fieldFilter],
  ) as typeof data;

  const headCellsWithFilter = useMemo(() => {
    const filterMap = new Map(fieldFilter.map(filter => [(filter as any).key, filter]));

    return headCells.map(headCell => ({
      ...headCell,
      ...(filterMap.has(headCell.id) ? { filter: filterMap.get(headCell.id)! } : {}),
    }))
  }, [fieldFilter]);

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterUpdate = (items: Item[]) => {
    const filters = items.filter(item => (item as any).filter).map(item => (item as any).filter);

    onUpdate(filters);
  };

  return (
    <div className={classes.root}>
      <Paper variant="outlined" className={classes.paper}>
        <TableContainer style={{ maxHeight: 800 }}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
            stickyHeader
          >
            {
              <MemoisedEnhancedTableHead
                classes={classes}
                headCells={headCellsWithFilter}
                rows={data}
                onUpdate={handleFilterUpdate}
              />
            }
            <TableBody>
              {
                chartData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <IntelligenceRow
                      key={index}
                      row={row}
                    />
                  ))
              }
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[50, 100, 200]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

export default memo(IntelligenceTable);
