import React from 'react';

type Chart = {
  id: string;
  title: string;
  chartType: 'barChart' | 'barChartWithLine' | 'pieChart';
  groupBy: string;
  value1: string;
  value2: string;
  xLabel: string;
  yLabel1: string;
  yLabel2: string;
  filter: any;
  sortBy: 'groupBy' | 'value1' | 'value2';
  limit: any;
  visible: boolean;
}

export type Report = {
  id: string;
  charts: Chart[];
}

type ContextType = {
  isLoading: boolean;
  reports: Report[];
  updateReport: (report: Report) => void;
};

export const Context = React.createContext<ContextType>({
  isLoading: true,
  reports: [],
  updateReport: () => {},
});

export default Context;
