import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import { Link, Typography, Grid, Button } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import Loading from '../../../../components/Loading';
import { login, completeNewPassword } from '../../../../services/ApiClient';
import { useNotifications } from '../../../../providers/Notifications';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  inputTitle: {
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
}));

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup
    .string()
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
});

export default function LoginForm() {
  const classes = useStyles();
  const showAlert = useNotifications();

  return (
    <div className={classes.root}>
      <Formik
        initialValues={
          {
            email: '',
            password: '',
          }
        }
        validationSchema={validationSchema}
        onSubmit={
          async ({ email, password }) => {
            try {
              const user = await login(email, password);
        
              if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                await completeNewPassword(user, password);
              }
          
              console.log('result', user);
            } catch (error) {
              showAlert({
                message: 'Failed to login.',
                severity: 'error',
              });
            }
          }
        }
      >
        {
          ({ submitForm, isSubmitting }) => (
            <>
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle1"
                      color="textPrimary"
                      className={classes.inputTitle}
                    >
                        Email
                    </Typography>
                    <Field
                      component={TextField}
                      placeholder="Email"
                      variant="outlined"
                      size="medium"
                      name="email"
                      type="email"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle1"
                      color="textPrimary"
                      className={classes.inputTitle}
                    >
                      Password
                    </Typography>
                    <Field
                      component={TextField}
                      placeholder="Password"
                      variant="outlined"
                      size="medium"
                      name="password"
                      type="password"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      size="large"
                      variant="contained"
                      type="submit"
                      color="primary"
                      fullWidth
                      disabled={isSubmitting}
                      onClick={submitForm}
                    >
                      Sign In
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle1"
                      color="textSecondary"
                      align="left"
                    >
                      Forgot your password?
                      {' '}
                      <Link component={RouterLink} to="/reset-password">
                        Reset password
                      </Link>
                      .
                    </Typography>
                  </Grid>
                </Grid>
              </Form>
              {
                isSubmitting && (
                  <Loading fullScreen />
                )
              }
            </>
          )
        }
      </Formik>
    </div>
  );
}
