import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Toolbar } from '@material-ui/core';
import Image from '../../../../components/Image';

const useStyles = makeStyles(theme => ({
  toolbar: {
    width: '100%',
    margin: '0 auto',
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 8),
    },
  },
  logoContainer: {
    width: 200,
    height: 28,
    [theme.breakpoints.up('md')]: {
      width: 240,
      height: 32,
    },
  },
  logoImage: {
    width: '100%',
    height: '100%',
  },
}));

interface Props {
  className?: string;
};

export default function Topbar({ className }: Props) {
  const classes = useStyles();

  return (
    <Toolbar className={clsx(classes.toolbar, className)}>
      <div className={classes.logoContainer}>
        <a href="/" title="Incident Reporting">
          <Image
            className={classes.logoImage}
            src="/assets/images/logos/logo.svg"
            alt="Incident Reporting"
            lazy={false}
          />
        </a>
      </div>
    </Toolbar>
  );
}
