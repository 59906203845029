import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Divider } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { useOnline } from '../../providers/Online';
import { Topbar, Footer } from './components';

const useStyles = makeStyles(() => ({
  root: {
  },
  content: {
    height: '100%',
  },
}));

interface Props {
  children: React.ReactNode;
  className?: string;
};

export default function Dashboard({ children, className }: Props) {
  const classes = useStyles();
  const isOnline = useOnline();

  return (
    <div className={clsx(classes.root, className)}>
      <Topbar />
      <Divider />
      {
        !isOnline && (
          <Alert severity="error" color="error">
            No Internet — please check your internet connection!
          </Alert>
        )
      }
      <main className={classes.content}>{children}</main>
      <Footer />
    </div>
  );
}
