import { memo, useState } from 'react';
import MuiSearchBar from 'material-ui-search-bar';

interface Props {
  className?: string;
  onUpdate: (value: string) => unknown;
}

function SearchBar({ className, onUpdate }: Props) {
  const [searched, setSearched] = useState('');

  const handleChange = (value: string) => {
    setSearched(value);
    onUpdate(value);
  };

  const handelCancelSearch = () => {
    setSearched('');
    onUpdate('');
  };

  return (
    <MuiSearchBar
      className={className}
      value={searched}
      onChange={handleChange}
      onCancelSearch={handelCancelSearch}
    />
  );
}

export default memo(SearchBar);
