import { memo } from 'react';
import GitInfo from 'react-git-info/macro';
import { AppBar, Container, Toolbar, Typography } from '@material-ui/core';

const gitInfo = GitInfo();

function Footer() {
  return (
      <AppBar position="static" color="transparent" elevation={0}>
        <Container maxWidth="md">
          <Toolbar>
            <Typography variant="body1" color="inherit">
              © {new Date().getFullYear()} corrosionerosion.com — version {gitInfo.commit.shortHash}
            </Typography>
          </Toolbar>
        </Container>
      </AppBar>
  )
}

export default memo(Footer);
