import { ComponentType, ReactNode } from 'react';
import { Route, RouteProps } from 'react-router-dom';

interface Props<T extends RouteProps> {
  component: ComponentType<T>;
  layout: ComponentType<{ children: ReactNode }>;
  exact?: boolean;
  path: string;
};

export default function RouteWithLayout<T extends RouteProps>({
  component: Component,
  layout: Layout,
  ...rest
}: Props<T>) {

  return (
    <Route
      {...rest}
      render={
        ((props: T) => (
          <Layout>
            <Component {...props} />
          </Layout>
        )) as any
      }
    />
  );
};
