import { memo, useState, useCallback } from 'react';
import { Redirect } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button, Grid, TextField, Tooltip, Typography, useMediaQuery } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Loading from '../../../../components/Loading';
import Chart from '../../../../components/Chart';
import * as ApiClient from '../../../../services/ApiClient';
import { filter } from '../../../../components/IncidentTable/ExportMenu';
import IntelligenceTable from '../../../../components/IntelligenceTable';
import { useReports } from '../../../../providers/Reports';
import { useNotifications } from '../../../../providers/Notifications';
import type { Report } from '../../../../providers/Reports/Context';
import DiscardChangesDialog from '../DiscardChangesDialog';
import SaveChangesDialog from '../SaveChangesDialog';

const useStyles = makeStyles(theme => ({
  form: {
    '& .MuiTextField-root': {
      background: theme.palette.background.paper,
    },
    '& .MuiOutlinedInput-input': {
      background: theme.palette.background.paper,
    },
  },
  inputTitle: {
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
  uploadButton: {
    display: 'flex',
    justifyContent: 'center',
    border: '1px solid transparent',
    background: theme.palette.alternate.dark,
    textTransform: 'lowercase',
    '& .icon-text': {
      width: 'auto',
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start',
    },
  },
  submitButton: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start',
    },
  },
  closeContainer: {
    marginTop: theme.spacing(12),
  },
  closeButton: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start',
    },
  }
}));

const chartTypes = [
  'barChart',
  'barChartWithLine',
  'pieChart',
] as const;

const sortByOptions = [
  'groupBy',
  'value1',
  'value2',
];

interface Props {
  report: Report;
  initialChart: {
    id: string;
    title: string;
    chartType: 'barChart' | 'barChartWithLine' | 'pieChart';
    groupBy: string;
    value1: string;
    value2: string;
    xLabel: string;
    yLabel1: string;
    yLabel2: string;
    filter: any;
    sortBy: 'groupBy' | 'value1' | 'value2';
    limit: any;
    visible: boolean;
  };
}

function NewChart({ initialChart, report }: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const showAlert = useNotifications();
  const { updateReport } = useReports();
  const [title, setTitle] = useState(initialChart.title);
  const [chartType, setChartType] = useState<'barChart' | 'barChartWithLine' | 'pieChart'>(initialChart.chartType);
  const [groupBy, setGroupBy] = useState(filter.find(filter => filter.id === initialChart.groupBy));
  const [value1, setValue1] = useState(filter.find(filter => filter.id === initialChart.value1));
  const [value2, setValue2] = useState(filter.find(filter => filter.id === initialChart.value2));
  const [xLabel, setXLabel] = useState(initialChart.xLabel);
  const [yLabel1, setYLabel1] = useState(initialChart.yLabel1);
  const [yLabel2, setYLabel2] = useState(initialChart.yLabel2);
  const [sortBy, setSortBy] = useState(initialChart.sortBy || 'groupBy');
  const [limit, setLimit] = useState<string | number>(initialChart.limit || '');
  const [chartFilter, setChartFilter] = useState(() => initialChart.filter || []);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [showSave, setShowSave] = useState(false);
  const [showDiscard, setShowDiscard] = useState(false);

  const handleTitleChange = useCallback((event: any) => {
    setTitle(event.target.value);
  }, []);

  const handleChartTypeChange = useCallback((_: object, value: string | null) => {
    if (value) {
      setChartType(value as 'barChart' | 'barChartWithLine' | 'pieChart');
    }
  }, []);

  const handleGroupByChange = useCallback((_: object, value: any) => {
    if (value) {
      setGroupBy(value);
    }
  }, []);

  const handleValue1Change = useCallback((_: object, value: any) => {
    if (value) {
      setValue1(value);
    }
  }, []);

  const handleValue2Change = useCallback((_: object, value: any) => {
    if (value) {
      setValue2(value);
    }
  }, []);

  const handleXLabelChange = useCallback((event: any) => {
    setXLabel(event.target.value);
  }, []);

  const handleYLabel1Change = useCallback((event: any) => {
    setYLabel1(event.target.value);
  }, []);

  const handleYLabel2Change = useCallback((event: any) => {
    setYLabel2(event.target.value);
  }, []);

  const handleSortByChange = useCallback((_: object, value: string | null) => {
    if (value) {
      setSortBy(value as 'groupBy' | 'value1' | 'value2');
    }
  }, []);

  const handleLimitChange = useCallback((event: any) => {
    const number = +event.target.value;
    setLimit(Number.isNaN(number) ? '' : +number);
  }, []);

  const updateChart = async () => {
    try {
      const newReport = {
        ...report,
        charts: report.charts.concat({
          ...initialChart,
          title,
          chartType,
          groupBy: groupBy?.id || initialChart.groupBy,
          value1: value1?.id || initialChart.value1,
          value2: value2?.id || initialChart.value2,
          xLabel,
          yLabel1,
          yLabel2,
          sortBy,
          limit,
          filter: chartFilter,
        }),
      };

      const result = await ApiClient.updateReport(newReport);

      showAlert({
        message: 'Added chart successfully.',
        severity: 'success',
      });

      updateReport(result);

      Promise.resolve().then(() => setHasSubmitted(true));
    } catch (error) {
      showAlert({
        message: 'Failed to add the chart.',
        severity: 'error',
      });
    }
  };

  if (hasSubmitted) {
    return <Redirect to="/intelligence" />;
  }

  return (
    <div>
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Tooltip
            disableFocusListener
            disableTouchListener
            title="Chart title"
            placement="top-start"
          >
            <Typography
              variant="subtitle1"
              color="textPrimary"
              className={classes.inputTitle}
            >
              Title
            </Typography>
          </Tooltip>
          <TextField
            fullWidth
            variant="outlined"
            value={title}
            onChange={handleTitleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Tooltip
            disableFocusListener
            disableTouchListener
            title="Chart Type"
            placement="top-start"
          >
            <Typography
              variant="subtitle1"
              color="textPrimary"
              className={classes.inputTitle}
            >
              Chart Type
            </Typography>
          </Tooltip>
          <Autocomplete
            options={chartTypes as unknown as string[]}
            getOptionLabel={option => option}
            value={chartType}
            onChange={handleChartTypeChange}
            renderInput={props => <TextField {...props} variant="outlined" />}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Tooltip
            disableFocusListener
            disableTouchListener
            title="Group by"
            placement="top-start"
          >
            <Typography
              variant="subtitle1"
              color="textPrimary"
              className={classes.inputTitle}
            >
              Group by
            </Typography>
          </Tooltip>
          <Autocomplete
            options={filter}
            value={groupBy}
            getOptionLabel={option => option.id}
            onChange={handleGroupByChange}
            renderInput={props => <TextField {...props} variant="outlined" />}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Tooltip
            disableFocusListener
            disableTouchListener
            title="Value (1)"
            placement="top-start"
          >
            <Typography
              variant="subtitle1"
              color="textPrimary"
              className={classes.inputTitle}
            >
              Value (1)
            </Typography>
          </Tooltip>
          <Autocomplete
            options={filter}
            value={value1}
            getOptionLabel={option => option.id}
            onChange={handleValue1Change}
            renderInput={props => <TextField {...props} variant="outlined" />}
          />
        </Grid>
        {
          chartType === 'barChartWithLine' && (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Tooltip
                disableFocusListener
                disableTouchListener
                title="Value (2)"
                placement="top-start"
              >
                <Typography
                  variant="subtitle1"
                  color="textPrimary"
                  className={classes.inputTitle}
                >
                  Value (2)
                </Typography>
              </Tooltip>
              <Autocomplete
                options={filter}
                value={value2}
                getOptionLabel={option => option.id}
                onChange={handleValue2Change}
                renderInput={props => <TextField {...props} variant="outlined" />}
              />
            </Grid>
          )
        }
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Tooltip
            disableFocusListener
            disableTouchListener
            title="Group by Label"
            placement="top-start"
          >
            <Typography
              variant="subtitle1"
              color="textPrimary"
              className={classes.inputTitle}
            >
              Group by Label
            </Typography>
          </Tooltip>
          <TextField
            fullWidth
            variant="outlined"
            value={xLabel}
            onChange={handleXLabelChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Tooltip
            disableFocusListener
            disableTouchListener
            title="Value 1 Label"
            placement="top-start"
          >
            <Typography
              variant="subtitle1"
              color="textPrimary"
              className={classes.inputTitle}
            >
              Value 1 Label
            </Typography>
          </Tooltip>
          <TextField
            fullWidth
            variant="outlined"
            value={yLabel1}
            onChange={handleYLabel1Change}
          />
        </Grid>
        {
          chartType === 'barChartWithLine' && (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Tooltip
                disableFocusListener
                disableTouchListener
                title="Value 2 Label"
                placement="top-start"
              >
                <Typography
                  variant="subtitle1"
                  color="textPrimary"
                  className={classes.inputTitle}
                >
                  Value 2 Label
                </Typography>
              </Tooltip>
              <TextField
                fullWidth
                variant="outlined"
                value={yLabel2}
                onChange={handleYLabel2Change}
              />
            </Grid>
          )
        }
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Tooltip
            disableFocusListener
            disableTouchListener
            title="Sort By"
            placement="top-start"
          >
            <Typography
              variant="subtitle1"
              color="textPrimary"
              className={classes.inputTitle}
            >
              Sort By
            </Typography>
          </Tooltip>
          <Autocomplete
            options={sortByOptions}
            value={sortBy}
            onChange={handleSortByChange}
            renderInput={props => <TextField {...props} variant="outlined" />}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Tooltip
            disableFocusListener
            disableTouchListener
            title="Limit"
            placement="top-start"
          >
            <Typography
              variant="subtitle1"
              color="textPrimary"
              className={classes.inputTitle}
            >
              Limit
            </Typography>
          </Tooltip>
          <TextField
            fullWidth
            variant="outlined"
            type="number"
            value={`${limit}`}
            onChange={handleLimitChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Chart
            id={initialChart.id}
            title={title}
            chartType={chartType}
            groupBy={(groupBy?.id ?? '') as string}
            value1={(value1?.id ?? '') as string}
            value2={(value2?.id ?? '') as string}
            xLabel={xLabel}
            yLabel1={yLabel1}
            yLabel2={yLabel2}
            filter={chartFilter}
            sortBy={sortBy}
            limit={limit || undefined}
          />
        </Grid>
        <Grid item xs={12}>
          <IntelligenceTable
            fieldFilter={chartFilter}
            onUpdate={setChartFilter}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            size="large"
            variant="outlined"
            type="button"
            color="primary"
            name="saveDraft"
            fullWidth
            disabled={isSubmitting}
            onClick={() => setShowDiscard(true)}
            className={classes.submitButton}
          >
            Discard changes
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            size="large"
            variant="contained"
            type="button"
            color="primary"
            name="submit"
            fullWidth
            disabled={isSubmitting}
            onClick={() => setShowSave(true)}
            className={classes.submitButton}
          >
            Add Chart
          </Button>
        </Grid>
      </Grid>
      {
        isSubmitting && (
          <Loading fullScreen />
        )
      }
      <DiscardChangesDialog
        open={showDiscard}
        onConfirm={() => {
          setShowDiscard(false);
          setHasSubmitted(true);
        }}
        onCancel={() => {
          setShowDiscard(false);
        }}
      />
      <SaveChangesDialog
        open={showSave}
        onConfirm={async () => {
          setIsSubmitting(true);
          setShowSave(false);
          await updateChart();
          setIsSubmitting(false);
        }}
        onCancel={() => {
          setShowSave(false);
        }}
      />
    </div>
  );
}

export default memo(NewChart);
