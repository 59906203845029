import { PropsWithChildren, useEffect, useState } from 'react';
import Context from './Context';
import { useAuth } from '../Auth';

const initialState = {
  isLoading: true,
  products: [] as string[],
};

export default function Provider({
  persistKey = 'products',
  children,
}: PropsWithChildren<{ persistKey?: string }>) {
  const { auth } = useAuth();

  const data = localStorage.getItem(persistKey);
  const json = data && JSON.parse(data);

  const [products, setProducts] = useState<typeof initialState>(() => json || initialState);

  useEffect(() => {
    try {
      if (auth.isAuthenticated) {
        localStorage.setItem(persistKey, JSON.stringify(products));
      }
    } catch (error) {
      console.warn(error);
    }
  }, [auth.isAuthenticated, products, persistKey]);

  useEffect(() => {
    if (!auth.isAuthenticated) {
      try {
        localStorage.removeItem(persistKey);
      } catch (error) {
        console.warn(error);
      }
      setProducts(initialState);
    }
  }, [auth.isAuthenticated, persistKey]);

  const updateProducts = (products: (typeof initialState)['products']) => {
    setProducts(state => ({
      ...state,
      products,
      isLoading: false,
    }));
  };

  return (
    <Context.Provider value={{ ...products, updateProducts }}>
      {children}
    </Context.Provider>
  );
}
