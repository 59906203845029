import { useContext } from 'react';
import Context from './Context';
export { default } from './Provider';

export function useLocations() {
  const locations = useContext(Context);

  if (!locations) {
    throw new Error('Failed to find locations context.');
  }

  return locations;
}
