import type { IncidentType } from '../providers/Incidents/store/types';
import type { Location as IncidentLocation, Category, Subcategory, FurtherSubcategory } from  '../providers/Locations/types';

export const notProductRelated = 'Not Product Related';

export const descriptionTooltip = 'Describe the incident';

export function sanitiseIncidentForm({ files, ...incident }: {
  id: string;
  reportedBy: string;
  incidentDate: Date;
  product: null | string;
  partNumber: null | string;
  defectiveQuantity: null | string;
  grnNumber: string;
  orderNumber: string;
  incidentLocation: IncidentLocation | null;
  category: Category | null;
  subcategory: Subcategory | null;
  furtherSubcategory: FurtherSubcategory | null;
  description: string;
  investigationId: string | null;
  attachments: {
    id: string;
    name: string;
    type: string;
    size: number;
    url: string;
  }[];
  files: File[];
  status: string;
}) {
  return {
    ...incident,
    investigationId: incident.investigationId ?? null,
    incidentDate: incident.incidentDate.toISOString().substring(0, 10),
    incidentLocation: incident.incidentLocation
      ? { id: incident.incidentLocation.id, title: incident.incidentLocation.title }
      : null,
    category: incident.category
      ? { id: incident.category.id, title: incident.category.title }
      : null,
    subcategory: incident.subcategory
      ? { id: incident.subcategory.id, title: incident.subcategory.title }
      : null,
    furtherSubcategory: incident.furtherSubcategory
      ? { id: incident.furtherSubcategory.id, title: incident.furtherSubcategory.title }
      : null,
  };
}

export function patchIncident(operationList: IncidentLocation[], incident: IncidentType) {
  const incidentLocation = (incident.incidentLocation &&
    operationList.find(incidentLocation => incidentLocation.id === incident.incidentLocation!.id)) ?? null;
  const category = (incident.category && incidentLocation &&
    incidentLocation.categories.find(category => category.id === incident.category!.id)) ?? null;
  const subcategory = (incident.subcategory && category &&
    category.subcategories.find(subcategory => subcategory.id === incident.subcategory!.id)) ?? null;
  const furtherSubcategory = (incident.furtherSubcategory && subcategory &&
    subcategory.furtherSubcategories.find(furtherSubcategory => furtherSubcategory.id === incident.furtherSubcategory!.id)) ?? null;

  return {
    ...incident,
    investigationId: incident.investigationId || null,
    incidentDate: new Date(incident.incidentDate),
    incidentLocation,
    category,
    subcategory,
    furtherSubcategory,
    attachments: incident.attachments ?? [],
    files: [] as File[],
  };
}

export function formatDate(date: string | null) {
  if (!date) {
    return '-';
  }

  const [year, month, day] = date.split('-');

  return `${day}-${month}-${year}`;
}

export function formatDateTime(dateTime: string | null) {
  if (!dateTime) {
    return '-';
  }

  const [date, time] = dateTime.split('T');
  const [year, month, day] = date.split('-');
  const [hours, minutes] = time.split(':');

  return `${day}-${month}-${year} ${hours}:${minutes}`;
}

export function formatDateTimeAsDate(dateTime: string | null) {
  if (!dateTime) {
    return '-';
  }

  const [date] = dateTime.split('T');
  const [year, month, day] = date.split('-');

  return `${day}-${month}-${year}`;
}
