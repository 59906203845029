import { InvestigationsStateType, InvestigationsActionType, InvestigationsActionTypes } from './types';

export const initialState: InvestigationsStateType = {
  isLoading: true,
  investigations: [],
};

export default function reducer(
  state: InvestigationsStateType,
  action: InvestigationsActionTypes,
) {
  switch (action.type) {
    case InvestigationsActionType.AddInvestigation:
      return {
        ...state,
        investigations: [action.investigation, ...state.investigations],
      };
    case InvestigationsActionType.UpdateInvestigation:
      return {
        ...state,
        investigations: state.investigations.map(existingInvestigation => action.investigation.id === existingInvestigation.id
          ? action.investigation
          : existingInvestigation),
      };
    case InvestigationsActionType.SynchroniseInvestigations:
      return {
        ...state,
        investigations: action.investigations,
        isLoading: false,
      };
    case InvestigationsActionType.ResetInvestigations:
      return initialState;
    default:
      return state;
  }
}
