import { memo, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import FilterListIcon from '@material-ui/icons/FilterList';
import ValueFilter from './ValueFilter';
import Tooltip from '../Tooltip';
import FilterListEditIcon from './FilterListEdit';

interface Props {
  id: string;
  data: any[];
  cell: any;
  onUpdate: (cell: any) => unknown;
  index: number;
}

function getFilter(key: string) {
  if (key === 'incidentDate') {
    return {
      key,
      type: 'date' as const,
      allowEmpty: true,
      allowAll: true,
      filter: null,
    };
  }

  return {
    key,
    type: 'category' as const,
    allowEmpty: true,
    allowAll: true,
    filter: null,
  };
}

function isEmptyFilter(filter: ReturnType<typeof getFilter>) {
  switch (filter.type) {
    case 'date':
    case 'category':
      return filter.allowEmpty && filter.allowAll && filter.filter === null;
    default:
      return false;
  }
}

function FilterCell({ id, data, cell, onUpdate, index }: Props) {
  const [open, setOpen] = useState(false);
  const [cellFilter, setCellFilter] = useState((cell as any).filter ?? getFilter(id));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleSave = () => {
    setOpen(false);
    onUpdate({
      ...cell,
      filter: cellFilter,
    });
  };

  const handleUpdate = (newCell: any) => {
    setCellFilter(newCell);
  };

  return (
    <>
      <Tooltip
        title="Filter Values"
        placement="top-start"
      >
        <IconButton
          onClick={handleClickOpen}
          color="inherit"
        >
          {
            isEmptyFilter(cellFilter) ? (
              <FilterListIcon />
            ) : (
              <FilterListEditIcon />
            )
          }
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleCancel}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        disableBackdropClick
      >
        <DialogTitle id="scroll-dialog-title">Filter Values</DialogTitle>
        <DialogContent dividers={true}>
          <ValueFilter
            filter={cellFilter}
            rows={data as any}
            onUpdateFilter={handleUpdate}
            index={index}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default memo(FilterCell);
