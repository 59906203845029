import { memo, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '../../../../components/Tooltip';
import Loading from '../../../../components/Loading';
import { usePartNumbers } from '../../../../providers/PartNumbers';
import { useNotifications } from '../../../../providers/Notifications';
import { createPartNumber } from '../../../../services/ApiClient';
import AddPartNumberDialog from './AddPartNumberDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 0,
      flexGrow: 1,
    },
    fab: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  }),
);

function PartNumbersFab() {
  const classes = useStyles();
  const showAlert = useNotifications();
  const [showAdd, setShowAdd] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { updatePartNumbers, partNumbers } = usePartNumbers();

  return (
    <div className={classes.root}>
      <Tooltip
        title="Add Product"
        placement="top-start"
      >
        <Fab
          color="primary"
          aria-label="Add"
          className={classes.fab}
          onClick={() => setShowAdd(true)}
        >
          <AddIcon />
        </Fab>
      </Tooltip>
      {
        isLoading && (
          <Loading fullScreen />
        )
      }
      <AddPartNumberDialog
        open={showAdd}
        onConfirm={async (partNumber: { id: string, description: string }) => {
          setShowAdd(false);
          setIsLoading(true);

          try {
            const result = await createPartNumber(partNumber);

            updatePartNumbers(partNumbers.concat(result));

            showAlert({
              message: 'Add part number successfully.',
              severity: 'success',
            });
          } catch (error) {
            showAlert({
              message: 'Failed to add part number.',
              severity: 'error',
            });
          }

          setIsLoading(false);
        }}
        onCancel={() => setShowAdd(false)}
      />
    </div>
  );
}

export default memo(PartNumbersFab);
