import { UsersStateType, UsersActionType, UsersActionTypes } from './types';

export default function reducer(
  state: UsersStateType,
  action: UsersActionTypes,
) {
  switch (action.type) {
    case UsersActionType.SetUsers:
      return {
        isLoading: false,
        users: action.users,
      };
    case UsersActionType.UpdateUser:
      return {
        ...state,
        users: state.users.map(existingUser => action.user.user_id === existingUser.user_id
          ? action.user
          : existingUser),
      };
    case UsersActionType.AddUser:
      return {
        ...state,
        users: [action.user].concat(state.users),
      };
    case UsersActionType.Reset:
      return action.state;
    default:
      return state;
  }
}
