import { useContext } from 'react';
import Context from './Context';
export { default } from './Provider';

export function useInvestigations() {
  const investigations = useContext(Context);

  if (!investigations) {
    throw new Error('Failed to find investigations context.');
  }

  return investigations;
}
