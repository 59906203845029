import { memo } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import DashboardBreadcrums from '../../components/DashboardBreadcrums';
import Section from '../../components/Section';
import SectionHeader from '../../components/SectionHeader';
import { useReports } from '../../providers/Reports';
import EditChart from './components/EditChart';

const useStyles = makeStyles(theme => {
  return ({
    pagePaddingTop: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      [theme.breakpoints.up('md')]: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
      },
    }, 
    sectionNoPaddingTop: {
      paddingTop: 0,
    },
  });
});

function EditIntelligence({ match }: RouteComponentProps<{ id: string }>) {
  const classes = useStyles();
  const chartId = match.params.id;

  const { reports } = useReports();
  const [report] = reports;

  const chart = report?.charts.find(chart => chart.id === chartId);

  if (!chart) {
    return <Redirect to={`/intelligence`} />;
  }

  return (
    <div>
      <Section className={classes.pagePaddingTop}>
        <DashboardBreadcrums
          breadcrums={[
            { name: 'Home', path: '/' },
            { name: 'Intelligence', path: '/intelligence' },
            { name: 'Edit Chart', path: `/intelligence/${chartId}/edit` },
          ]}
        />
      </Section>
      <Section className={classes.sectionNoPaddingTop}>
        <div>
          <SectionHeader title="Edit Chart" align="left" />
          <EditChart initialChart={chart} report={report} />
        </div>
      </Section>
    </div>
  );
}

export default memo(EditIntelligence);
