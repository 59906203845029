import Loading from '../../../../components/Loading';
import { useUsers } from '../../../../providers/Users';
import UserTable from '../UserTable';

export default function UserList() {
  const { users } = useUsers();

  if (!users.users) {
    return <Loading />;
  }

  const fieldFilter = {
    user_id: false,
    name: true,
    email: true,
    role: true,
    active: true,
    created_at: false,
    updated_at: false,
  }

  return (
    <div>
      <UserTable rows={users.users} fieldFilter={fieldFilter} />
    </div>
  );
}
