import { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';

export interface Props {
  open: boolean;
  onConfirm: (rejectReason: string) => unknown;
  onCancel: () => unknown;
}

export default function RejectDialog({ open, onCancel, onConfirm }: Props) {
  const [rejectReason, setRejectReason] = useState('');

  const handleChange = (event: any) => {
    setRejectReason(event.target.value);
  };

  return (
    <Dialog onClose={onCancel} open={open}>
      <DialogTitle id="simple-dialog-title">Are you sure you want to reject the incident?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please indicate the reason for rejecting the incident:
        </DialogContentText>
        <TextField
          autoFocus
          variant="outlined"
          fullWidth
          value={rejectReason}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => onConfirm(rejectReason)}
          color="primary"
          disabled={rejectReason.trim().length === 0}
        >
          Reject
        </Button>
      </DialogActions>
    </Dialog>
  );
}
