import { MenuActionType } from './types';

export function setMiniMode(payload: boolean) {
  return {
    type: MenuActionType.SetMiniMode, 
    payload,
  };
}

export function setMenuOpen(payload: boolean) {
  return {
    type: MenuActionType.SetMenuOpen, 
    payload,
  };
}

export function setMobileMenuOpen(payload: boolean) {
  return {
    type: MenuActionType.SetMobileMenuOpen, 
    payload,
  };
}

export function setMiniSwitchVisibility(payload: boolean) {
  return {
    type: MenuActionType.SetMiniSwitchVisibility,
    payload,
  };
}
