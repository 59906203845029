import { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import Loading from '../Loading';
import { useNotifications } from '../../providers/Notifications';
import { useReports } from '../../providers/Reports';
import * as ApiClient from '../../services/ApiClient';
import EditReportDialog from './EditReportDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 0,
      flexGrow: 1,
    },
    speedDial: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    tooltip: {
      fontSize: theme.typography.pxToRem(15),
    },
  }),
);

const useTooltipStyles = makeStyles((theme: Theme) =>
  createStyles({
    tooltip: {
      fontSize: theme.typography.pxToRem(15),
    },
  }),
);

type Chart = {
  id: string;
  title: string;
  chartType: 'barChart' | 'barChartWithLine' | 'pieChart';
  groupBy: string;
  value1: string;
  value2: string;
  xLabel: string;
  yLabel1: string;
  yLabel2: string;
  filter: any;
  sortBy: 'groupBy' | 'value1' | 'value2';
  limit: any;
  visible: boolean;
};

type Report = {
  id: string;
  charts: Chart[];
};

interface Props {
  report: Report;
}

export default function ReportSpeedDial({ report }: Props) {
  const classes = useStyles();
  const tooltipClasses = useTooltipStyles();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);  
  const [redirect, setRedirect] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const showAlert = useNotifications();
  const { updateReport } = useReports();

  if (redirect === true) {
    return <Redirect to={`/intelligence/new`} />;
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleUpdateReport = async (report: Report) => {
    try {  
      const result = await ApiClient.updateReport(report);

      showAlert({
        message: 'Updated intelligence charts successfully.',
        severity: 'success',
      });

      updateReport(result);
    } catch (error) {
      showAlert({
        message: 'Failed to update intelligence charts.',
        severity: 'error',
      });
    }
  }

  return (
    <div className={classes.root}>
      <SpeedDial
        ariaLabel="Intelligence actions"
        className={classes.speedDial}
        icon={<MoreVertIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        direction="up"
      >
        <SpeedDialAction
          TooltipClasses={tooltipClasses}
          icon={<EditIcon />}
          tooltipTitle="Edit"
          onClick={() => setShowEdit(true)}
        />
        <SpeedDialAction
          TooltipClasses={tooltipClasses}
          icon={<AddIcon />}
          tooltipTitle="Add New Chart"
          onClick={() => setRedirect(true)}
        />
      </SpeedDial>
      {
        loading && (
          <Loading fullScreen />
        )
      }
      <EditReportDialog
        open={showEdit}
        initialItems={report.charts}
        onCancel={() => setShowEdit(false)}
        onConfirm={async (charts: any) => {
          setShowEdit(false)
          setLoading(true);
          await handleUpdateReport({ ...report, charts });
          setLoading(false);
        }}
      />
    </div>
  );
}
