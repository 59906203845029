export enum UsersActionType {
  SetUsers = 'SET_USERS',
  Reset = 'RESET',
  UpdateUser = 'UPDATE_USER',
  AddUser = 'ADD_USER',
}

export type UserType = {
  user_id: string;
  name: string;
  email: string;
  role: string;
  active: boolean;
  created_at: string;
  updated_at: string;
}

export type UsersStateType = {
  isLoading: boolean;
  users: UserType[];
};

interface SetUsersAction {
  type: UsersActionType.SetUsers;
  users: UserType[];
}

interface ResetAction {
  type: UsersActionType.Reset;
  state: UsersStateType;
}

interface UpdateUserAction {
  type: UsersActionType.UpdateUser;
  user: UserType;
}

interface AddUserAction {
  type: UsersActionType.AddUser;
  user: UserType;
}

export type UsersActionTypes = SetUsersAction | ResetAction | UpdateUserAction | AddUserAction;
