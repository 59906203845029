import { PropsWithChildren, useEffect, useState } from 'react';
import Context from './Context';
import { useAuth } from '../Auth';
import type { Template } from './types';

const initialState = {
  isLoading: true,
  templates: [] as Template[],
};

export default function Provider({
  persistKey = 'templates',
  children,
}: PropsWithChildren<{ persistKey?: string }>) {
  const { auth } = useAuth();

  const data = localStorage.getItem(persistKey);
  const json = data && JSON.parse(data);

  const [templates, setTemplates] = useState<typeof initialState>(() => json || initialState);

  useEffect(() => {
    try {
      if (auth.isAuthenticated) {
        localStorage.setItem(persistKey, JSON.stringify(templates));
      }
    } catch (error) {
      console.warn(error);
    }
  }, [auth.isAuthenticated, templates, persistKey]);

  useEffect(() => {
    if (!auth.isAuthenticated) {
      try {
        localStorage.removeItem(persistKey);
      } catch (error) {
        console.warn(error);
      }
      setTemplates(initialState);
    }
  }, [auth.isAuthenticated, persistKey]);

  const updateTemplates = (templates: (typeof initialState)['templates']) => {
    setTemplates(state => ({
      ...state,
      templates,
      isLoading: false,
    }));
  };

  return (
    <Context.Provider value={{ ...templates, updateTemplates }}>
      {children}
    </Context.Provider>
  );
}
