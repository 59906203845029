import { useCallback, useMemo, useState } from 'react';
import { format } from 'date-fns';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Slider from '@material-ui/core/Slider';
import { useUsers } from '../../providers/Users';
import { formatDateTimeAsDate } from '../../utils/incidents';
import { useInvestigations } from '../../providers/Investigations';
import type { InvestigationType } from '../../providers/Investigations/store/types';

export type Filter = ({
  key: string;
  allowEmpty: boolean;
}) & ({
  type: 'numeric';
  from?: number;
  to?: number;
} | {
  type: 'date';
  filter: string[] | null;
} | {
  type: 'dateTime';
  filter: string[] | null;
} | {
  type: 'category';
  filter: string[] | null;
} | {
  type: 'boolean';
  filter: string[] | null;
} | {
  type: 'status';
  filter: string[] | null;
} | {
  type: 'object';
  filter: string[] | null;
} | {
  type: 'string';
  contains: string;
});

export function applyFilter<T extends { [name: string]: undefined | null | string | number | object | boolean }>(
  rows: T[],
  filter: Filter,
  investigationMap: Map<string, InvestigationType>,
) {
  const { key } = filter;

  if (rows.length > 0 && key in rows[0]) {
    let filteredRows = rows;
    if (!filter.allowEmpty) {
      filteredRows = filteredRows.filter(row => {
        const value = row[key];
        return value !== undefined && value !== null && value !== '';
      });
    }
  
    switch (filter.type) {
      case 'numeric': {
        if ((filter.from === undefined || filter.from === null) && (filter.to === undefined || filter.to === null)) {
          return filteredRows;
        }

        return filteredRows.filter(row => {
          const value = row[key];

          if (filter.from !== undefined && filter.from !== null) {
            if (value !== undefined && value !== null && value < filter.from) {
              return false;
            }
          }

          if (filter.to !== undefined && filter.to !== null) {
            if (value !== undefined && value !== null && value > filter.to) {
              return false;
            }
          }

          return true;
        });
      }
      case 'dateTime': {
        if (filter.filter === null) {
          return filteredRows;
        }

        return filteredRows.filter(row => {
          if (!row[key]) {
            return !!filter.allowEmpty;
          }

          const date = formatDateTimeAsDate(row[key] as string);

          return filter.filter!.includes(date);
        });
      }
      case 'date': {
        if (filter.filter === null) {
          return filteredRows;
        }

        return filteredRows.filter(row => {
          if (!row[key]) {
            return !!filter.allowEmpty;
          }

          const monthYear = format(new Date(row[key] as string), 'MMM yyyy');

          return filter.filter!.includes(monthYear);
        });
      }
      case 'boolean': {
        if (filter.filter === null) {
          return filteredRows;
        }

        return filteredRows.filter(row => {
          const value = row[key] !== false ? 'Yes' : 'No';

          return filter.filter!.includes(value);
        });
      }
      case 'status': {
        if (filter.filter === null) {
          return filteredRows;
        }

        return filteredRows.filter(row => {
          if (!row[key]) {
            return !!filter.allowEmpty;
          }

          const investigation = investigationMap.get(row[key] as string);

          let status = '-';
  
          if (investigation) {
            status = investigation.status === 'opened' ? 'Opened' : 'Completed';
          }
  
          return filter.filter!.includes(status);
        });
      }
      case 'category': {
        if (filter.filter === null) {
          return filteredRows;
        }

        return filteredRows.filter(row => filter.filter!.includes(row[key] as string));
      }
      case 'object': {
        if (filter.filter === null) {
          return filteredRows;
        }

        return filteredRows.filter(row => filter.filter!.includes((row[key] as ({ id: string } | undefined))?.id!));
      }
      case 'string': {
        if (!filter.contains) {
          return filteredRows;
        }

        return filteredRows.filter(row => row[key] !== undefined && row[key] !== null && (row[key] as string).includes(filter.contains!));
      }
    }
  }

  return rows;
}

interface Props<T extends { [name: string]: undefined | null | string | number | object | boolean }> {
  filter: Filter;
  rows: T[];
  onUpdateFilter: (filter: Filter) => unknown;
}

export default function ValueFilter<T extends { [name: string]: undefined | null | string | number | object | boolean }>({
  filter,
  rows,
  onUpdateFilter,
}: Props<T>) {
  const [currentFilter, setCurrentFilter] = useState(filter);
  const { userMap } = useUsers();
  const { investigationMap } = useInvestigations();
  const options = useMemo(
    () => Array.from(new Set(rows
      .map(row => {
        const value = row[currentFilter.key as any] as any;
        switch(currentFilter.key) {
          case 'incidentJustified':
            return value !== false ? 'Yes' : 'No';
          case 'incidentDate':
            return typeof value === 'string' ? format(new Date(value), 'MMM yyyy') : value;
          case 'investigationId':
            const investigation = investigationMap.get(value);

            if (!investigation) {
              return null;
            }

            return investigation.status === 'opened' ? 'Opened' : 'Completed';
          case 'created_at':
          case 'updated_at':
            return typeof value === 'string' ? formatDateTimeAsDate(value) : value;
          default:
            return typeof value === 'string' ? value : value?.id;
        }
      })
      .filter(option => option !== null && option !== undefined)))
      .sort(),
    [currentFilter.key, rows, investigationMap],
  );

  const handleAllowEmptyChange = useCallback(() => {
    setCurrentFilter(filter => {
      const newFilter = {
        ...filter,
        allowEmpty: !filter.allowEmpty,
      };

      onUpdateFilter(newFilter);

      return newFilter;
    });
  }, [onUpdateFilter]);

  const handleContainsChange = useCallback((event: any) => {
    setCurrentFilter(filter => {
      const newFilter = {
        ...filter,
        contains: event.target.value as string,
      };

      onUpdateFilter(newFilter);

      return newFilter;
    });
  }, [onUpdateFilter]);

  const handleCheckAllChange = useCallback(() => {
    setCurrentFilter(filter => {
      const newFilter = {
        ...filter,
        filter: (filter as any).filter === null ? [] : null,
      };
      
      onUpdateFilter(newFilter);

      return newFilter;
    });
  }, [onUpdateFilter]);

  const handleCheckChange = useCallback((option: string, remove: boolean) => {
    setCurrentFilter(filter => {
      const newFilter = {
        ...filter,
        filter: remove
          ? ((filter as any).filter || options).filter((filter: any) => filter !== option)
          : ((filter as any).filter === null ? [option] : [...(filter as any).filter, option]),
      };
      
      onUpdateFilter(newFilter);

      return newFilter;
    });
  }, [options, onUpdateFilter]);

  const handleObjectCheckChange = useCallback((option: string, remove: boolean) => {
    setCurrentFilter(filter => {
      const newFilter = {
        ...filter,
        filter: remove
          ? ((filter as any).filter || options).filter((filter: any) => filter !== option)
          : ((filter as any).filter === null ? [option] : [...(filter as any).filter, option]),
      };

      onUpdateFilter(newFilter);

      return newFilter;
    })
  }, [options, onUpdateFilter]);

  switch (currentFilter.type) {
    case 'numeric': {
      return (
        <div>
          <ListItem>
            <ListItemText primary="Allow empty" />
            <ListItemIcon>
              <Checkbox
                checked={currentFilter.allowEmpty}
                onChange={handleAllowEmptyChange}
              />
            </ListItemIcon>
          </ListItem>
          <ListItem>
            <ListItemText primary="From">
              <Slider
                value={[1, 20]}
                valueLabelDisplay="auto"
              />
            </ListItemText>
            <ListItemIcon>
              <Checkbox
                checked={currentFilter.from !== null || currentFilter.from !== undefined}
                onChange={() => {}}
              />
            </ListItemIcon>
          </ListItem>
        </div>
      );
    }
    case 'string': {
      return (
        <div>
          <ListItem>
            <ListItemText primary="Allow empty" />
            <ListItemIcon>
              <Checkbox
                checked={currentFilter.allowEmpty}
                onChange={handleAllowEmptyChange}
              />
            </ListItemIcon>
          </ListItem>
          <ListItem>
            <ListItemText primary="Text contains" />
            <ListItemSecondaryAction>
              <TextField
                variant="outlined"
                value={currentFilter.contains || ''}
                onChange={handleContainsChange}
              />
            </ListItemSecondaryAction>
          </ListItem>
        </div>
      );
    }
    case 'boolean': {
      const filterSet = new Set(currentFilter.filter || []);

      return (
        <div>
          <List>
            {
              options.map(option => (
                <ListItem key={option}>
                  <ListItemText
                    primary={currentFilter.key === 'reportedBy' ? (userMap.get(option)?.name || userMap) : option}
                  />
                  <ListItemIcon>
                    <Checkbox
                      checked={currentFilter.filter === null || filterSet.has(option)}
                      onChange={() => {
                        if (!(currentFilter.filter === null || filterSet.has(option))) {
                          const newFilterSet = new Set(filterSet);
                          newFilterSet.add(option);

                          if (newFilterSet.size === options.length) {
                            handleCheckAllChange();
                            return;
                          }
                        }
                        handleCheckChange(option, currentFilter.filter === null || filterSet.has(option))
                      }}
                    />
                  </ListItemIcon>
                </ListItem>
              ))
            }
          </List>
        </div>
      );
    }
    case 'date':
    case 'dateTime':
    case 'status':
    case 'category': {
      const filterSet = new Set(currentFilter.filter || []);

      return (
        <div>
          <ListItem>
            <ListItemText primary="Allow empty" />
            <ListItemIcon>
              <Checkbox
                checked={currentFilter.allowEmpty}
                onChange={handleAllowEmptyChange}
              />
            </ListItemIcon>
          </ListItem>
          <ListItem>
            <ListItemText primary="Select all" />
            <ListItemIcon>
              <Checkbox
                checked={currentFilter.filter === null}
                onChange={handleCheckAllChange}
              />
            </ListItemIcon>
          </ListItem>
          <Divider />
          <List>
            {
              options.map(option => (
                <ListItem key={option}>
                  <ListItemText
                    primary={currentFilter.key === 'reportedBy' ? (userMap.get(option)?.name || userMap) : option}
                  />
                  <ListItemIcon>
                    <Checkbox
                      checked={currentFilter.filter === null || filterSet.has(option)}
                      onChange={() => handleCheckChange(option, currentFilter.filter === null || filterSet.has(option))}
                    />
                  </ListItemIcon>
                </ListItem>
              ))
            }
          </List>
        </div>
      );
    }
    case 'object': {
      const filterSet = new Set(currentFilter.filter || []);
      const optionMap = new Map(rows.map(row => [
        (row[currentFilter.key as any] as any)?.id,
        (row[currentFilter.key as any] as any)?.title,
      ]));

      return (
        <div>
          <ListItem>
            <ListItemText primary="Allow empty" />
            <ListItemIcon>
              <Checkbox
                checked={currentFilter.allowEmpty}
                onChange={handleAllowEmptyChange}
              />
            </ListItemIcon>
          </ListItem>
          <ListItem>
            <ListItemText primary="Select all" />
            <ListItemIcon>
              <Checkbox
                checked={currentFilter.filter === null}
                onChange={handleCheckAllChange}
              />
            </ListItemIcon>
          </ListItem>
          <Divider />
          <List>
            {
              options.map(option => (
                <ListItem key={option}>
                  <ListItemText primary={optionMap.get(option)} />
                  <ListItemIcon>
                    <Checkbox
                      checked={currentFilter.filter === null || filterSet.has(option)}
                      onChange={() => handleObjectCheckChange(option, currentFilter.filter === null || filterSet.has(option))}
                    />
                  </ListItemIcon>
                </ListItem>
              ))
            }
          </List>
        </div>
      );
    }
  }

  return null;
}