import { format } from 'date-fns';
import { useIncidents } from '../../providers/Incidents';
import { useInvestigations } from '../../providers/Investigations';
import { useUsers } from '../../providers/Users';
import type { InvestigationType } from '../../providers/Investigations/store/types';
import { formatDate } from '../../utils/incidents';
import type { Filter as RawFilter } from '../ValueFilter';

export function applyFilter<T extends (undefined | null | string | number | object)[]>(rows: T[], filter: RawFilter, position: number) {
  if (rows.length > 0) {
    let filteredRows = rows;
    if (!filter.allowEmpty) {
      filteredRows = filteredRows.filter(row => {
        const value = row[position];
        return value !== undefined && value !== null && value !== '';
      });
    }
  
    switch (filter.type) {
      case 'numeric': {
        if ((filter.from === undefined || filter.from === null) && (filter.to === undefined || filter.to === null)) {
          return filteredRows;
        }

        return filteredRows.filter(row => {
          const value = row[position];

          if (filter.from !== undefined && filter.from !== null) {
            if (value !== undefined && value !== null && value < filter.from) {
              return false;
            }
          }

          if (filter.to !== undefined && filter.to !== null) {
            if (value !== undefined && value !== null && value > filter.to) {
              return false;
            }
          }

          return true;
        });
      }
      case 'dateTime': {
        return filteredRows;
      }
      case 'date': {
        if (filter.filter === null) {
          return filteredRows;
        }

        return filteredRows.filter(row => {
          if (!row[position]) {
            return !!filter.allowEmpty;
          }

          const monthYear = format(new Date(row[position] as string), 'MMM yyyy');

          return filter.filter!.includes(monthYear)
        });
      }
      case 'category': {
        if (filter.filter === null) {
          return filteredRows;
        }

        return filteredRows.filter(row => filter.filter!.includes(row[position] as string));
      }
      case 'string': {
        if (!filter.contains) {
          return filteredRows;
        }

        return filteredRows.filter(row => (row[position] as string).includes(filter.contains!));
      }
    }
  }

  return rows;
}

interface Data {
  id: string;
  incidentNumber: string | null;
  reportedBy: string;
  incidentDate: string;
  product: string | null;
  partNumber: string | null;
  defectiveQuantity: string | null;
  grnNumber: string;
  orderNumber: string;
  incidentLocation: { id: string; title: string; } | null;
  category: { id: string; title: string; } | null;
  subcategory: { id: string; title: string; } | null;
  furtherSubcategory: { id: string; title: string; } | null;
  description: string;
  investigationId: string | null;
  status: string;
  created_at: string;
  updated_at: string;
}

interface Filter {
  id: keyof Data | 'assignedTo' | 'incidentDescription' | 'incidentJustified' | 'containmentAction' | 'containmentActionStatus' | 'containmentActionSignedBy' | 'containmentActionSignedDate' | 'rootCause' | 'rootCauseCategory' | 'rootCauseStatus' | 'rootCauseSignedBy' | 'rootCauseSignedDate' | 'correctiveAction' | 'correctiveActionStatus' | 'correctiveActionSignedBy' | 'correctiveActionSignedDate' | 'verificationOfEffectiveness' | 'verificationOfEffectivenessStatus' | 'verificationOfEffectivenessSignedBy' | 'verificationOfEffectivenessSignedDate' | 'preventiveAction' | 'preventiveActionStatus' | 'preventiveActionSignedBy' | 'preventiveActionSignedDate' | 'costOfLabour' | 'costOfMaterial' | 'costAdjustment' | 'costTotal' | 'completedStatus' | 'completedSignedBy' | 'completedSignedDate';
}

const filter = [
  { id: 'incidentNumber' },
  { id: 'incidentDate' },
  { id: 'reportedBy' },
  { id: 'product' },
  { id: 'partNumber' },
  { id: 'grnNumber' },
  { id: 'orderNumber' },
  { id: 'defectiveQuantity' },
  { id: 'incidentLocation' },
  { id: 'category' },
  { id: 'subcategory' },
  { id: 'furtherSubcategory' },
  { id: 'description' },
  { id: 'assignedTo' },
  { id: 'incidentDescription' },
  { id: 'incidentJustified' },
  { id: 'containmentAction' },
  { id: 'containmentActionStatus' },
  { id: 'containmentActionSignedBy' },
  { id: 'containmentActionSignedDate' },
  { id: 'rootCause' },
  { id: 'rootCauseCategory' },
  { id: 'rootCauseStatus' },
  { id: 'rootCauseSignedBy' },
  { id: 'rootCauseSignedDate' },
  { id: 'correctiveAction' },
  { id: 'correctiveActionStatus' },
  { id: 'correctiveActionSignedBy' },
  { id: 'correctiveActionSignedDate' },
  { id: 'verificationOfEffectiveness' },
  { id: 'verificationOfEffectivenessStatus' },
  { id: 'verificationOfEffectivenessSignedBy' },
  { id: 'verificationOfEffectivenessSignedDate' },
  { id: 'preventiveAction' },
  { id: 'preventiveActionStatus' },
  { id: 'preventiveActionSignedBy' },
  { id: 'preventiveActionSignedDate' },
  { id: 'costOfLabour' },
  { id: 'costOfMaterial' },
  { id: 'costAdjustment' },
  { id: 'costTotal' },
  { id: 'completedStatus' },
  { id: 'completedSignedBy' },
  { id: 'completedSignedDate' },
  { id: 'status' },
] as Filter[];

function getChartData(
  rows: Data[],
  filter: Filter[],
  userMap: Map<string, { name: string }>,
  investigationMap: Map<string, InvestigationType>,
) {
  return rows
    .map(row => filter.map(value => {
      switch (value.id) {
        case 'reportedBy':
          return (row[value.id] && userMap.get(row[value.id])?.name) || '';
        case 'incidentNumber':
        case 'product':
        case 'partNumber':
        case 'defectiveQuantity':
        case 'investigationId':
          return row[value.id] || '';
        case 'incidentLocation':
        case 'category':
        case 'subcategory':
        case 'furtherSubcategory':
          return row[value.id]?.title || '';
        case 'assignedTo':
        case 'containmentActionSignedBy': 
        case 'rootCauseSignedBy':
        case 'correctiveActionSignedBy':
        case 'verificationOfEffectivenessSignedBy':
        case 'preventiveActionSignedBy':
        case 'completedSignedBy': {
          if (!row.investigationId) {
            return '';
          }
          const investigation = investigationMap.get(row.investigationId);
          if (!investigation) {
            return '';
          }
          const userId = investigation[value.id];
          return (userId && userMap.get(userId)?.name) || '';
        }
        case 'incidentDescription':
        case 'containmentAction': 
        case 'rootCause':
        case 'correctiveAction':
        case 'verificationOfEffectiveness':
        case 'preventiveAction': {
            if (!row.investigationId) {
            return '';
          }
          const investigation = investigationMap.get(row.investigationId);
          if (!investigation) {
            return '';
          }
          return investigation[value.id] || '';
        }
        case 'containmentActionStatus': 
        case 'rootCauseStatus':
        case 'correctiveActionStatus':
        case 'verificationOfEffectivenessStatus':
        case 'preventiveActionStatus':
        case 'completedStatus': {
            if (!row.investigationId) {
            return '';
          }
          const investigation = investigationMap.get(row.investigationId);
          if (!investigation) {
            return '';
          }
          const key = `${value.id.substring(0, value.id.length - 6)}SignedBy`;
          return investigation[key as keyof typeof investigation] ? 'Completed' : 'Open';
        }
        case 'containmentActionSignedDate': 
        case 'rootCauseSignedDate':
        case 'correctiveActionSignedDate':
        case 'verificationOfEffectivenessSignedDate':
        case 'preventiveActionSignedDate':
        case 'completedSignedDate': {
          if (!row.investigationId) {
            return '';
          }
          const investigation = investigationMap.get(row.investigationId);
          if (!investigation) {
            return '';
          }
          return (investigation[value.id] && formatDate(investigation[value.id])) || '';
        }
        case 'incidentJustified': {
          if (!row.investigationId) {
            return 'Yes';
          }
          const investigation = investigationMap.get(row.investigationId);
          if (!investigation) {
            return 'Yes';
          }
          if (investigation.incidentJustified === false) {
            return 'No';
          }
          return 'Yes';
        }
        case 'rootCauseCategory': {
          if (!row.investigationId) {
            return '';
          }
          const investigation = investigationMap.get(row.investigationId);
          if (!investigation) {
            return '';
          }
          return investigation[value.id]?.title || '';
        }  
        case 'costOfLabour':
        case 'costOfMaterial':
        case 'costAdjustment': {
          if (!row.investigationId) {
            return '';
          }
          const investigation = investigationMap.get(row.investigationId);
          if (!investigation) {
            return '';
          }

          return typeof investigation[value.id] === 'number'
            ? investigation[value.id]
            : '';
        }
        case 'costTotal': {
          if (!row.investigationId) {
            return '';
          }
          const investigation = investigationMap.get(row.investigationId);
          if (!investigation) {
            return '';
          }

          return (+(investigation.costOfLabour ?? 0) + (investigation.costOfMaterial ?? 0) + (investigation.costAdjustment ?? 0));
        }
        default:
          return row[value.id];
      }
    }));
}

export function getValue(id: string, value: null | string | number) {
  if (['defectiveQuantity', 'costOfLabour', 'costOfMaterial', 'costAdjustment', 'costTotal'].includes(id)) {
    const quantity = +(value ?? 0);

    return Number.isNaN(quantity) ? 0 : quantity;
  }

  if (value === null || value === undefined || value === '') {
    return 0;
  }

  return 1;
}

export default function useData() {
  const { incidents } = useIncidents();
  const { userMap } = useUsers();
  const { investigationMap } = useInvestigations();

  const rawData = getChartData(incidents.incidents, filter, userMap, investigationMap);

  return rawData;
}
