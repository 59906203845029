import { memo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Icon, IconButton, Link, Grid, GridList, GridListTile, GridListTileBar, Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { Lightbox } from 'mui-lightbox';
import extensions from './extensions';
import { downloadAsZip } from '../../utils/download';

const useStyles = makeStyles(() => ({
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  tile: {
    cursor: 'pointer',
  },
  imageIcon: {
    height: '100%',
    padding: '3px',
  },
}));

interface Props {
  title?: string;
  attachments?: {
    id: string;
    name: string;
    url: string;
    type: string;
  }[];
  className: string;
}

function getIconType(filename: string) {
  // Extract extension from the filename
  const extension = filename.split('.').pop()!.toLowerCase();

  const extensionWithDot = `.${extension}` as unknown as keyof typeof extensions;
  if (extension && extensionWithDot in extensions) {
    return extensions[extensionWithDot];
  }

  return 'unknown';
}

function Attachments({ title = 'Attachments', attachments, className }: Props) {
  const classes = useStyles();
  const [lightbox, setLightbox] = useState<false | number>(false);

  if (!attachments || attachments.length === 0) {
    return null;
  }

  return (
    <Grid item xs={12}>
      <Typography
        variant="subtitle1"
        color="textPrimary"
        className={className}
      >
        {title}
        <IconButton
          edge="end"
          aria-label="delete"
          onClick={() => downloadAsZip('IncidentAttachments', attachments.map(attachment => attachment.url))}
        >
          <CloudDownloadIcon />
        </IconButton>
      </Typography>
      {
        <GridList className={classes.gridList} cols={4.5}>
          {
            attachments.filter(attachment => attachment.type.includes('image')).map((attachment, index) => (
              <GridListTile
                key={attachment.id}
                onClick={() => setLightbox(index)}
                className={classes.tile}
              >
                <img
                  src={attachment.url}
                  alt={attachment.name}
                />
                <GridListTileBar
                  actionIcon={
                    <IconButton component={Link} download href={attachment.url}>
                      <CloudDownloadIcon />
                    </IconButton>
                  }
                />
              </GridListTile>
            ))
          }
        </GridList>
      }
      <Grid item xs={12}>
        <List dense={false}>
          {
            attachments.filter(attachment => !attachment.type.includes('image')).map(attachment => (
              <Link download href={attachment.url} key={attachment.id}>
                <ListItem>
                  <ListItemIcon>
                    <Icon>
                      <img className={classes.imageIcon} alt="" src={`/svg/${getIconType(attachment?.name || '')}.svg`} />
                    </Icon>
                  </ListItemIcon>
                  <ListItemText
                    primary={ attachment.name }
                  />
                </ListItem>
              </Link>  
            ))
          }
        </List>
      </Grid>
      {
        lightbox !== false && (
          <Lightbox
            images={attachments.filter(attachment => attachment.type.includes('image')).map(attachment => ({ src: attachment.url }))}
            initialImage={lightbox}
            open
            onClose={() => setLightbox(false)}
          />
        )
      }
    </Grid>
  );
}

export default memo(Attachments);
