export enum IncidentsActionType {
  AddIncident = 'ADD_INCIDENT',
  UpdateIncident = 'UPDATE_INCIDENT',
  SynchroniseIncidents = 'SYNCHRONISE_INCIDENTS',
  ResetIncidents = 'RESET_INCIDENTS',
}

export type IncidentType = {
  id: string;
  incidentNumber: string | null;
  reportedBy: string;
  incidentDate: string;
  product: string | null;
  partNumber: string | null;
  defectiveQuantity: string | null;
  grnNumber: string;
  orderNumber: string;
  incidentLocation: { id: string; title: string; } | null;
  category: { id: string; title: string; } | null;
  subcategory: { id: string; title: string; } | null;
  furtherSubcategory: { id: string; title: string; } | null;
  description: string;
  investigationId: string | null;
  attachments: {
    id: string;
    name: string;
    type: string;
    size: number;
    url: string;
  }[];
  status: string;
  created_at: string;
  updated_at: string;
}

export type IncidentsStateType = {
  isLoading: boolean;
  incidents: IncidentType[];
};

interface AddIncidentsAction {
  type: IncidentsActionType.AddIncident;
  incident: IncidentType;
}

interface UpdateIncidentsAction {
  type: IncidentsActionType.UpdateIncident;
  incident: IncidentType;
}

interface SynchroniseIncidentsAction {
  type: IncidentsActionType.SynchroniseIncidents;
  incidents: IncidentType[];
}

interface ResetIncidentsAction {
  type: IncidentsActionType.ResetIncidents;
}

export type IncidentsActionTypes = AddIncidentsAction |
  UpdateIncidentsAction |
  SynchroniseIncidentsAction |
  ResetIncidentsAction;
