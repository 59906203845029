export enum InvestigationsActionType {
  AddInvestigation = 'ADD_INCIDENT',
  UpdateInvestigation = 'UPDATE_INCIDENT',
  SynchroniseInvestigations = 'SYNCHRONISE_INCIDENTS',
  ResetInvestigations = 'RESET_INCIDENTS',
}

export type InvestigationType = {
  id: string;
  assignedTo: string;
  incidentDescription: string;
  incidentDescriptionCopied?: boolean;
  incidentJustified: boolean | null;
  containmentAction: string;
  containmentActionCopied?: boolean;
  containmentActionSignedBy: string | null;
  containmentActionSignedDate: string | null;
  rootCause: string;
  rootCauseCopied?: boolean;
  rootCauseCategory: { id: string; title: string; } | null;
  rootCauseCategoryCopied?: boolean;
  rootCauseSignedBy: string | null;
  rootCauseSignedDate: string | null;
  correctiveAction: string;
  correctiveActionCopied?: boolean;
  correctiveActionSignedBy: string | null;
  correctiveActionSignedDate: string | null;
  verificationOfEffectiveness: string;
  verificationOfEffectivenessCopied?: boolean;
  verificationOfEffectivenessSignedBy: string | null;
  verificationOfEffectivenessSignedDate: string | null;
  preventiveAction: string;
  preventiveActionCopied?: boolean;
  preventiveActionSignedBy: string | null;
  preventiveActionSignedDate: string | null;
  defectiveProductQuantity: string | null;
  additionalNotes: string;
  additionalNotesCopied?: boolean;
  additionalNotesSignedBy: string | null;
  additionalNotesSignedDate: string | null;
  costOfLabour: number | null;
  costOfLabourCopied?: boolean;
  costOfMaterial: number | null;
  costOfMaterialCopied?: boolean;
  costAdjustment: number | null;
  costAdjustmentCopied?: boolean;
  completed: boolean;
  completedSignedBy: string | null;
  completedSignedDate: string | null;
  attachments: {
    id: string;
    name: string;
    type: string;
    size: number;
    url: string;
  }[];
  status: string;
  created_at: string;
  updated_at: string;
}

export type InvestigationsStateType = {
  isLoading: boolean;
  investigations: InvestigationType[];
};

interface AddInvestigationsAction {
  type: InvestigationsActionType.AddInvestigation;
  investigation: InvestigationType;
}

interface UpdateInvestigationsAction {
  type: InvestigationsActionType.UpdateInvestigation;
  investigation: InvestigationType;
}

interface SynchroniseInvestigationsAction {
  type: InvestigationsActionType.SynchroniseInvestigations;
  investigations: InvestigationType[];
}

interface ResetInvestigationsAction {
  type: InvestigationsActionType.ResetInvestigations;
}

export type InvestigationsActionTypes = AddInvestigationsAction |
  UpdateInvestigationsAction |
  SynchroniseInvestigationsAction |
  ResetInvestigationsAction;
