import { saveAs } from 'file-saver'
import JSZip from 'jszip';
import axios from 'axios';

export function downloadAsZip(filename: string, urls: string[]) {
  const zip = new JSZip();

  const request = async (url: string) => {
    const filename = url.split('/').pop()!;

    const response = await axios.get(url, { responseType: 'blob' });

    zip.file(filename, response.data, { binary: true });
  };

  Promise
    .all(urls.map(request))
    .then(() => {
      zip.generateAsync({ type: 'blob' }).then(content => {
        saveAs(content, `${filename}.zip`);
      });
    });
}
