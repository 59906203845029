import { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '../Tooltip';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 0,
      flexGrow: 1,
    },
    fab: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  }),
);

interface Props {
  investigationId: string;
}

export default function InvestigationFab({ investigationId }: Props) {
  const classes = useStyles();
  const [redirect, setRedirect] = useState(false);

  if (redirect) {
    return <Redirect to={`/investigation/${investigationId}/edit`} />;
  }

  return (
    <div className={classes.root}>
      <Tooltip
        title="Edit investigation"
        placement="top-start"
      >
        <Fab
          color="primary"
          aria-label="Edit"
          className={classes.fab}
          onClick={() => setRedirect(true)}
        >
          <EditIcon />
        </Fab>
      </Tooltip>
    </div>
  );
}
