import { format } from 'date-fns';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import QRCode from 'qrcode';
import type { IncidentType } from '../providers/Incidents/store/types';
import type { InvestigationType } from '../providers/Investigations/store/types';
import type { UserType } from '../providers/Users/store/types';
import { formatCurrency } from '../utils/price';
import { formatDate } from './incidents';

const ptMm = 0.3527777778;

function drawText(
  doc: jsPDF,
  text: string,
  xPos: number,
  yPos: number,
  width: number,
  height: number,
  horizontalCentred = false,
  verticalCentred = false,
) {
  const xOffset = horizontalCentred ? width / 2 : 0;

  const lineHeight = doc.getLineHeight() * ptMm;
  const yOffset = verticalCentred ? (height - (height - lineHeight) / 2) : lineHeight;

  doc.text(text, xPos + xOffset, yPos + yOffset, {
    align: horizontalCentred ? 'center' : 'left',
    maxWidth: width,
  });
}

function wrapText(
  doc: jsPDF,
  text: string,
  maximumWidth: number,
  maximumLines: number,
) {
  const originalLines = doc.splitTextToSize(text, maximumWidth);

  if (originalLines.length <= maximumLines) {
    return text;
  }

  const lines = doc.splitTextToSize(text, maximumWidth)
    .slice(0, 7)
    .join('\n');
  
  return lines;
}

export async function generateTag(incident: IncidentType, userMap: Map<string, UserType>) {
  const doc = new jsPDF('p', 'mm', [76, 48]);

  const filename = `incident-tag-${incident.incidentNumber ?? ''}`;

  doc.setProperties({
    title: `Incident Tag ${incident.incidentNumber ?? ''}`,
    creator: 'https://corrosionerosion.com/'
  });

  doc.setFontSize(6);
  doc.setFont(undefined as unknown as string, 'bold');
  drawText(doc, 'Incident Number', 2.86, 1.58, 21.14, 2.89, false, true);

  doc.setFontSize(10);
  doc.setFont(undefined as unknown as string, 'bold');
  drawText(doc, incident.incidentNumber ?? '', 2.86, 4.47, 21.14, 5.79, false, true);

  doc.setFontSize(6);
  doc.setFont(undefined as unknown as string, 'bold');
  drawText(doc, 'Reported by', 2.86, 10.26, 21.14, 2.89, false, true);

  doc.setFontSize(6);
  doc.setFont(undefined as unknown as string, 'normal');

  const reportedBy = userMap.get(incident.reportedBy)?.name ?? '';
  drawText(doc, reportedBy, 2.86, 13.15, 21.14, 2.89, false, true);

  const qrCodeImage = await QRCode.toDataURL(`https://corrosionerosion.com/incident/${incident.id}`, { errorCorrectionLevel: 'L' });
  doc.addImage(qrCodeImage, 'PNG', 24, 1, 20, 20);

  doc.setFontSize(6);
  doc.setFont(undefined as unknown as string, 'bold');
  drawText(doc, 'Incident Date', 2.86, 19.93, 21.14, 2.89, false, true);

  doc.setFontSize(6);
  doc.setFont(undefined as unknown as string, 'normal');
  drawText(doc, formatDate(incident.incidentDate), 2.86, 22.83, 21.14, 2.89, false, true);

  doc.setFontSize(6);
  doc.setFont(undefined as unknown as string, 'bold');
  drawText(doc, 'Product', 24, 19.93, 21.14, 2.89, false, true);

  doc.setFontSize(6);
  doc.setFont(undefined as unknown as string, 'normal');
  drawText(doc, incident.product ?? '', 24, 22.83, 21.14, 2.89, false, true);

  doc.setFontSize(6);
  doc.setFont(undefined as unknown as string, 'bold');
  drawText(doc, 'Part Number', 2.86, 25.72, 21.14, 2.89, false, true);

  doc.setFontSize(6);
  doc.setFont(undefined as unknown as string, 'normal');
  drawText(doc, incident.partNumber ?? '', 2.86, 28.61, 21.14, 2.89, false, true);

  doc.setFontSize(6);
  doc.setFont(undefined as unknown as string, 'bold');
  drawText(doc, 'Defective Quantity', 24, 25.72, 21.14, 2.89, false, true);

  doc.setFontSize(6);
  doc.setFont(undefined as unknown as string, 'normal');
  drawText(doc, `${incident.defectiveQuantity ?? ''}`, 24, 28.61, 21.14, 2.89, false, true);

  doc.setFontSize(6);
  doc.setFont(undefined as unknown as string, 'bold');
  drawText(doc, 'GRN Number', 2.86, 31.51, 21.14, 2.89, false, true);

  doc.setFontSize(6);
  doc.setFont(undefined as unknown as string, 'normal');
  drawText(doc, incident.grnNumber ?? '', 2.86, 34.40, 21.14, 2.89, false, true);

  doc.setFontSize(6);
  doc.setFont(undefined as unknown as string, 'bold');
  drawText(doc, 'Order Number', 24, 31.51, 21.14, 2.89, false, true);

  doc.setFontSize(6);
  doc.setFont(undefined as unknown as string, 'normal');
  drawText(doc, incident.orderNumber ?? '', 24, 34.40, 21.14, 2.89, false, true);

  doc.setFontSize(6);
  doc.setFont(undefined as unknown as string, 'bold');
  drawText(doc, 'Description of the incident', 2.86, 39.18, 42.29, 5.26, false, true);

  doc.setFontSize(6);
  doc.setFont(undefined as unknown as string, 'normal');

  const incidentDescription = wrapText(doc, incident.description, 42.29, 7);

  drawText(doc, incidentDescription, 2.86, 44.44, 42.29, 20.25);

  doc.setFontSize(6);
  doc.setFont(undefined as unknown as string, 'normal');
  drawText(doc, 'QC', 2.86, 67.74, 10.57, 6.31, true, false);
  drawText(doc, 'NC', 13.43, 67.74, 10.57, 6.31, true, false);
  drawText(doc, 'CONC', 24, 67.74, 10.57, 6.31, true, false);
  drawText(doc, 'Scrap', 34.57, 67.74, 10.57, 6.31, true, false);

  doc.setFontSize(14);
  doc.setFont(undefined as unknown as string, 'normal');
  drawText(doc, '    *', 2.86, 64.49, 10.57, 6.31, true, true);
  drawText(doc, '    *', 13.43, 64.49, 10.57, 6.31, true, true);
  drawText(doc, '      *', 24, 64.49, 10.57, 6.31, true, true);
  drawText(doc, '      *', 34.57, 64.49, 10.57, 6.31, true, true);

  doc.setFontSize(6);
  doc.setFont(undefined as unknown as string, 'normal');
  drawText(doc, '* Add additional info on reverse.', 2.86, 71.00, 42.29, 3.42, true, true);

  doc.save(filename);
}

function formatIncidentStatus(status: string) {
  switch (status) {
    case 'draft':
      return 'Draft';
    case 'cancelled':
      return 'Cancelled';
    case 'submitted':
      return 'Submitted';
    case 'pending-update':
      return 'Pending Update'
    case 'acknowledged':
      return 'Acknowledged';
    case 'rejected':
      return 'Rejected';
    case 'under-investigation':
      return 'Under Investigation';
    case 'investigation-completed':
      return 'Investigation Completed';
    case 'closed':
      return 'Closed';
    default:
      return '';
  }
}

function formatInvestigationStatus(status: string) {
  switch (status) {
    case 'opened':
      return 'Opened';
    case 'completed':
      return 'Completed';
    default:
      return '';
  }
}

export async function generateReport(
  incident: IncidentType,
  investigation: InvestigationType | undefined,
  userMap: Map<string, UserType>,
  partNumberDescriptionMap: Map<string, string>,
) {
  const factor = ptMm; // 2.5;
  const column1Width = 39.67; // 128.82;
  const column2Width = 11.8; // 38.3180237;
  const column3Width = 27.85; // 90.4370305;
  const column4Width = 26.02; // 84.49448954;
  const column5Width = 13.65; // 44.32551046;
  const column6Width = 39.69; // 128.8849458;

  const logoImage = new Image();

  await new Promise((resolve, reject) => {
    logoImage.onload = resolve;
    logoImage.onerror = reject;
    logoImage.src = '/assets/images/logos/logo.svg';
  });

  const logoCanvas = document.createElement('canvas');
  logoCanvas.width = 2 * logoImage.naturalWidth;
  logoCanvas.height = 2 * logoImage.naturalHeight;
  const context = logoCanvas.getContext('2d');
  context?.drawImage(logoImage, 0, 0, logoCanvas.width, logoCanvas.height);

  const doc = new jsPDF('p', 'pt', 'a4');

  doc.setProperties({
    title: `Incident Tag ${incident.incidentNumber ?? ''}`,
    creator: 'https://corrosionerosion.com/'
  });

  doc.setFontSize(12);
  doc.setFont(undefined as unknown as string, 'bold');
  doc.setTextColor('#4472c4');
  doc.text('Incident Details', (25.4 + 1.7) / ptMm, (20 + 3 + 6 + 6 + 6) / ptMm, {
    align: 'left',
    maxWidth: 161,
  });

  doc.setFontSize(9);
  doc.setFont(undefined as unknown as string, 'bold');
  doc.setTextColor('#000000');
  doc.text('Incident Status', (25.4 + 1.7) / ptMm, (20 + 3 + 6 + 6 + 6 + 8) / ptMm, {
    align: 'left',
    maxWidth: 161,
  });

  doc.setFontSize(10);
  doc.setFont(undefined as unknown as string, 'normal');
  doc.setTextColor('#000000');
  doc.text(formatIncidentStatus(incident.status), (25.4 + 1.7) / ptMm, (20 + 3 + 6 + 6 + 6 + 8 + 7) / ptMm, {
    align: 'left',
    maxWidth: 161,
  });  

  autoTable(doc, {
    startY: (20 + 3 + 6 + 6 + 6 + 8 + 7 + 5) / ptMm,
    margin: [
      (25.4 + 12.5) / ptMm, // top
      (25.4) / ptMm, // right
      (25.4 + 7.9) / ptMm, // bottom
      (25.4) / ptMm, // left
    ],
    body: [
      [
        {
          rowSpan: 1,
          colSpan: 1,
          styles: {
            fillColor: '#99ccff',
            textColor: '#000000',
            fontSize: 9,
            fontStyle: 'bold',
            halign: 'left',
            valign: 'top',
            minCellHeight: 12.98916057,
            cellWidth: (column1Width + column2Width) / factor,
          },
          content: 'Incident Number',
        },
        {
          rowSpan: 1,
          colSpan: 1,
          styles: {
            fillColor: '#99ccff',
            textColor: '#000000',
            fontSize: 9,
            fontStyle: 'bold',
            halign: 'left',
            valign: 'top',
            minCellHeight: 12.98916057,
            cellWidth: (column3Width + column4Width) / factor,
          },
          content: 'Reported by',
        },
        {
          rowSpan: 1,
          colSpan: 1,
          styles: {
            fillColor: '#99ccff',
            textColor: '#000000',
            fontSize: 9,
            fontStyle: 'bold',
            halign: 'left',
            valign: 'top',
            minCellHeight: 12.98916057,
            cellWidth: (column5Width + column6Width) / factor,
          },
          content: 'Incident Date',
        },
      ],
      [
        {
          rowSpan: 1,
          colSpan: 1,
          styles: {
            fillColor: '#ffffff',
            textColor: '#000000',
            fontSize: 10,
            halign: 'left',
            valign: 'middle',
            minCellHeight: 29.22561129,
            cellWidth: (column1Width + column2Width) / factor,
          },
          content: `${incident.incidentNumber ?? ''}`,
        },
        {
          rowSpan: 1,
          colSpan: 1,
          styles: {
            fillColor: '#ffffff',
            textColor: '#000000',
            fontSize: 10,
            halign: 'left',
            valign: 'middle',
            minCellHeight: 29.22561129,
            cellWidth: (column3Width + column4Width) / factor,
          },
          content: `${userMap.get(incident.reportedBy)?.name ?? ''}`,
        },
        {
          rowSpan: 1,
          colSpan: 1,
          styles: {
            fillColor: '#ffffff',
            textColor: '#000000',
            fontSize: 10,
            halign: 'left',
            valign: 'middle',
            minCellHeight: 29.22561129,
            cellWidth: (column5Width + column6Width) / factor,
          },
          content: `${formatDate(incident.incidentDate)}`,
        },
      ],
      [
        {
          rowSpan: 1,
          colSpan: 1,
          styles: {
            fillColor: '#99ccff',
            textColor: '#000000',
            fontSize: 9,
            fontStyle: 'bold',
            halign: 'left',
            valign: 'top',
            minCellHeight: 12.98916057,
            cellWidth: (column1Width + column2Width) / factor,
          },
          content: 'Product',
        },
        {
          rowSpan: 1,
          colSpan: 1,
          styles: {
            fillColor: '#99ccff',
            textColor: '#000000',
            fontSize: 9,
            fontStyle: 'bold',
            halign: 'left',
            valign: 'top',
            minCellHeight: 12.98916057,
            cellWidth: (column3Width + column4Width) / factor,
          },
          content: 'Part Number',
        },
        {
          rowSpan: 1,
          colSpan: 1,
          styles: {
            fillColor: '#99ccff',
            textColor: '#000000',
            fontSize: 9,
            fontStyle: 'bold',
            halign: 'left',
            valign: 'top',
            minCellHeight: 12.98916057,
            cellWidth: (column5Width + column6Width) / factor,
          },
          content: 'Description',
        },
      ],
      [
        {
          rowSpan: 1,
          colSpan: 1,
          styles: {
            fillColor: '#ffffff',
            textColor: '#000000',
            fontSize: 10,
            halign: 'left',
            valign: 'middle',
            minCellHeight: 29.22561129,
            cellWidth: (column1Width + column2Width) / factor,
          },
          content: `${incident.product ?? '—'}`,
        },
        {
          rowSpan: 1,
          colSpan: 1,
          styles: {
            fillColor: '#ffffff',
            textColor: '#000000',
            fontSize: 10,
            halign: 'left',
            valign: 'middle',
            minCellHeight: 29.22561129,
            cellWidth: (column3Width + column4Width) / factor,
          },
          content: `${incident.partNumber ?? '—'}`,
        },
        {
          rowSpan: 1,
          colSpan: 1,
          styles: {
            fillColor: '#ffffff',
            textColor: '#000000',
            fontSize: 10,
            halign: 'left',
            valign: 'middle',
            minCellHeight: 29.22561129,
            cellWidth: (column5Width + column6Width) / factor,
          },
          content: `${(incident.partNumber && partNumberDescriptionMap.get(incident.partNumber)) || '—'}`,
        },
      ],
    ],
  });

  autoTable(doc, {
    startY: (doc as any).lastAutoTable.finalY,
    margin: [
      (25.4 + 12.5) / ptMm, // top
      (25.4) / ptMm, // right
      (25.4 + 7.9) / ptMm, // bottom
      (25.4) / ptMm, // left
    ],
    body: [
      [
        {
          rowSpan: 1,
          colSpan: 1,
          styles: {
            fillColor: '#99ccff',
            textColor: '#000000',
            fontSize: 9,
            fontStyle: 'bold',
            halign: 'left',
            valign: 'top',
            minCellHeight: 12.98916057,
            cellWidth: (column1Width) / factor,
          },
          content: 'GRN Number',
        },
        {
          rowSpan: 1,
          colSpan: 1,
          styles: {
            fillColor: '#99ccff',
            textColor: '#000000',
            fontSize: 9,
            fontStyle: 'bold',
            halign: 'left',
            valign: 'top',
            minCellHeight: 12.98916057,
            cellWidth: (column2Width + column3Width) / factor,
          },
          content: 'Order Number',
        },
        {
          rowSpan: 1,
          colSpan: 1,
          styles: {
            fillColor: '#99ccff',
            textColor: '#000000',
            fontSize: 9,
            fontStyle: 'bold',
            halign: 'left',
            valign: 'top',
            minCellHeight: 12.98916057,
            cellWidth: (column4Width + column5Width) / factor,
          },
          content: 'Defective Quantity',
        },
        {
          rowSpan: 1,
          colSpan: 1,
          styles: {
            fillColor: '#99ccff',
            textColor: '#000000',
            fontSize: 9,
            fontStyle: 'bold',
            halign: 'left',
            valign: 'top',
            minCellHeight: 12.98916057,
            cellWidth: (column6Width) / factor,
          },
          content: 'Defective Product',
        },
      ],
      [
        {
          rowSpan: 1,
          colSpan: 1,
          styles: {
            fillColor: '#ffffff',
            textColor: '#000000',
            fontSize: 10,
            halign: 'left',
            valign: 'middle',
            minCellHeight: 29.22561129,
            cellWidth: (column1Width) / factor,
          },
          content: `${incident.grnNumber || '—'}`,
        },
        {
          rowSpan: 1,
          colSpan: 1,
          styles: {
            fillColor: '#ffffff',
            textColor: '#000000',
            fontSize: 10,
            halign: 'left',
            valign: 'middle',
            minCellHeight: 29.22561129,
            cellWidth: (column2Width + column3Width) / factor,
          },
          content: `${incident.orderNumber || '—'}`,
        },
        {
          rowSpan: 1,
          colSpan: 1,
          styles: {
            fillColor: '#ffffff',
            textColor: '#000000',
            fontSize: 10,
            halign: 'left',
            valign: 'middle',
            minCellHeight: 29.22561129,
            cellWidth: (column4Width + column5Width) / factor,
          },
          content: `${incident.defectiveQuantity || '—'}`,
        },
        {
          rowSpan: 1,
          colSpan: 1,
          styles: {
            fillColor: '#ffffff',
            textColor: '#000000',
            fontSize: 10,
            halign: 'left',
            valign: 'middle',
            minCellHeight: 29.22561129,
            cellWidth: (column6Width) / factor,
          },
          content: `${investigation?.defectiveProductQuantity || '0'}`,
        },
      ],
      [
        {
          rowSpan: 1,
          colSpan: 1,
          styles: {
            fillColor: '#99ccff',
            textColor: '#000000',
            fontSize: 9,
            fontStyle: 'bold',
            halign: 'left',
            valign: 'top',
            minCellHeight: 12.98916057,
            cellWidth: (column1Width) / factor,
          },
          content: 'Location of the Incident',
        },
        {
          rowSpan: 1,
          colSpan: 1,
          styles: {
            fillColor: '#99ccff',
            textColor: '#000000',
            fontSize: 9,
            fontStyle: 'bold',
            halign: 'left',
            valign: 'top',
            minCellHeight: 12.98916057,
            cellWidth: (column2Width + column3Width) / factor,
          },
          content: 'Category',
        },
        {
          rowSpan: 1,
          colSpan: 1,
          styles: {
            fillColor: '#99ccff',
            textColor: '#000000',
            fontSize: 9,
            fontStyle: 'bold',
            halign: 'left',
            valign: 'top',
            minCellHeight: 12.98916057,
            cellWidth: (column4Width + column5Width) / factor,
          },
          content: 'Subcategory Level 1',
        },
        {
          rowSpan: 1,
          colSpan: 1,
          styles: {
            fillColor: '#99ccff',
            textColor: '#000000',
            fontSize: 9,
            fontStyle: 'bold',
            halign: 'left',
            valign: 'top',
            minCellHeight: 12.98916057,
            cellWidth: (column6Width) / factor,
          },
          content: 'Subcategory Level 2',
        },
      ],
      [
        {
          rowSpan: 1,
          colSpan: 1,
          styles: {
            fillColor: '#ffffff',
            textColor: '#000000',
            fontSize: 10,
            halign: 'left',
            valign: 'middle',
            minCellHeight: 29.22561129,
            cellWidth: (column1Width) / factor,
          },
          content: `${incident.incidentLocation?.title ?? '—'}`,
        },
        {
          rowSpan: 1,
          colSpan: 1,
          styles: {
            fillColor: '#ffffff',
            textColor: '#000000',
            fontSize: 10,
            halign: 'left',
            valign: 'middle',
            minCellHeight: 29.22561129,
            cellWidth: (column2Width + column3Width) / factor,
          },
          content: `${incident.category?.title ?? '—'}`,
        },
        {
          rowSpan: 1,
          colSpan: 1,
          styles: {
            fillColor: '#ffffff',
            textColor: '#000000',
            fontSize: 10,
            halign: 'left',
            valign: 'middle',
            minCellHeight: 29.22561129,
            cellWidth: (column4Width + column5Width) / factor,
          },
          content: `${incident.subcategory?.title ?? '—'}`,
        },
        {
          rowSpan: 1,
          colSpan: 1,
          styles: {
            fillColor: '#ffffff',
            textColor: '#000000',
            fontSize: 10,
            halign: 'left',
            valign: 'middle',
            minCellHeight: 29.22561129,
            cellWidth: (column6Width) / factor,
          },
          content: `${incident.furtherSubcategory?.title ?? '—'}`,
        },
      ],
    ],
  });

  autoTable(doc, {
    startY: (doc as any).lastAutoTable.finalY,
    margin: [
      (25.4 + 12.5) / ptMm, // top
      (25.4) / ptMm, // right
      (25.4 + 7.9) / ptMm, // bottom
      (25.4) / ptMm, // left
    ],
    body: [
      [
        {
          rowSpan: 1,
          colSpan: 1,
          styles: {
            fillColor: '#99ccff',
            textColor: '#000000',
            fontSize: 9,
            fontStyle: 'bold',
            halign: 'left',
            valign: 'top',
            minCellHeight: 12.98916057,
            cellWidth: (column1Width + column2Width + column3Width + column4Width + column5Width + column6Width) / factor,
          },
          content: 'Description of the Incident',
        },
      ],
      [
        {
          rowSpan: 1,
          colSpan: 1,
          styles: {
            fillColor: '#ffffff',
            textColor: '#000000',
            fontSize: 10,
            halign: 'left',
            valign: 'middle',
            minCellHeight: 29.22561129,
            cellWidth: (column1Width + column2Width + column3Width + column4Width + column5Width + column6Width) / factor,
          },
          content: `${incident.description || '—'}`,
        },
      ],
    ],
  });

  if (investigation) {
    doc.addPage();

    doc.setFontSize(12);
    doc.setFont(undefined as unknown as string, 'bold');
    doc.setTextColor('#4472c4');
    doc.text('Investigation Details', (25.4 + 1.7) / ptMm, (20 + 3 + 6 + 6 + 6) / ptMm, {
      align: 'left',
      maxWidth: 161,
    });

    doc.setFontSize(9);
    doc.setFont(undefined as unknown as string, 'bold');
    doc.setTextColor('#000000');
    doc.text('Investigation Status', (25.4 + 1.7) / ptMm, (20 + 3 + 6 + 6 + 6 + 8) / ptMm, {
      align: 'left',
      maxWidth: 161,
    });

    doc.setFontSize(10);
    doc.setFont(undefined as unknown as string, 'normal');
    doc.setTextColor('#000000');
    doc.text(formatInvestigationStatus(investigation.status), (25.4 + 1.7) / ptMm, (20 + 3 + 6 + 6 + 6 + 8 + 7) / ptMm, {
      align: 'left',
      maxWidth: 161,
    });

    doc.setFontSize(9);
    doc.setFont(undefined as unknown as string, 'bold');
    doc.setTextColor('#000000');
    doc.text('Main Investigator', (25.4 + 1.7 + 79.6) / ptMm, (20 + 3 + 6 + 6 + 6 + 8) / ptMm, {
      align: 'left',
      maxWidth: 161,
    });

    doc.setFontSize(10);
    doc.setFont(undefined as unknown as string, 'normal');
    doc.setTextColor('#000000');
    doc.text(`${userMap.get(investigation.assignedTo)?.name ?? ''}`, (25.4 + 1.7 + 79.6) / ptMm, (20 + 3 + 6 + 6 + 6 + 8 + 7) / ptMm, {
      align: 'left',
      maxWidth: 161,
    });

    autoTable(doc, {
      startY: (20 + 3 + 6 + 6 + 6 + 8 + 7 + 5) / ptMm,
      margin: [
        (25.4 + 12.5) / ptMm, // top
        (25.4) / ptMm, // right
        (25.4 + 7.9) / ptMm, // bottom
        (25.4) / ptMm, // left
      ],
      body: [
        [
          {
            rowSpan: 1,
            colSpan: 1,
            styles: {
              fillColor: '#ffc000',
              textColor: '#000000',
              fontSize: 9,
              fontStyle: 'bold',
              halign: 'left',
              valign: 'top',
              minCellHeight: 12.98916057,
              cellWidth: (column1Width + column2Width + column3Width + column4Width + column5Width + column6Width) / factor,
            },
            content: 'Description of the Incident',
          },
        ],
        [
          {
            rowSpan: 1,
            colSpan: 1,
            styles: {
              fillColor: '#ffffff',
              textColor: '#000000',
              fontSize: 10,
              halign: 'left',
              valign: 'middle',
              minCellHeight: 29.22561129,
              cellWidth: (column1Width + column2Width + column3Width + column4Width + column5Width + column6Width) / factor,
            },
            content: `${investigation.incidentDescription || '—'}`,
          },
        ],
        [
          {
            rowSpan: 1,
            colSpan: 1,
            styles: {
              fillColor: '#ffc000',
              textColor: '#000000',
              fontSize: 9,
              fontStyle: 'bold',
              halign: 'left',
              valign: 'top',
              minCellHeight: 12.98916057,
              cellWidth: (column1Width + column2Width + column3Width + column4Width + column5Width + column6Width) / factor,
            },
            content: 'Containment Action',
          },
        ],
        [
          {
            rowSpan: 1,
            colSpan: 1,
            styles: {
              fillColor: '#ffffff',
              textColor: '#000000',
              fontSize: 10,
              halign: 'left',
              valign: 'middle',
              minCellHeight: 29.22561129,
              cellWidth: (column1Width + column2Width + column3Width + column4Width + column5Width + column6Width) / factor,
            },
            content: `${investigation.containmentAction || '—'}`,
          },
        ],
        [
          {
            rowSpan: 1,
            colSpan: 1,
            styles: {
              fillColor: '#ffffff',
              textColor: '#000000',
              fontSize: 9,
              fontStyle: 'italic',
              halign: 'right',
              valign: 'middle',
              minCellHeight: 29.22561129,
              cellWidth: (column1Width + column2Width + column3Width + column4Width + column5Width + column6Width) / factor,
            },
            content: investigation.containmentActionSignedBy && investigation.containmentActionSignedDate ? (
              `Completed by ${
                userMap.get(investigation.containmentActionSignedBy)?.name
              } on ${formatDate(investigation.containmentActionSignedDate)}`
            ) : 'In progress',
          },
        ],
        [
          {
            rowSpan: 1,
            colSpan: 1,
            styles: {
              fillColor: '#ffc000',
              textColor: '#000000',
              fontSize: 9,
              fontStyle: 'bold',
              halign: 'left',
              valign: 'top',
              minCellHeight: 12.98916057,
              cellWidth: (column1Width + column2Width + column3Width + column4Width + column5Width + column6Width) / factor,
            },
            content: 'Root Cause',
          },
        ],
        [
          {
            rowSpan: 1,
            colSpan: 1,
            styles: {
              fillColor: '#ffffff',
              textColor: '#000000',
              fontSize: 10,
              halign: 'left',
              valign: 'middle',
              minCellHeight: 29.22561129,
              cellWidth: (column1Width + column2Width + column3Width + column4Width + column5Width + column6Width) / factor,
            },
            content: `${investigation.rootCause || '—'}`,
          },
        ],
        [
          {
            rowSpan: 1,
            colSpan: 1,
            styles: {
              fillColor: '#ffffff',
              textColor: '#000000',
              fontSize: 9,
              fontStyle: 'bold',
              halign: 'left',
              valign: 'middle',
              minCellHeight: 29.22561129,
              cellWidth: (column1Width + column2Width + column3Width + column4Width + column5Width + column6Width) / factor,
            },
            content: 'Root Cause Category',
          },
        ],
        [
          {
            rowSpan: 1,
            colSpan: 1,
            styles: {
              fillColor: '#ffffff',
              textColor: '#000000',
              fontSize: 10,
              halign: 'left',
              valign: 'middle',
              minCellHeight: 29.22561129,
              cellWidth: (column1Width + column2Width + column3Width + column4Width + column5Width + column6Width) / factor,
            },
            content: `${investigation.rootCauseCategory?.title ?? '—'}`,
          },
        ],
        [
          {
            rowSpan: 1,
            colSpan: 1,
            styles: {
              fillColor: '#ffffff',
              textColor: '#000000',
              fontSize: 9,
              fontStyle: 'italic',
              halign: 'right',
              valign: 'middle',
              minCellHeight: 29.22561129,
              cellWidth: (column1Width + column2Width + column3Width + column4Width + column5Width + column6Width) / factor,
            },
            content: investigation.rootCauseSignedBy && investigation.rootCauseSignedDate ? (
              `Completed by ${
                userMap.get(investigation.rootCauseSignedBy)?.name
              } on ${formatDate(investigation.rootCauseSignedDate)}`
            ) : 'In progress',
          },
        ],
        [
          {
            rowSpan: 1,
            colSpan: 1,
            styles: {
              fillColor: '#ffc000',
              textColor: '#000000',
              fontSize: 9,
              fontStyle: 'bold',
              halign: 'left',
              valign: 'top',
              minCellHeight: 12.98916057,
              cellWidth: (column1Width + column2Width + column3Width + column4Width + column5Width + column6Width) / factor,
            },
            content: 'Corrective Action',
          },
        ],
        [
          {
            rowSpan: 1,
            colSpan: 1,
            styles: {
              fillColor: '#ffffff',
              textColor: '#000000',
              fontSize: 10,
              halign: 'left',
              valign: 'middle',
              minCellHeight: 29.22561129,
              cellWidth: (column1Width + column2Width + column3Width + column4Width + column5Width + column6Width) / factor,
            },
            content: `${investigation.correctiveAction || '—'}`,
          },
        ],
        [
          {
            rowSpan: 1,
            colSpan: 1,
            styles: {
              fillColor: '#ffffff',
              textColor: '#000000',
              fontSize: 9,
              fontStyle: 'italic',
              halign: 'right',
              valign: 'middle',
              minCellHeight: 29.22561129,
              cellWidth: (column1Width + column2Width + column3Width + column4Width + column5Width + column6Width) / factor,
            },
            content: investigation.correctiveActionSignedBy && investigation.correctiveActionSignedDate ? (
              `Completed by ${
                userMap.get(investigation.correctiveActionSignedBy)?.name
              } on ${formatDate(investigation.correctiveActionSignedDate)}`
            ) : 'In progress',
          },
        ],
        [
          {
            rowSpan: 1,
            colSpan: 1,
            styles: {
              fillColor: '#ffc000',
              textColor: '#000000',
              fontSize: 9,
              fontStyle: 'bold',
              halign: 'left',
              valign: 'top',
              minCellHeight: 12.98916057,
              cellWidth: (column1Width + column2Width + column3Width + column4Width + column5Width + column6Width) / factor,
            },
            content: 'Verification of Effectiveness',
          },
        ],
        [
          {
            rowSpan: 1,
            colSpan: 1,
            styles: {
              fillColor: '#ffffff',
              textColor: '#000000',
              fontSize: 10,
              halign: 'left',
              valign: 'middle',
              minCellHeight: 29.22561129,
              cellWidth: (column1Width + column2Width + column3Width + column4Width + column5Width + column6Width) / factor,
            },
            content: `${investigation.verificationOfEffectiveness || '—'}`,
          },
        ],
        [
          {
            rowSpan: 1,
            colSpan: 1,
            styles: {
              fillColor: '#ffffff',
              textColor: '#000000',
              fontSize: 9,
              fontStyle: 'italic',
              halign: 'right',
              valign: 'middle',
              minCellHeight: 29.22561129,
              cellWidth: (column1Width + column2Width + column3Width + column4Width + column5Width + column6Width) / factor,
            },
            content: investigation.verificationOfEffectivenessSignedBy && investigation.verificationOfEffectivenessSignedDate ? (
              `Completed by ${
                userMap.get(investigation.verificationOfEffectivenessSignedBy)?.name
              } on ${formatDate(investigation.verificationOfEffectivenessSignedDate)}`
            ) : 'In progress',
          },
        ],
        [
          {
            rowSpan: 1,
            colSpan: 1,
            styles: {
              fillColor: '#ffc000',
              textColor: '#000000',
              fontSize: 9,
              fontStyle: 'bold',
              halign: 'left',
              valign: 'top',
              minCellHeight: 12.98916057,
              cellWidth: (column1Width + column2Width + column3Width + column4Width + column5Width + column6Width) / factor,
            },
            content: 'Preventive Actions',
          },
        ],
        [
          {
            rowSpan: 1,
            colSpan: 1,
            styles: {
              fillColor: '#ffffff',
              textColor: '#000000',
              fontSize: 10,
              halign: 'left',
              valign: 'middle',
              minCellHeight: 29.22561129,
              cellWidth: (column1Width + column2Width + column3Width + column4Width + column5Width + column6Width) / factor,
            },
            content: `${investigation.preventiveAction || '—'}`,
          },
        ],
        [
          {
            rowSpan: 1,
            colSpan: 1,
            styles: {
              fillColor: '#ffffff',
              textColor: '#000000',
              fontSize: 9,
              fontStyle: 'italic',
              halign: 'right',
              valign: 'middle',
              minCellHeight: 29.22561129,
              cellWidth: (column1Width + column2Width + column3Width + column4Width + column5Width + column6Width) / factor,
            },
            content: investigation.preventiveActionSignedBy && investigation.preventiveActionSignedDate ? (
              `Completed by ${
                userMap.get(investigation.preventiveActionSignedBy)?.name
              } on ${formatDate(investigation.preventiveActionSignedDate)}`
            ) : 'In progress',
          },
        ],
        [
          {
            rowSpan: 1,
            colSpan: 1,
            styles: {
              fillColor: '#ffc000',
              textColor: '#000000',
              fontSize: 9,
              fontStyle: 'bold',
              halign: 'left',
              valign: 'top',
              minCellHeight: 12.98916057,
              cellWidth: (column1Width + column2Width + column3Width + column4Width + column5Width + column6Width) / factor,
            },
            content: 'Conclusion / Additional Notes',
          },
        ],
        [
          {
            rowSpan: 1,
            colSpan: 1,
            styles: {
              fillColor: '#ffffff',
              textColor: '#000000',
              fontSize: 10,
              halign: 'left',
              valign: 'middle',
              minCellHeight: 29.22561129,
              cellWidth: (column1Width + column2Width + column3Width + column4Width + column5Width + column6Width) / factor,
            },
            content: `${investigation.additionalNotes || '—'}`,
          },
        ],
        [
          {
            rowSpan: 1,
            colSpan: 1,
            styles: {
              fillColor: '#ffffff',
              textColor: '#000000',
              fontSize: 9,
              fontStyle: 'italic',
              halign: 'right',
              valign: 'middle',
              minCellHeight: 29.22561129,
              cellWidth: (column1Width + column2Width + column3Width + column4Width + column5Width + column6Width) / factor,
            },
            content: investigation.additionalNotesSignedBy && investigation.additionalNotesSignedDate ? (
              `Completed by ${
                userMap.get(investigation.additionalNotesSignedBy)?.name
              } on ${formatDate(investigation.additionalNotesSignedDate)}`
            ) : (investigation.status === 'completed' ? '' : 'In progress'),
          },
        ],
      ],
    });

    autoTable(doc, {
      startY: (doc as any).lastAutoTable.finalY,
      margin: [
        (25.4 + 12.5) / ptMm, // top
        (25.4) / ptMm, // right
        (25.4 + 7.9) / ptMm, // bottom
        (25.4) / ptMm, // left
      ],
      body: [
        [
          {
            rowSpan: 1,
            colSpan: 1,
            styles: {
              fillColor: '#ffc000',
              textColor: '#000000',
              fontSize: 9,
              fontStyle: 'bold',
              halign: 'left',
              valign: 'top',
              minCellHeight: 12.98916057,
              cellWidth: (column1Width + column2Width + column3Width + column4Width + column5Width + column6Width) / 4 / factor,
            },
            content: 'Cost of Labour (£)',
          },
          {
            rowSpan: 1,
            colSpan: 1,
            styles: {
              fillColor: '#ffc000',
              textColor: '#000000',
              fontSize: 9,
              fontStyle: 'bold',
              halign: 'left',
              valign: 'top',
              minCellHeight: 12.98916057,
              cellWidth: (column1Width + column2Width + column3Width + column4Width + column5Width + column6Width) / 4 / factor,
            },
            content: 'Cost of Material (£)',
          },
          {
            rowSpan: 1,
            colSpan: 1,
            styles: {
              fillColor: '#ffc000',
              textColor: '#000000',
              fontSize: 9,
              fontStyle: 'bold',
              halign: 'left',
              valign: 'top',
              minCellHeight: 12.98916057,
              cellWidth: (column1Width + column2Width + column3Width + column4Width + column5Width + column6Width) / 4 / factor,
            },
            content: 'Cost of Adjustment (£)',
          },
          {
            rowSpan: 1,
            colSpan: 1,
            styles: {
              fillColor: '#ffc000',
              textColor: '#000000',
              fontSize: 9,
              fontStyle: 'bold',
              halign: 'left',
              valign: 'top',
              minCellHeight: 12.98916057,
              cellWidth: (column1Width + column2Width + column3Width + column4Width + column5Width + column6Width) / 4 / factor,
            },
            content: 'Total Cost (£)',
          },
        ],
        [
          {
            rowSpan: 1,
            colSpan: 1,
            styles: {
              fillColor: '#ffffff',
              textColor: '#000000',
              fontSize: 10,
              halign: 'left',
              valign: 'middle',
              minCellHeight: 29.22561129,
              cellWidth: (column1Width + column2Width + column3Width + column4Width + column5Width + column6Width) / 4 / factor,
            },
            content: `${formatCurrency(investigation.costOfLabour || '—')}`,
          },
          {
            rowSpan: 1,
            colSpan: 1,
            styles: {
              fillColor: '#ffffff',
              textColor: '#000000',
              fontSize: 10,
              halign: 'left',
              valign: 'middle',
              minCellHeight: 29.22561129,
              cellWidth: (column1Width + column2Width + column3Width + column4Width + column5Width + column6Width) / 4 / factor,
            },
            content: `${formatCurrency(investigation.costOfMaterial || '—')}`,
          },
          {
            rowSpan: 1,
            colSpan: 1,
            styles: {
              fillColor: '#ffffff',
              textColor: '#000000',
              fontSize: 10,
              halign: 'left',
              valign: 'middle',
              minCellHeight: 29.22561129,
              cellWidth: (column1Width + column2Width + column3Width + column4Width + column5Width + column6Width) / 4 / factor,
            },
            content: `${formatCurrency(investigation.costAdjustment || '—')}`,
          },
          {
            rowSpan: 1,
            colSpan: 1,
            styles: {
              fillColor: '#ffffff',
              textColor: '#000000',
              fontSize: 10,
              halign: 'left',
              valign: 'middle',
              minCellHeight: 29.22561129,
              cellWidth: (column1Width + column2Width + column3Width + column4Width + column5Width + column6Width) / 4 / factor,
            },
            content: `${formatCurrency((+(investigation.costOfLabour ?? 0) + (investigation.costOfMaterial ?? 0) + (investigation.costAdjustment ?? 0)) || '—')}`,
          },
        ],
      ],
    });

    autoTable(doc, {
      startY: (doc as any).lastAutoTable.finalY,
      margin: [
        (25.4 + 12.5) / ptMm, // top
        (25.4) / ptMm, // right
        (25.4 + 7.9) / ptMm, // bottom
        (25.4) / ptMm, // left
      ],
      body: [
        [
          {
            rowSpan: 1,
            colSpan: 1,
            styles: {
              fillColor: '#ffc000',
              textColor: '#000000',
              fontSize: 9,
              fontStyle: 'bold',
              halign: 'left',
              valign: 'top',
              minCellHeight: 12.98916057,
              cellWidth: (column1Width + column2Width + column3Width + column4Width + column5Width + column6Width) / factor,
            },
            content: 'Is the Incident Justified?',
          },
        ],
        [
          {
            rowSpan: 1,
            colSpan: 1,
            styles: {
              fillColor: '#ffffff',
              textColor: '#000000',
              fontSize: 10,
              halign: 'left',
              valign: 'middle',
              minCellHeight: 29.22561129,
              cellWidth: (column1Width + column2Width + column3Width + column4Width + column5Width + column6Width) / factor,
            },
            content: `${investigation.incidentJustified !== false ? 'Yes' : 'No'}`,
          },
        ],
      ],
    });
  }

  const pageCount = doc.getNumberOfPages();
    
  for(let pageIndex = 1; pageIndex <= pageCount; pageIndex += 1) {
    doc.setPage(pageIndex);

    doc.addImage(logoCanvas.toDataURL('image/jpg'), 'JPEG', (25.4 + 1.7) / ptMm, 16 / ptMm, 65 / ptMm, 8.67 / ptMm);

    doc.setFontSize(14);
    doc.setFont(undefined as unknown as string, 'bold');
    doc.setTextColor('#000000');
    doc.text(`Incident Number – `, (210 - 25.4 - 1.7 - 23.8) / ptMm, (20 + 3) / ptMm, {
      align: 'right',
      maxWidth: 161 / ptMm,
    });

    doc.setFontSize(16);
    doc.setFont(undefined as unknown as string, 'bold');
    doc.setTextColor('#4472c4');
    doc.text(incident.incidentNumber ?? '', (210 - 25.4 - 1.7) / ptMm, (20 + 3) / ptMm, {
      align: 'right',
      maxWidth: 161 / ptMm,
    });

    doc.setFontSize(11);
    doc.setFont(undefined as unknown as string, 'normal');
    doc.setTextColor('#000000');
    doc.text(`Date of Print: ${format(new Date(), 'dd MMMM yyyy')}`, (210 - 25.4 - 1.7) / ptMm, (20 + 3 + 6) / ptMm, {
      align: 'right',
      maxWidth: 161 / ptMm,
    });

    doc.setFontSize(11);
    doc.setFont(undefined as unknown as string, 'normal');
    doc.setTextColor('#000000');
    doc.text(`Page ${pageIndex} of ${pageCount}`, (210 - 25.4 - 1.7) / ptMm, (20 + 3 + 6 + 6) / ptMm, {
      align: 'right',
      maxWidth: 161 / ptMm,
    });

    doc.addImage('/assets/images/logos/permasense.png', 'PNG', (25.4 + 1.7) / ptMm, (297 - 25.4 - 7.9 + 2.9 + 3.94) / ptMm, 32.5 / ptMm, 3.32 / ptMm);

    doc.setFillColor('#8d9093');
    doc.rect((25.4 + 1.7) / ptMm, (297 - 25.4 - 7.9 + 2.9 + 9.75) / ptMm, (210 - 2 * 25.4 - 3 * 1.7 - 34.38) / ptMm, 0.5 / ptMm, 'F');

    doc.setFontSize(8 /* 3.32 */);
    doc.setFont(undefined as unknown as string, 'normal');
    doc.setTextColor('#8d9093');
    doc.text('www.emerson.com/permasense', (25.4 + 1.7) / ptMm, (297 - 25.4 - 7.9 + 2.9 + 9.75 + 0.5 + 4) / ptMm, {
      align: 'left',
      maxWidth: 161 / ptMm,
    });

    doc.addImage('/assets/images/logos/emerson.jpg', 'JPEG', (210 - 25.4 - 1.7 - 34.38) / ptMm, (297 - 25.4 - 7.9 + 2.9) / ptMm, 34.38 / ptMm, 14.4 / ptMm);
  }

  doc.save(`incident-tag-${incident.incidentNumber ?? ''}`);
}
