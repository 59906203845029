import { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import AuthProvider from './providers/Auth';
import OnlineProvider from './providers/Online';
import MenuProvider from './providers/Menu';
import IncidentsProvider from './providers/Incidents';
import InvestigationsProvider from './providers/Investigations';
import NotificationsProvider from './providers/Notifications';
import LocationsProvider from './providers/Locations';
import PartNumbersProvider from './providers/PartNumbers';
import ProductsProvider from './providers/Products';
import UsersProvider from './providers/Users';
import ProfileProvider from './providers/Profile';
import ReportsProvider from './providers/Reports';
import TemplatesProvider from './providers/Templates';
import Loading from './components/Loading';
import Routes from './Routes';
import theme from './theme';
import ApiSynchroniser from './ApiSynchroniser';

export default function App() {
  return (
    <Suspense fallback={<Loading />}>
      <NotificationsProvider>
        <AuthProvider>
          <ProfileProvider>
            <MenuProvider>
              <OnlineProvider>
                <UsersProvider>
                  <TemplatesProvider>
                    <InvestigationsProvider>
                      <IncidentsProvider>
                        <LocationsProvider>
                          <ProductsProvider>
                            <PartNumbersProvider>
                              <ReportsProvider>
                                <ThemeProvider theme={theme}>
                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <CssBaseline />
                                    <ApiSynchroniser>
                                      <Paper elevation={0}>
                                        <Router>
                                          <Routes />
                                        </Router>
                                      </Paper>
                                    </ApiSynchroniser>
                                  </MuiPickersUtilsProvider>
                                </ThemeProvider>
                              </ReportsProvider>
                            </PartNumbersProvider>
                          </ProductsProvider>
                        </LocationsProvider>
                      </IncidentsProvider>
                    </InvestigationsProvider>
                  </TemplatesProvider>
                </UsersProvider>
              </OnlineProvider>
            </MenuProvider>
          </ProfileProvider>
        </AuthProvider>
      </NotificationsProvider>
    </Suspense>
  );
}
