import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import RouteWithLayout from './components/RouteWithLayout';
import UnauthenticatedRoute from './components/UnauthenticatedRoute';
import DashboardLayout from './layouts/Dashboard';
import MinimalLayout from './layouts/Minimal';
import AdministrationView from './views/Administration';
import HelpView from './views/Help';
import HomeView from './views/Home';
import LoginView from './views/Login';
import LogoutView from './views/Logout';
import NotFoundView from './views/NotFound';
import ResetPasswordView from './views/ResetPassword';
import ReportIncidentView from './views/ReportIncident';
import IncidentView from './views/Incident';
import EditIncidentView from './views/EditIncident';
import IncidentsView from './views/Incidents';
import InvestigationView from './views/Investigation';
import EditInvestigationView from './views/EditInvestigation';
import IntelligenceView from './views/Intelligence';
import EditIntelligenceView from './views/EditIntelligence';
import NewIntelligenceView from './views/NewIntelligence';
import ProfileView from './views/Profile';
import UserView from './views/User';
import UsersView from './views/Users';

export default function Routes() {
  return (
    <Switch>
      <Redirect exact from="/" to="/home" />
      <AuthenticatedRoute
        component={HomeView}
        exact
        layout={DashboardLayout}
        path="/home"
      />
      <AuthenticatedRoute
        component={ReportIncidentView}
        exact
        layout={DashboardLayout}
        path="/incident/new"
      />
      <AuthenticatedRoute
        component={IncidentView}
        exact
        layout={DashboardLayout}
        path="/incident/:id"
      />
      <AuthenticatedRoute
        component={EditIncidentView}
        exact
        layout={DashboardLayout}
        path="/incident/:id/edit"
      />
      <AuthenticatedRoute
        component={IncidentsView}
        exact
        layout={DashboardLayout}
        path="/incidents"
      />
      <AuthenticatedRoute
        component={InvestigationView}
        exact
        layout={DashboardLayout}
        path="/investigation/:id"
      />
      <AuthenticatedRoute
        component={EditInvestigationView}
        exact
        layout={DashboardLayout}
        path="/investigation/:id/edit"
      />
      <AuthenticatedRoute
        component={IntelligenceView}
        exact
        layout={DashboardLayout}
        path="/intelligence"
      />
      <AuthenticatedRoute
        component={NewIntelligenceView}
        exact
        layout={DashboardLayout}
        path="/intelligence/new"
      />
      <AuthenticatedRoute
        component={EditIntelligenceView}
        exact
        layout={DashboardLayout}
        path="/intelligence/:id/edit"
      />
      <AuthenticatedRoute
        component={UserView}
        exact
        layout={DashboardLayout}
        path="/user/:id"
      />
      <AuthenticatedRoute
        component={UsersView}
        exact
        layout={DashboardLayout}
        path="/users"
      />
      <AuthenticatedRoute
        component={ProfileView}
        exact
        layout={DashboardLayout}
        path="/profile"
      />
      <AuthenticatedRoute
        component={HelpView}
        layout={DashboardLayout}
        path="/help"
      />
      <AuthenticatedRoute
        component={AdministrationView}
        layout={DashboardLayout}
        path="/admin"
      />
      <UnauthenticatedRoute
        component={ResetPasswordView}
        exact
        layout={MinimalLayout}
        path="/reset-password"
      />
      <UnauthenticatedRoute
        component={LoginView}
        exact
        layout={MinimalLayout}
        path="/login"
      />
      <AuthenticatedRoute
        component={LogoutView}
        exact
        layout={MinimalLayout}
        path="/logout"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
}
