import React from 'react';
import { IncidentsStateType, IncidentType } from './store/types';

type ContextType = null | {
  incidents: IncidentsStateType;
  addIncident: (incident: IncidentType) => unknown;
  updateIncident: (incident: IncidentType) => unknown;
  synchroniseIncidents: (incidents: IncidentType[]) => unknown;
};

export const Context = React.createContext<ContextType>(null);

export default Context;
