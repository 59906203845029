import { memo } from 'react';
import { DatePicker, DatePickerProps } from 'formik-material-ui-pickers';

function PastOrPresentDatePicker(props: DatePickerProps) {
  return (
    <DatePicker {...props} disableFuture autoOk />
  );
}

export default memo(PastOrPresentDatePicker);
