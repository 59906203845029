import { memo, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import FilterListIcon from '@material-ui/icons/FilterList';
import ValueFilter from '../ValueFilter';
import Tooltip from '../Tooltip';
import { DecoratedData } from './types';
import FilterListEditIcon from './FilterListEdit';

interface Item {
  id: string;
  label: string;
  selected: boolean;
}

interface Props<T extends Item> {
  id: keyof DecoratedData;
  data: DecoratedData[];
  cell: any;
  onUpdate: (cell: any) => unknown;
}

function getFilter(key: string) {
  switch (key) {
    case 'incidentDate':
      return {
        key,
        type: 'date' as const,
        allowEmpty: true,
        allowAll: true,
        filter: null,
      };
    case 'incidentLocation':
    case 'category':
    case 'subcategory':
    case 'furtherSubcategory':
      return {
        key,
        type: 'object' as const,
        allowEmpty: true,
        filter: null,
      };
    case 'created_at':
    case 'updated_at':
      return {
        key,
        type: 'dateTime' as const,
        allowEmpty: true,
        allowAll: true,
        filter: null,
      };
    case 'description':
      return {
        key,
        type: 'string' as const,
        allowEmpty: true,
        contains: '',
      };
    case 'investigationId':
      return {
        key,
        type: 'status' as const,
        allowEmpty: true,
        allowAll: true,
        filter: null,
      };
    case 'incidentJustified':
      return {
        key,
        type: 'boolean' as const,
        filter: null,
      };
    default:
      return {
        key,
        type: 'category' as const,
        allowEmpty: true,
        allowAll: true,
        filter: null,
      };
    };
}

function isEmptyFilter(filter: ReturnType<typeof getFilter>) {
  switch (filter.type) {
    case 'category':
    case 'date':
    case 'dateTime':
    case 'status':
      return filter.allowEmpty && filter.allowAll && filter.filter === null;
    case 'object':
      return filter.allowEmpty && filter.filter === null;
    case 'string':
      return filter.allowEmpty && filter.contains === '';
    case 'boolean':
      return filter.filter === null;
    default:
      return false;
  }
}

function FilterCell<T extends Item>({ id, data, cell, onUpdate }: Props<T>) {
  const [open, setOpen] = useState(false);
  const [cellFilter, setCellFilter] = useState(() => (cell as any).filter ?? getFilter(id));

  useEffect(() => {
    if (cell.filter) {
      setCellFilter(cell.filter);
    }
  }, [cell.filter]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleSave = () => {
    setOpen(false);
    onUpdate({
      ...cell,
      filter: cellFilter,
    });
  };

  const handleUpdate = (newCell: any) => {
    setCellFilter(newCell);
  };

  return (
    <>
      <Tooltip
        title="Filter Values"
        placement="top-start"
      >
        <IconButton
          onClick={handleClickOpen}
          color="inherit"
        >
          {
            isEmptyFilter(cellFilter) ? (
              <FilterListIcon />
            ) : (
              <FilterListEditIcon />
            )
          }
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleCancel}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        disableBackdropClick
      >
        <DialogTitle id="scroll-dialog-title">Filter Values</DialogTitle>
        <DialogContent dividers={true}>
          <ValueFilter
            filter={cellFilter}
            rows={data as any}
            onUpdateFilter={handleUpdate}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default memo(FilterCell);
