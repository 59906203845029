import { useState } from 'react';
import { v1 as uuid } from 'uuid';
import { Redirect } from 'react-router-dom';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import { useNotifications } from '../../providers/Notifications';
import { useAuth } from '../../providers/Auth';
import { useIncidents } from '../../providers/Incidents';
import { IncidentType } from '../../providers/Incidents/store/types';
import { useInvestigations } from '../../providers/Investigations';
import { InvestigationType } from '../../providers/Investigations/store/types';
import * as ApiClient from '../../services/ApiClient';
import Loading from '../Loading';
import AcknowledgeDialog from './AcknowledgeDialog';
import RejectDialog from './RejectDialog';
import RequestChangesDialog from './RequestChangesDialog';
import CloseDialog from './CloseDialog';
import AssignInvestigationDialog from './AssignInvestigationDialog';
import StartInvestigationDialog from './StartInvestigationDialog';

const newInvestigationMessage = 'Start a new investigation based on this incident';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 0,
      flexGrow: 1,
    },
    speedDial: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    tooltip: {
      fontSize: theme.typography.pxToRem(15),
    },
  }),
);

const useTooltipStyles = makeStyles((theme: Theme) =>
  createStyles({
    tooltip: {
      fontSize: theme.typography.pxToRem(15),
    },
  }),
);

function getUpdateToastMessage(status: string) {
  switch (status) {
    case 'rejected':
      return 'The incident has been rejected successfully.';
    case 'acknowledged':
      return 'The incident has been acknowledged successfully.';
    case 'pending-update':
      return 'Requested more information successfully.';
    case 'closed':
      return 'The incident has been closed successfully.';
    default:
      return 'Updated incident successfully.';
  }
}

function statusValue(
  incidentStatus: IncidentType['status'],
  investigationId: IncidentType['investigationId'],
  investigations: InvestigationType[],
) {
  switch (incidentStatus) {
    case 'acknowledged': {
      const newStatus = investigationId ? 'under-investigation' : 'acknowledged';
      if (newStatus === 'under-investigation') {
        const investigation = investigations.find(investigation => investigation.id === investigationId);
        if (investigation?.status === 'completed') {
          return 'investigation-completed';
        }
      }
      return newStatus;
    }
    case 'under-investigation':
    case 'investigation-completed': {
      const investigation = investigations.find(investigation => investigation.id === investigationId);
      if (investigation?.status === 'completed') {
        return 'investigation-completed';
      } else {
        return 'under-investigation';
      }
    }
    default:
      return incidentStatus;
  }
}
interface Props {
  incident: IncidentType;
}

export default function IncidentSpeedDial({ incident }: Props) {
  const classes = useStyles();
  const tooltipClasses = useTooltipStyles();
  const [open, setOpen] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [editInvestigation, setEditInvestigation] = useState(false);
  const [showAcknowledge, setShowAcknowledge] = useState(false);
  const [showReject, setShowReject] = useState(false);
  const [showRequestChanges, setShowRequestChanges] = useState(false);
  const [showClose, setShowClose] = useState(false);
  const [showStart, setShowStart] = useState(false);
  const [showAssign, setShowAssign] = useState(false);
  const [loading, setLoading] = useState(false);  
  const showAlert = useNotifications();
  const { auth } = useAuth();
  const { updateIncident } = useIncidents();
  const { investigations, addInvestigation } = useInvestigations();

  if (!auth.isAuthenticated) {
    return null;
  }

  if (redirect) {
    return <Redirect to={`/incident/${incident.id}/edit`} />;
  }

  if (editInvestigation && incident.investigationId) {
    return <Redirect to={`/investigation/${incident.investigationId}/edit`} />;
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const submitForm = async (newStatus: string, options: any = {}) => {
    try {
      const result = await ApiClient.updateIncident({
        ...incident,
        ...options,
        status: newStatus,
      });

      showAlert({
        message: getUpdateToastMessage(newStatus),
        severity: 'success',
      });

      updateIncident(result);
    } catch (error) {
      showAlert({
        message: 'Failed to update incident.',
        severity: 'error',
      });
    }
  }

  const assignInvestigation = async (newInvestigationId: string | null) => {
    try {
      const investigationId = uuid();

      const newIncident = {
        ...incident,
        investigationId,
      };

      const newStatus = statusValue(
        newIncident.status,
        newInvestigationId === newInvestigationMessage ? investigationId : newInvestigationId,
        investigations.investigations,
      );

      const existingInvestigation = newInvestigationId === newInvestigationMessage
        ? null
        : investigations.investigations.find(investigation => investigation.id === newInvestigationId);

      const investigation = existingInvestigation ? {
        ...existingInvestigation,
        incidentDescriptionCopied: true,
        containmentActionCopied: true,
        rootCauseCopied: true,
        rootCauseCategoryCopied: true,
        correctiveActionCopied: true,
        verificationOfEffectivenessCopied: true,
        preventiveActionCopied: true,
        defectiveProductQuantityCopied: true,
        additionalNotesCopied: true,
        costOfLabourCopied: true,
        costOfMaterialCopied: true,
        costAdjustmentCopied: true,
        id: investigationId,
        containmentActionSignedBy: null,
        containmentActionSignedDate: null,
        rootCauseSignedBy: null,
        rootCauseSignedDate: null,
        correctiveActionSignedBy: null,
        correctiveActionSignedDate: null,
        verificationOfEffectivenessSignedBy: null,
        verificationOfEffectivenessSignedDate: null,
        preventiveActionSignedBy: null,
        preventiveActionSignedDate: null,
        additionalNotesSignedBy: null,
        additionalNotesSignedDate: null,
        completed: false,
        completedSignedBy: null,
        completedSignedDate: null,
        status: 'opened',
      } : {
        id: investigationId,
        assignedTo: auth.username,
        incidentDescription: incident.description,
        incidentJustified: null,
        containmentAction: '',
        containmentActionSignedBy: null,
        containmentActionSignedDate: null,
        rootCause: '',
        rootCauseCategory: null,
        rootCauseSignedBy: null,
        rootCauseSignedDate: null,
        correctiveAction: '',
        correctiveActionSignedBy: null,
        correctiveActionSignedDate: null,
        verificationOfEffectiveness: '',
        verificationOfEffectivenessSignedBy: null,
        verificationOfEffectivenessSignedDate: null,
        preventiveAction: '',
        preventiveActionSignedBy: null,
        preventiveActionSignedDate: null,
        defectiveProductQuantity: '0',
        additionalNotes: '',
        additionalNotesSignedBy: null,
        additionalNotesSignedDate: null,  
        costOfLabour: null,
        costOfMaterial: null,
        costAdjustment: null,
        completed: false,
        completedSignedBy: null,
        completedSignedDate: null,
        attachments: [] as {
          id: string;
          name: string;
          type: string;
          size: number;
          url: string;
        }[],
        status: 'opened',
      };

      const investigationResult = await ApiClient.createInvestigation(investigation);

      addInvestigation(investigationResult);

      const result = await ApiClient.updateIncident({
        ...newIncident,
        status: newStatus,
      });

      showAlert({
        message: 'Updated investigation assigned to the incident.',
        severity: 'success',
      });

      updateIncident(result);
    } catch (error) {
      showAlert({
        message: 'Failed to assign investigation to the incident.',
        severity: 'error',
      });
    }
  }

  return (
    <div className={classes.root}>
      <SpeedDial
        ariaLabel="Incident actions"
        className={classes.speedDial}
        icon={<MoreVertIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        direction="up"
      >
        <SpeedDialAction
          TooltipClasses={tooltipClasses}
          icon={<EditIcon />}
          tooltipTitle="Edit"
          onClick={() => setRedirect(true)}
        />
        {
          (incident.status === 'under-investigation' || incident.status === 'investigation-completed') && (
            <SpeedDialAction
              TooltipClasses={tooltipClasses}
              icon={<AssignmentIndIcon />}
              tooltipTitle="Reassign investigation"
              onClick={() => setShowAssign(true)}
            />
          )
        }
        {
          (incident.status === 'under-investigation' || incident.status === 'investigation-completed') && (
            <SpeedDialAction
              TooltipClasses={tooltipClasses}
              icon={<AssignmentIcon />}
              tooltipTitle="Edit investigation"
              onClick={() => setEditInvestigation(true)}
            />
          )
        }
        {
          incident.status === 'acknowledged' && (
            <SpeedDialAction
              TooltipClasses={tooltipClasses}
              icon={<AssignmentIndIcon />}
              tooltipTitle="Start investigation"
              onClick={() => setShowStart(true)}
            />
          )
        }
        {
          incident.status === 'submitted' && (
            <SpeedDialAction
              TooltipClasses={tooltipClasses}
              icon={<ClearIcon />}
              tooltipTitle="Reject"
              onClick={() => setShowReject(true)}
            />
          )
        }
        {
          incident.status === 'submitted' && (
            <SpeedDialAction
              TooltipClasses={tooltipClasses}
              icon={<CheckIcon />}
              tooltipTitle="Acknowledge"
              onClick={() => setShowAcknowledge(true)}
            />
          )
        }
        {
          incident.status === 'submitted' && (
            <SpeedDialAction
              TooltipClasses={tooltipClasses}
              icon={<AddIcon />}
              tooltipTitle="Request more information"
              onClick={() => setShowRequestChanges(true)}
            />
          )
        }
        {
          incident.status === 'investigation-completed' && (
            <SpeedDialAction
              TooltipClasses={tooltipClasses}
              icon={<AssignmentTurnedInIcon />}
              tooltipTitle="Close Incident"
              onClick={() => setShowClose(true)}
            />
          )
        }
      </SpeedDial>
      {
        loading && (
          <Loading fullScreen />
        )
      }
      <RejectDialog
        open={showReject}
        onCancel={() => setShowReject(false)}
        onConfirm={async (rejectReason) => {
          setShowReject(false)
          setLoading(true);
          await submitForm('rejected', { rejectReason });
          setLoading(false);
        }}
      />
      <AcknowledgeDialog
        open={showAcknowledge}
        onCancel={() => setShowAcknowledge(false)}
        onConfirm={async () => {
          setShowAcknowledge(false)
          setLoading(true);
          await submitForm('acknowledged');
          setLoading(false);
        }}
      />
      <RequestChangesDialog
        open={showRequestChanges}
        onCancel={() => setShowRequestChanges(false)}
        onConfirm={async (pendingUpdateReason) => {
          setShowRequestChanges(false)
          setLoading(true);
          await submitForm('pending-update', { pendingUpdateReason });
          setLoading(false);
        }}
      />
      <CloseDialog
        open={showClose}
        onCancel={() => setShowClose(false)}
        onConfirm={async () => {
          setShowClose(false)
          setLoading(true);
          await submitForm('closed');
          setLoading(false);
        }}
      />
      <StartInvestigationDialog
        open={showStart}
        incident={incident}
        onCancel={() => setShowStart(false)}
        onConfirm={async (investigationId: string) => {
          setShowStart(false)
          setLoading(true);
          await assignInvestigation(investigationId);
          setLoading(false);
        }}
      />
      <AssignInvestigationDialog
        open={showAssign}
        incident={incident}
        onCancel={() => setShowAssign(false)}
        onConfirm={async (investigationId: string) => {
          setShowAssign(false)
          setLoading(true);
          await assignInvestigation(investigationId);
          setLoading(false);
        }}
      />
    </div>
  );
}
