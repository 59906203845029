import { useMemo, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { IncidentType } from '../../providers/Incidents/store/types';
import { useIncidents } from '../../providers/Incidents';

const newInvestigationMessage = 'Start a new investigation based on this incident';

export interface Props {
  incident: IncidentType;
  open: boolean;
  onConfirm: (incidentId: string) => unknown;
  onCancel: () => unknown;
}

export default function AssignInvestigationDialog({
  incident,
  open,
  onCancel,
  onConfirm,
}: Props) {
  const [investigation, setInvestigation] = useState(incident.investigationId ? {
    investigationId: incident.investigationId,
    investigationIncident: incident.incidentNumber!,
  } : null);
  const { incidents } = useIncidents();

  const investigationOptions = useMemo(() => [
    {
      investigationId: newInvestigationMessage,
      investigationIncident: newInvestigationMessage,
    },
    ...(incidents.incidents
      .filter(incident => incident.investigationId && incident.incidentNumber)
      .map(incident => ({
        investigationId: incident.investigationId!,
        investigationIncident: incident.incidentNumber!,
      }))),
  ], [incidents.incidents]);

  const handleInvestigationChange = (_: object, value: any) => {
    if (value) {
      setInvestigation(value);
    }
  };

  return (
    <Dialog onClose={onCancel} open={open}>
      <DialogTitle id="simple-dialog-title">
        Reassign Investigation
      </DialogTitle>
      <DialogContent>
        <Autocomplete
          options={investigationOptions}
          getOptionLabel={(option: { investigationId: string; investigationIncident: string }) => option.investigationIncident}
          getOptionSelected={(option: { investigationId: string; investigationIncident: string }) => option.investigationId === investigation?.investigationId}
          value={investigation as any}
          onChange={handleInvestigationChange}
          renderInput={props => <TextField {...props} variant="outlined" />}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Cancel
        </Button>
        <Button
          disabled={!investigation}
          onClick={
            () => {
              onConfirm(investigation!.investigationId);
            }
          }
          color="primary"
        >
          Reassign investigation
        </Button>
      </DialogActions>
    </Dialog>
  );
}
