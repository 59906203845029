import React from 'react';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Link, Menu, MenuItem, Toolbar } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Image from '../../../../components/Image';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    margin: '0 auto',
    flexGrow: 1,
    justifyContent: 'space-between',
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 8),
    },
  },
  logoContainer: {
    width: 200,
    height: 28,
    [theme.breakpoints.up('md')]: {
      width: 240,
      height: 32,
    },
  },
  logoImage: {
    width: '100%',
    height: '100%',
  },
}));

interface Props {
  className?: string;
};

export default function Topbar({ className }: Props) {
  const classes = useStyles();
  const [anchorElement, setAnchorElement] = React.useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  return (
    <Toolbar className={clsx(classes.root, className)}>
      <div className={classes.logoContainer}>
        <Link component={RouterLink} to="/" title="Incident Reporting">
          <Image
            className={classes.logoImage}
            src="/assets/images/logos/logo.svg"
            alt="Incident Reporting"
            lazy={false}
          />
        </Link>
      </div>
      <div>
        <IconButton
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <Menu
          anchorEl={anchorElement}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={!!anchorElement}
          onClose={handleClose}
        >
          <MenuItem component={RouterLink} to="/profile">My account</MenuItem>
          <MenuItem component={RouterLink} to="/logout">Logout</MenuItem>
        </Menu>
      </div>
    </Toolbar>
  );
}
