import { forwardRef, memo } from 'react';
import {
  PieChart as RawPieChart,
  Pie,
  Cell,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import hexColor from 'number-to-color/hexMap';

const RADIAN = Math.PI / 180;

interface LabelProps {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  value: number;
}

function CustomLabel({ cx, cy, midAngle, innerRadius, outerRadius, value }: LabelProps) {
  const radius = 15 + innerRadius + (outerRadius - innerRadius);
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="#000000"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {value}
    </text>
  );
}

function CustomLegendText(value: string) {  
  return <span style={{ color: '#000000' }}>{value}</span>;
}

interface Props {
  data: {
    title: string;
    xLabel: string;
    yLabel1: string;
    values: {
      name: string;
      value1: number;
    }[];
  };
}

export default memo(forwardRef(({ data }: Props, ref: any) => (
  <ResponsiveContainer width="99%" aspect={2}>
    <RawPieChart ref={ref}>
      <Legend
        formatter={CustomLegendText}
        layout="vertical"
        verticalAlign="middle"
        align="right"
      />
      <Pie
        data={data.values}
        cx="50%"
        cy="50%"
        labelLine={false}
        label={CustomLabel}
        fill="#000000"
        dataKey="value1"
      >
        {
          data.values.map((_, index) => (
            <Cell key={`cell-${index}`} fill={hexColor(index / data.values.length)} />
          ))
        }
      </Pie>
    </RawPieChart>
  </ResponsiveContainer>
)));
