import { Redirect, RouteComponentProps } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import DashboardBreadcrums from '../../components/DashboardBreadcrums';
import Section from '../../components/Section';
import SectionHeader from '../../components/SectionHeader';
import useCurrentUser from '../../hooks/useCurrentUser';
import { useIncidents } from '../../providers/Incidents';
import { useLocations } from '../../providers/Locations';
import { patchIncident} from '../../utils/incidents';
import { EditDraftIncidentForm, EditIncidentForm } from './components';

const useStyles = makeStyles(theme => {
  return ({
    pagePaddingTop: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      [theme.breakpoints.up('md')]: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
      },
    },
    sectionNoPaddingTop: {
      paddingTop: 0,
    },
  });
});

export default function EditIncident({ match }: RouteComponentProps<{ id: string }>) {
  const classes = useStyles();
  const { incidents } = useIncidents();
  const currentUser = useCurrentUser();
  const { locations } = useLocations();
 
  const incidentId = match.params.id;
  const incident = incidents.incidents.find(incident => incident.id === incidentId);

  if (!incident || !currentUser) {
    return <Redirect to="/not-found" />;
  } else if (incident.status === 'cancelled') {
    // Do not edit a cancelled incident.
    return <Redirect to="/incidents" />;
  } else if (incident.status !== 'draft' && incident.status !== 'pending-update' && currentUser?.role === 'user') {
    // Prevent users from editing non-draft incidents;
    return <Redirect to={`/incident/${incident.id}`} />;
  }

  const patchedIncident = patchIncident(locations, incident);

  return (
    <div>
      <Section className={classes.pagePaddingTop}>
        <DashboardBreadcrums
          breadcrums={[
            { name: 'Home', path: '/' },
            { name: 'Incidents', path: '/incidents' },
            { name: `${incident.incidentNumber ? incident.incidentNumber : 'Incident Details'}`, path: `/incident/${incident.id}` },
            { name: 'Update Incident', path: `/incident/${incident.id}/edit` },
          ]}
        />
      </Section>
      <Section className={classes.sectionNoPaddingTop}>
        <div>
          {
            (patchedIncident.status === 'draft' || patchedIncident.status === 'pending-update') ? (
              <>
                <SectionHeader
                  title="Report Incident"
                  subtitle="Please make sure to fill all mandatory fields marked by *."
                  subtitleProps={{
                    variant: 'body1',
                    color: 'textPrimary',
                  }}
                  align="left"
                />
                <EditDraftIncidentForm
                  incident={patchedIncident}
                  showDeleteButton={patchedIncident.status === 'draft'}
                />
              </>
            ) : (
              <>
                <SectionHeader
                  title="Update Incident"
                  subtitle="Please make sure to fill all mandatory fields marked by *."
                  subtitleProps={{
                    variant: 'body1',
                    color: 'textPrimary',
                  }}
                  align="left"
                />
                <EditIncidentForm incident={patchedIncident} />
              </>
            )
          }
        </div>
      </Section>
    </div>
  );
}
