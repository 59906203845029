import { PropsWithChildren, useState, useEffect, useReducer } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Context from './Context';
import * as actions from './store/actions';
import reducer from './store/reducer';

export default function Provider({
  children,
  persistKey = 'menu',
}: PropsWithChildren<{ persistKey?: string }>) {
  const [menu, dispatch] = useReducer(reducer, {
    miniMode: false,
    menuOpen: true,
    mobileMenuOpen: true,
    miniSwitchVisibility: true,
  });
  
  const props = {
    ...menu,
    setMiniMode: (payload: boolean) => dispatch(actions.setMiniMode(payload)),
    setMenuOpen: (payload: boolean) => dispatch(actions.setMenuOpen(payload)),
    setMobileMenuOpen: (payload: boolean) => dispatch(actions.setMobileMenuOpen(payload)),
    setMiniSwitchVisibility: (payload: boolean) => dispatch(actions.setMiniSwitchVisibility(payload)),
  };

  const [isAuthMenuOpen, setAuthMenuOpen] = useState(false);
  const isDesktop = useMediaQuery('(min-width:600px)');

  useEffect(() => {
    try {
      localStorage.setItem(persistKey, JSON.stringify(menu));
    } catch (error) {
      console.warn(error);
    }
  }, [menu, persistKey]);

  return (
    <Context.Provider
      value={{
        isDesktop,
        isAuthMenuOpen,
        setAuthMenuOpen,
        ...props,
      }}
    >
      {children}
    </Context.Provider>
  );
}
