import { useContext } from 'react';
import Context from './Context';
export { default } from './Provider';

export function useProducts() {
  const products = useContext(Context);

  if (!products) {
    throw new Error('Failed to find products context.');
  }

  return products;
}
