import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chipOpened: {
      backgroundColor: theme.palette.info.light,
      color: theme.palette.info.contrastText,
    },
    chipCompleted: {
      backgroundColor: theme.palette.success.light,
      color: theme.palette.success.contrastText,
    },
  }),
);

interface Props {
  value: string;
}

export default function InvestigationStatus({ value }: Props) {
  const classes = useStyles();

  switch (value) {
    case 'opened':
      return <Chip label="Opened" className={classes.chipOpened} />;
    case 'completed':
      return <Chip label="Completed" className={classes.chipCompleted} />;
  }

  return null;
}
