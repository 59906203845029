import { MouseEvent, useState } from 'react';
import { CsvBuilder } from 'filefy';
import { saveAs } from 'file-saver';
import Excel from 'exceljs/dist/es5/exceljs.browser';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { useUsers } from '../../providers/Users';
import { useInvestigations } from '../../providers/Investigations';
import type { InvestigationType } from '../../providers/Investigations/store/types';
import { usePartNumbers } from '../../providers/PartNumbers';
import { formatDate, formatDateTimeAsDate } from '../../utils/incidents';
import Tooltip from '../Tooltip';

interface Data {
  id: string;
  incidentNumber: string | null;
  reportedBy: string;
  incidentDate: string;
  product: string | null;
  partNumber: string | null;
  defectiveQuantity: string | null;
  grnNumber: string;
  orderNumber: string;
  incidentLocation: { id: string; title: string; } | null;
  category: { id: string; title: string; } | null;
  subcategory: { id: string; title: string; } | null;
  furtherSubcategory: { id: string; title: string; } | null;
  description: string;
  investigationId: string | null;
  status: string;
  created_at: string;
  updated_at: string;
}

interface Filter {
  id: keyof Data | 'partNumberDescription' | 'assignedTo' | 'incidentDescription' | 'incidentJustified' | 'containmentAction' | 'containmentActionStatus' | 'containmentActionSignedBy' | 'containmentActionSignedDate' | 'rootCause' | 'rootCauseCategory' | 'rootCauseStatus' | 'rootCauseSignedBy' | 'rootCauseSignedDate' | 'correctiveAction' | 'correctiveActionStatus' | 'correctiveActionSignedBy' | 'correctiveActionSignedDate' | 'verificationOfEffectiveness' | 'verificationOfEffectivenessStatus' | 'verificationOfEffectivenessSignedBy' | 'verificationOfEffectivenessSignedDate' | 'preventiveAction' | 'preventiveActionStatus' | 'preventiveActionSignedBy' | 'preventiveActionSignedDate' | 'costOfLabour' | 'costOfMaterial' | 'costAdjustment' | 'costTotal' | 'completedStatus' | 'completedSignedBy' | 'completedSignedDate';
}

function getExportData(
  rows: Data[],
  filter: Filter[],
  userMap: Map<string, { name: string }>,
  partNumberDescriptionMap: Map<string, string>,
  investigationMap: Map<string, InvestigationType>,
) {
  return rows
    .filter(row => row.status !== 'cancelled')
    .map(row => filter.map(value => {
      switch (value.id) {
        case 'reportedBy':
          return (row[value.id] && userMap.get(row[value.id])?.name) || '';
        case 'incidentNumber':
        case 'product':
        case 'partNumber':
        case 'defectiveQuantity':
        case 'investigationId':
          return row[value.id] || '';
        case 'partNumberDescription':
          return (row.partNumber && partNumberDescriptionMap.get(row.partNumber)) || '';
        case 'incidentLocation':
        case 'category':
        case 'subcategory':
        case 'furtherSubcategory':
          return row[value.id]?.title || '';
        case 'assignedTo':
        case 'containmentActionSignedBy': 
        case 'rootCauseSignedBy':
        case 'correctiveActionSignedBy':
        case 'verificationOfEffectivenessSignedBy':
        case 'preventiveActionSignedBy':
        case 'completedSignedBy': {
          if (!row.investigationId) {
            return '';
          }
          const investigation = investigationMap.get(row.investigationId);
          if (!investigation) {
            return '';
          }
          const userId = investigation[value.id];
          return (userId && userMap.get(userId)?.name) || '';
        }
        case 'incidentDescription':
        case 'containmentAction': 
        case 'rootCause':
        case 'correctiveAction':
        case 'verificationOfEffectiveness':
        case 'preventiveAction': {
            if (!row.investigationId) {
            return '';
          }
          const investigation = investigationMap.get(row.investigationId);
          if (!investigation) {
            return '';
          }
          return investigation[value.id] || '';
        }
        case 'containmentActionStatus': 
        case 'rootCauseStatus':
        case 'correctiveActionStatus':
        case 'verificationOfEffectivenessStatus':
        case 'preventiveActionStatus':
        case 'completedStatus': {
            if (!row.investigationId) {
            return '';
          }
          const investigation = investigationMap.get(row.investigationId);
          if (!investigation) {
            return '';
          }
          const key = `${value.id.substring(0, value.id.length - 6)}SignedBy`;
          return investigation[key as keyof typeof investigation] ? 'Completed' : 'Open';
        }
        case 'containmentActionSignedDate': 
        case 'rootCauseSignedDate':
        case 'correctiveActionSignedDate':
        case 'verificationOfEffectivenessSignedDate':
        case 'preventiveActionSignedDate':
        case 'completedSignedDate': {
          if (!row.investigationId) {
            return '';
          }
          const investigation = investigationMap.get(row.investigationId);
          if (!investigation) {
            return '';
          }
          return (investigation[value.id] && formatDate(investigation[value.id])) || '';
        }
        case 'created_at': {
          return (row[value.id] && formatDateTimeAsDate(row[value.id])) || '';
        }
        case 'incidentJustified': {
          if (!row.investigationId) {
            return 'Yes';
          }
          const investigation = investigationMap.get(row.investigationId);
          if (!investigation) {
            return 'Yes';
          }
          if (investigation.incidentJustified === false) {
            return 'No';
          }
          return 'Yes';
        }
        case 'rootCauseCategory': {
          if (!row.investigationId) {
            return '';
          }
          const investigation = investigationMap.get(row.investigationId);
          if (!investigation) {
            return '';
          }
          return investigation[value.id]?.title || '';
        }  
        case 'costOfLabour':
        case 'costOfMaterial':
        case 'costAdjustment': {
          if (!row.investigationId) {
            return '';
          }
          const investigation = investigationMap.get(row.investigationId);
          if (!investigation) {
            return '';
          }

          return typeof investigation[value.id] === 'number'
            ? investigation[value.id]
            : '';
        }
        case 'costTotal': {
          if (!row.investigationId) {
            return '';
          }
          const investigation = investigationMap.get(row.investigationId);
          if (!investigation) {
            return '';
          }

          return (+(investigation.costOfLabour ?? 0) + (investigation.costOfMaterial ?? 0) + (investigation.costAdjustment ?? 0));
        }
        default:
          return row[value.id];
      }
    }));
}

function exportCsv(
  filename: string,
  rows: Data[],
  filter: Filter[],
  userMap: Map<string, { name: string }>,
  partNumberDescriptionMap: Map<string, string>,
  investigationMap: Map<string, InvestigationType>,
) {
  const builder = new CsvBuilder(`${filename}.csv`);

  builder
    .setDelimeter(',')
    .setColumns(filter.map(head => head.id))
    .addRows(getExportData(rows, filter, userMap, partNumberDescriptionMap, investigationMap))
    .exportFile();
}

export const filter = [
  { id: 'incidentNumber' },
  { id: 'incidentDate' },
  { id: 'reportedBy' },
  { id: 'product' },
  { id: 'partNumber' },
  { id: 'partNumberDescription' },
  { id: 'grnNumber' },
  { id: 'orderNumber' },
  { id: 'defectiveQuantity' },
  { id: 'incidentLocation' },
  { id: 'category' },
  { id: 'subcategory' },
  { id: 'furtherSubcategory' },
  { id: 'description' },
  { id: 'assignedTo' },
  { id: 'incidentDescription' },
  { id: 'incidentJustified' },
  { id: 'containmentAction' },
  { id: 'containmentActionStatus' },
  { id: 'containmentActionSignedBy' },
  { id: 'containmentActionSignedDate' },
  { id: 'rootCause' },
  { id: 'rootCauseCategory' },
  { id: 'rootCauseStatus' },
  { id: 'rootCauseSignedBy' },
  { id: 'rootCauseSignedDate' },
  { id: 'correctiveAction' },
  { id: 'correctiveActionStatus' },
  { id: 'correctiveActionSignedBy' },
  { id: 'correctiveActionSignedDate' },
  { id: 'verificationOfEffectiveness' },
  { id: 'verificationOfEffectivenessStatus' },
  { id: 'verificationOfEffectivenessSignedBy' },
  { id: 'verificationOfEffectivenessSignedDate' },
  { id: 'preventiveAction' },
  { id: 'preventiveActionStatus' },
  { id: 'preventiveActionSignedBy' },
  { id: 'preventiveActionSignedDate' },
  { id: 'costOfLabour' },
  { id: 'costOfMaterial' },
  { id: 'costAdjustment' },
  { id: 'costTotal' },
  { id: 'completedStatus' },
  { id: 'completedSignedBy' },
  { id: 'completedSignedDate' },
  { id: 'status' },
  { id: 'created_at' },
] as Filter[];

export const labels = [
  'Incident ID Number',
  'Incident Date',
  'Reported by',
  'Product',
  'Part Number',
  'Description',
  'GRN Number',
  'Order Number',
  'Defective Quantity',
  'Location of the Incident',
  'Category',
  'Sub Category Level 1',
  'Sub Category Level 2',
  'Description of the incident',
  'Investigation Team Leader',
  'Incident Description',
  'Is justified',
  'Containment Action',
  'Containment Action Status',
  'Completed by',
  'Completion Date',
  'Root Cause',
  'Root Cause Category',
  'Root Cause Status',
  'Completed by',
  'Completion Date',
  'Corrective Action',
  'Corrective Action Status',
  'Completed by',
  'Completion Date',
  'Verification of Effectiveness',
  'Verification of Effectiveness Status',
  'Completed by',
  'Completion Date',
  'Preventive Action',
  'Preventive Action Status',
  'Completed by',
  'Completion Date',
  'Cost of Labour',
  'Cost of Material',
  'Cost Adjustment',
  'Total Cost',
  'Investigation Status',
  'Completed by',
  'Completion Date',
  'Incident Status',
  'Created At',
];

async function exportXslx(
  filename: string,
  rows: Data[],
  userMap: Map<string, { name: string }>,
  partNumberDescriptionMap: Map<string, string>,
  investigationMap: Map<string, InvestigationType>,
) {
  const workbook = new Excel.Workbook()
  workbook.creator = `${window.location.hostname}`;
  workbook.lastModifiedBy = `${window.location.hostname}`;
  workbook.created = new Date();
  workbook.modified = new Date();

  const worksheet = workbook.addWorksheet('Incidents');

  worksheet.columns = [
    { key: 'incidentId', width: 10.6 },
    { key: 'incidentDate', width: 15.5 },
    { key: 'reportedBy', width: 19.6 },
    { key: 'product', width: 13.9 },
    { key: 'partNumber', width: 14.6 },
    { key: 'partDescription', width: 31.5 },
    { key: 'grnNumber', width: 14.6 },
    { key: 'orderNumber', width: 14.6 },
    { key: 'defectiveQuantity', width: 11.6 },
    { key: 'incidentLocation', width: 23.2 },
    { key: 'category', width: 27.6 },
    { key: 'subcategory', width: 27.2 },
    { key: 'furtherSubcategory', width: 23.5 },
    { key: 'description', width: 60.7 },
    { key: 'assignedTo', width: 19.6 },
    { key: 'incidentDescription', width: 60.7 },
    { key: 'incidentJustified', width: 10.9 },
    { key: 'containmentAction', width: 60.7 },
    { key: 'containmentActionStatus', width: 10.9 },
    { key: 'containmentActionSignedBy', width: 19.6 },
    { key: 'containmentActionSignedDate', width: 15.5 },
    { key: 'rootCause', width: 60.7 },
    { key: 'rootCauseCategory', width: 31.5 },
    { key: 'rootCauseStatus', width: 10.9 },
    { key: 'rootCauseSignedBy', width: 19.6 },
    { key: 'rootCauseSignedDate', width: 15.5 },
    { key: 'correctiveAction', width: 60.7 },
    { key: 'correctiveActionStatus', width: 10.9 },
    { key: 'correctiveActionSignedBy', width: 19.6 },
    { key: 'correctiveActionSignedDate', width: 15.5 },
    { key: 'verificationOfEffectiveness', width: 60.7 },
    { key: 'verificationOfEffectivenessStatus', width: 10.9 },
    { key: 'verificationOfEffectivenessSignedBy', width: 19.6 },
    { key: 'verificationOfEffectivenessSignedDate', width: 15.5 },
    { key: 'preventiveAction', width: 60.7 },
    { key: 'preventiveActionStatus', width: 10.9 },
    { key: 'preventiveActionSignedBy', width: 19.6 },
    { key: 'preventiveActionSignedDate', width: 15.5 },
    { key: 'costOfLabour', width: 15.5 },
    { key: 'costOfMaterial', width: 15.5 },
    { key: 'costAdjustment', width: 15.5 },
    { key: 'costTotal', width: 15.5 },
    { key: 'completedStatus', width: 10.9 },
    { key: 'completedSignedBy', width: 19.6 },
    { key: 'completedSignedDate', width: 15.5 },
    { key: 'status', width: 10.9 },
    { key: 'createdAt', width: 19.6 },
  ];

  const subHeaderRow = worksheet.addRow(labels);
  subHeaderRow.height = 56;

  const darkBlue = '2e75b5';
  const lightBlue = 'b4c6e7';
  const darkBlueFont = '1f3864';
  const lightBlueFont = 'd9e2f3';

  const darkOrange = 'c55a11';
  const lightOrange = 'e7c9b4';
  const darkOrangeFont = '643c1f';
  const lightOrangeFont = 'f3e4d9';

  subHeaderRow.eachCell((cell: any, column: any) => {
    if (!((column >= 5 && column <= 9) || (column >= 15 && column <= 45))) {
      cell.alignment = {
        vertical: 'top',
        horizontal: 'center',
        wrapText: true,
      };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: column >= 15 && column <= 45 ? darkOrange : darkBlue }
      };
      cell.font = {
        color: { argb: column >= 15 && column <= 45 ? lightOrangeFont : lightBlueFont },
        size: 11,
        bold: true,
      };
      return;
    }
    cell.alignment = {
      vertical: 'top',
      horizontal: 'center',
      wrapText: true,
    };
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: column >= 15 ? lightOrange : lightBlue }
    };
    cell.font = {
      color: { argb: column >= 15 ? darkOrangeFont : darkBlueFont },
      size: 11,
      bold: true,
    };
  });

  const data = getExportData(rows, filter, userMap, partNumberDescriptionMap, investigationMap);

  data.forEach(dataRow => {
    const row = worksheet.addRow(dataRow);
    
    row.eachCell((cell: any, column: any) => {
      cell.alignment = {
        vertical: 'top',
        horizontal: column === 9 || (column >= 39 && column <= 43) ? 'right' : 'left',
        wrapText: true,
      };
      cell.font = {
        size: 11,
        bold: column === 1,
      };
      if (column >= 39 && column <= 43) {
        cell.numFmt = '£#,##0.00;[Red]-£#,##0.00';
      }
    });
  });

  const xlsx = await workbook.xlsx.writeBuffer();
  saveAs(new Blob([xlsx], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), `${filename}.xlsx`);
}

interface Props {
  data: Data[];
  filter: Filter[];
}

export default function ExportMenu({ data, filter }: Props) {
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const { userMap } = useUsers();
  const { partNumberDescriptionMap } = usePartNumbers();
  const { investigationMap } = useInvestigations();

  const handleMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  const handleExportCsv = () => {
    exportCsv(
      `IncidentLog${new Date().toISOString().substring(0,10).replaceAll('-', '')}`,
      data,
      filter,
      userMap,
      partNumberDescriptionMap,
      investigationMap,
    );
    handleClose();
  };

  const handleExportXslx = () => {
    exportXslx(
      `IncidentLog${new Date().toISOString().substring(0,10).replaceAll('-', '')}`,
      data,
      userMap,
      partNumberDescriptionMap,
      investigationMap,
    );
    handleClose();
  };

  return (
    <div>
      <Tooltip
        title="Export options"
        placement="top-start"
      >
        <IconButton
          aria-label="export"
          aria-controls="menu-table-export"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
        >
          <SaveAltIcon />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorElement}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={!!anchorElement}
        onClose={handleClose}
      >
        <MenuItem onClick={handleExportCsv}>Export as CSV</MenuItem>
        <MenuItem onClick={handleExportXslx}>Export as XSLX</MenuItem>
      </Menu>
    </div>
  );
}
