import { PropsWithChildren, useEffect, useMemo, useReducer } from 'react';
import Context from './Context';
import * as actions from './store/actions';
import reducer from './store/reducer';
import { UserType } from './store/types';
import { useAuth } from '../Auth';

const initialState = {
  isLoading: true,
  users: [] as UserType[],
};

export default function Provider({
  persistKey = 'users',
  children,
}: PropsWithChildren<{ persistKey?: string }>) {
  const { auth } = useAuth();

  const data = localStorage.getItem(persistKey);
  const json = data && JSON.parse(data);

  const [users, dispatch] = useReducer(reducer, json || initialState);

  useEffect(() => {
    try {
      if (auth.isAuthenticated) {
        localStorage.setItem(persistKey, JSON.stringify(users));
      }
    } catch (error) {
      console.warn(error);
    }
  }, [auth.isAuthenticated, users, persistKey]);

  useEffect(() => {
    if (!auth.isAuthenticated) {
      try {
        localStorage.removeItem(persistKey);
      } catch (error) {
        console.warn(error);
      }  
      dispatch(actions.reset(initialState));
    }
  }, [auth.isAuthenticated, persistKey]);

  const userMap = useMemo(() => {
    return new Map((users?.users ?? []).map(user => [user.user_id, user] as const));
  }, [users?.users])

  const setUsers = (users: UserType[]) => {
    dispatch(actions.setUsers(users));
  }

  const updateUser = (user: UserType) => {
    dispatch(actions.updateUser(user));
  }

  const addUser = (user: UserType) => {
    dispatch(actions.addUser(user));
  };

  return (
    <Context.Provider value={{ users, setUsers, updateUser, addUser, userMap }}>
      {children}
    </Context.Provider>
  );
}
