import { memo } from 'react';
import { Link as RouterLink } from 'react-router-dom'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Link, Grid, Typography, useMediaQuery } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import ReactMarkdown from 'react-markdown';
import { usePartNumbers } from '../../../../providers/PartNumbers';
import { useUsers } from '../../../../providers/Users';
import { useInvestigations } from '../../../../providers/Investigations';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import SectionHeader from '../../../../components/SectionHeader';
import IncidentStatus from '../../../../components/IncidentStatus';
import { IncidentType } from '../../../../providers/Incidents/store/types';
import IncidentSpeedDial from '../../../../components/IncidentSpeedDial';
import Attachments from '../../../../components/Attachments';
import Tooltip from '../../../../components/Tooltip';
import { formatDate } from '../../../../utils/incidents';
import { generateTag, generateReport } from '../../../../utils/pdf';

const useStyles = makeStyles(theme => ({
  inputTitle: {
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
  redLabel: {
    color: theme.palette.error.dark,
  },
}));

interface Props {
  incident: IncidentType;
}

function IncidentDetails({ incident }: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const { userMap } = useUsers();
  const currentUser = useCurrentUser();
  const { investigationMap } = useInvestigations();
  const { partNumberDescriptionMap } = usePartNumbers();

  if (!currentUser) {
    return null;
  }

  const investigation = incident.investigationId
    ? investigationMap.get(incident.investigationId)
    : undefined;

  return (
    <div>
      <SectionHeader
        title={
          <>
            Incident Details
            { ' ' }
            <IncidentStatus value={incident.status} />
            <Tooltip
              title="Red Label Tag"
              placement="top-start"
            >
              <IconButton
                aria-label="Tag"
                color="inherit"
                onClick={() => generateTag(incident, userMap)}
              >
                <LocalOfferIcon className={classes.redLabel} />
              </IconButton>
            </Tooltip>
            <Tooltip
              title="Print"
              placement="top-start"
            >
              <IconButton
                aria-label="Print"
                color="inherit"
                onClick={() => generateReport(incident, investigation, userMap, partNumberDescriptionMap)}
              >
                <PictureAsPdfIcon />
              </IconButton>
            </Tooltip>
          </>
        }
        align="left"
      />
      {
        !!investigation && (
          <Grid container spacing={isMd ? 4 : 2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography
                variant="subtitle1"
                color="textPrimary"
                className={classes.inputTitle}
              >
                Related Investigation
              </Typography>
              <Typography
                variant="body1"
                color="textPrimary"
              >
                {
                  !investigation ? '—' : (
                    <Link component={RouterLink} to={`/investigation/${investigation.id}`}>
                      { investigation.status === 'opened' ? 'Opened' : 'Completed' }
                    </Link>
                  )
                }
              </Typography>
            </Grid>
          </Grid>
        )
      }
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className={classes.inputTitle}
          >
            Incident Number
          </Typography>
          <Typography
            variant="body1"
            color="textPrimary"
          >
            { incident.incidentNumber ?? '—' }
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className={classes.inputTitle}
          >
            Incident reported by
          </Typography>
          <Typography
            variant="body1"
            color="textPrimary"
          >
            { userMap.get(incident.reportedBy)?.name }
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className={classes.inputTitle}
          >
            Incident Date
          </Typography>
          <Typography
            variant="body1"
            color="textPrimary"
          >
            { formatDate(incident.incidentDate) }
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className={classes.inputTitle}
          >
            Product
          </Typography>
          <Typography
            variant="body1"
            color="textPrimary"
          >
            { incident.product ?? '—' }
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            color="textSecondary"
          >
            Defective item
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className={classes.inputTitle}
          >
            Part Number
          </Typography>
          <Typography
            variant="body1"
            color="textPrimary"
          >
            { incident.partNumber ?? '—' }
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className={classes.inputTitle}
          >
            Part Number Description
          </Typography>
          <Typography
            variant="body1"
            color="textPrimary"
          >
            { (incident.partNumber && partNumberDescriptionMap.get(incident.partNumber)) || '—' }
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className={classes.inputTitle}
          >
            Defective Quantity
          </Typography>
          <Typography
            variant="body1"
            color="textPrimary"
          >
            { incident.defectiveQuantity || '—' }
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className={classes.inputTitle}
          >
            Defective Product
          </Typography>
          <Typography
            variant="body1"
            color="textPrimary"
          >
            { investigation?.defectiveProductQuantity || '0' }
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className={classes.inputTitle}
          >
            GRN Number
          </Typography>
          <Typography
            variant="body1"
            color="textPrimary"
          >
            { incident.grnNumber || '—' }
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className={classes.inputTitle}
          >
            Order Number
          </Typography>
          <Typography
            variant="body1"
            color="textPrimary"
          >
            { incident.orderNumber || '—' }
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className={classes.inputTitle}
          >
            Location of the Incident
          </Typography>
          <Typography
            variant="body1"
            color="textPrimary"
          >
            { incident.incidentLocation?.title ?? '—' }
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className={classes.inputTitle}
          >
            Category
          </Typography>
          <Typography
            variant="body1"
            color="textPrimary"
          >
            { incident.category?.title ?? '—' }
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className={classes.inputTitle}
          >
            Subcategory Level 1
          </Typography>
          <Typography
            variant="body1"
            color="textPrimary"
          >
            { incident.subcategory?.title ?? '—' }
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className={classes.inputTitle}
          >
            Subcategory Level 2
          </Typography>
          <Typography
            variant="body1"
            color="textPrimary"
          >
            { incident.furtherSubcategory?.title ?? '—' }
          </Typography>
        </Grid>
        <Attachments attachments={incident.attachments} className={classes.inputTitle} />
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className={classes.inputTitle}
          >
            Description of the incident
          </Typography>
          <ReactMarkdown
            source={incident.description || '—'}
            renderers={{ paragraph: Typography }}
          />
        </Grid>
      </Grid>
      {
        (currentUser.role === 'admin' || (currentUser.role === 'quality' && incident.status !== 'draft' && incident.status !== 'cancelled') || ((incident.status === 'draft' || incident.status === 'pending-update') && incident.reportedBy === currentUser.user_id)) && (
          <IncidentSpeedDial incident={incident} />
        )
      }
    </div>
  );
}

export default memo(IncidentDetails);
