import { PropsWithChildren, useEffect, useState } from 'react';
import Context, { Report } from './Context';
import { useAuth } from '../Auth';

const initialState = {
  isLoading: true,
  reports: [] as Report[],
};

export default function Provider({
  persistKey = 'reports',
  children,
}: PropsWithChildren<{ persistKey?: string }>) {
  const { auth } = useAuth();

  const data = localStorage.getItem(persistKey);
  const json = data && JSON.parse(data);

  const [reports, setReports] = useState<typeof initialState>(() => json || initialState);

  useEffect(() => {
    try {
      if (auth.isAuthenticated) {
        localStorage.setItem(persistKey, JSON.stringify(reports));
      }
    } catch (error) {
      console.warn(error);
    }
  }, [auth.isAuthenticated, reports, persistKey]);

  useEffect(() => {
    if (!auth.isAuthenticated) {
      try {
        localStorage.removeItem(persistKey);
      } catch (error) {
        console.warn(error);
      }  
      setReports(initialState);
    }
  }, [auth.isAuthenticated, persistKey]);

  const updateReport = (newReport: Report) => {
    setReports(state => ({
      ...state,
      reports: [newReport],
      isLoading: false,
    }));
  }

  return (
    <Context.Provider value={{ ...reports, updateReport }}>
      {children}
    </Context.Provider>
  );
}
