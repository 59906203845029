import { PropsWithChildren, useEffect, useState } from 'react';
import Context from './Context';
import { useAuth } from '../Auth';
import { updateProfile as updateProfileServer } from '../../services/ApiClient';

const initialState = {
  isLoading: true,
  profile: {
    notifications: {
      submitted: true,
      acknowledged: true,
      rejected: true,
      'pending-update': true,
      closed: true,
    },
  },
};

export default function Provider({
  persistKey = 'profile',
  children,
}: PropsWithChildren<{ persistKey?: string }>) {
  const { auth } = useAuth();

  const data = localStorage.getItem(persistKey);
  const json = data && JSON.parse(data);

  const [profile, setProfile] = useState<typeof initialState>(() => json || initialState);

  useEffect(() => {
    try {
      if (auth.isAuthenticated) {
        localStorage.setItem(persistKey, JSON.stringify(profile));
      }
    } catch (error) {
      console.warn(error);
    }
  }, [auth.isAuthenticated, profile, persistKey]);

  useEffect(() => {
    if (!auth.isAuthenticated) {
      try {
        localStorage.removeItem(persistKey);
      } catch (error) {
        console.warn(error);
      }
      setProfile(initialState);
    }
  }, [auth.isAuthenticated, persistKey]);

  const updateProfile = (newProfile: (typeof initialState)['profile'], skipUpdate = false) => {
    setProfile(profile => ({
      ...profile,
      profile: !newProfile || JSON.stringify(newProfile) === '{}' ? initialState.profile : newProfile,
      isLoading: false,
    }));

    if (!skipUpdate) {
      updateProfileServer(newProfile);
    }
  };

  return (
    <Context.Provider value={{ ...profile, updateProfile }}>
      {children}
    </Context.Provider>
  );
}
