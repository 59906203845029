import { useMemo } from 'react';
import { useAuth } from '../providers/Auth';
import { useUsers } from '../providers/Users';

export default function useCurrentUser() {
  const { auth } = useAuth();
  const { users } = useUsers();

  const user = useMemo(() => {
    if (auth.isAuthenticated) {
      return users.users.find(user => user.user_id === auth.username);
    }
    
    return null;
  }, [auth, users]);
 
  return user;
}
