import { memo } from 'react';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Button } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import Loading from '../../../../components/Loading';
import { useNotifications } from '../../../../providers/Notifications';
import { changePassword } from '../../../../services/ApiClient';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  inputTitle: {
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
}));

const validationSchema = yup.object({
  newPassword: yup
    .string()
    .required('The new password is required.')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/,
      'The new password must have at least 8 characters, with at least one lowercase letter, one uppercase letter and one digit.'
    ),
  confirmNewPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'The passwords should match.')
    .required('The confirm password is required.')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/,
      'The confirm password must have at least 8 characters, with at least one lowercase letter, one uppercase letter and one digit.'
    ),
});

function ChangePasswordForm() {
  const classes = useStyles();
  const showAlert = useNotifications();

  return (
    <div className={classes.root}>
      <Formik
        initialValues={
          {
            oldPassword: '',
            newPassword: '',
            confirmNewPassword: '',
          }
        }
        validationSchema={validationSchema}
        onSubmit={
          async ({ oldPassword, newPassword }) => {
            try {
              await changePassword(oldPassword, newPassword);

              showAlert({
                message: 'The password has been changed successfully.',
                severity: 'success',
              });
            } catch (error) {
              showAlert({
                message: 'Failed to change the password.',
                severity: 'error',
              });
            }
          }
        }
      >
        {
          ({ submitForm, isSubmitting, values }) => (
            <>
              <Form>
                <Grid container item xs={12} sm={6} md={4} lg={3} spacing={2}>
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle1"
                      color="textPrimary"
                      className={classes.inputTitle}
                    >
                      Current password
                    </Typography>
                    <Field
                      component={TextField}
                      placeholder="Current password"
                      variant="outlined"
                      size="medium"
                      name="oldPassword"
                      type="password"
                      disabled={isSubmitting}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle1"
                      color="textPrimary"
                      className={classes.inputTitle}
                    >
                      New password
                    </Typography>
                    <Field
                      component={TextField}
                      placeholder="New password"
                      variant="outlined"
                      size="medium"
                      name="newPassword"
                      type="password"
                      disabled={isSubmitting}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle1"
                      color="textPrimary"
                      className={classes.inputTitle}
                    >
                      Confirm new password
                    </Typography>
                    <Field
                      component={TextField}
                      placeholder="Confirm new password"
                      variant="outlined"
                      size="medium"
                      name="confirmNewPassword"
                      type="password"
                      disabled={isSubmitting}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      size="large"
                      variant="contained"
                      type="button"
                      color="primary"
                      fullWidth
                      disabled={isSubmitting || values.newPassword.length === 0 || values.oldPassword.length === 0 || values.newPassword !== values.confirmNewPassword}
                      onClick={submitForm}
                    >
                      Change Password
                    </Button>
                  </Grid>
                </Grid>
              </Form>
              {
                isSubmitting && (
                  <Loading fullScreen />
                )
              }
            </>
          )
        }
      </Formik>
    </div>
  );
}

export default memo(ChangePasswordForm);
