import { memo } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles(() =>
  createStyles({
    rowCell: {
      height: 83,
    },
    rowValue: {
      maxHeight: 70,
      overflow: 'auto',
    },
  }),
);

interface Props {
  row: (string | number | null)[];
}

function IntelligenceRow({
  row,
}: Props) {
  const classes = useStyles();

  return (
    <TableRow
      hover
      tabIndex={-1}
    >
      {
        row.map((value, index) => (
          <TableCell
            key={index}
            align={typeof value === 'number' ? 'right' : 'left'}
            className={classes.rowCell}
          >
            <div className={classes.rowValue}>
              {value}
            </div>
          </TableCell>
        ))
      }
    </TableRow>
  );
}

export default memo(IntelligenceRow);
