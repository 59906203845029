import { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Section from '../../../../components/Section';
import SectionHeader from '../../../../components/SectionHeader';
import IncidentStates from './incident-states.svg';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  sectionNoPaddingTop: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  sectionPaddingTop: {
    paddingTop: 20,
    paddingBottom: 0,
  },
}));

function Incident() {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Section className={classes.sectionNoPaddingTop}>
        <div>
          <SectionHeader title="Incident" align="left" />
          <Typography>
            This software allows reporting incidents that describe an occurrence, typically an exception, during a process.
            An incident lifetime follows the protocol described in the image below:
          </Typography>
          <img src={IncidentStates} alt="Incident States" />
        </div>
      </Section>
      <Section className={classes.sectionPaddingTop}>
        <div>
          <Typography variant="h5">
            Draft
          </Typography>
          <Typography>
            The initial state that an incident takes is Draft.
            It indicates that the initial report is still being filled and is not visible to other users.
            An Draft incident can be cancelled directly if the user decides it is not worth submitting it.
            Otherwise, it can be submitted which will notify the quality team to process the incident.
            Upon submission, the status of the incident is changed to Submitted to indicate that it is pending a review by the quality team.
          </Typography>
        </div>
      </Section>
      <Section className={classes.sectionPaddingTop}>
        <div>
          <Typography variant="h5">
            Submitted
          </Typography>
          <Typography variant="body1">
            A member of the quality team 
            This software allows reporting incidents that describe an occurrence, typically an exception, during a process.
          </Typography>
        </div>
      </Section>
    </Box>
  );
}

export default memo(Incident);
