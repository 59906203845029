import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
  },
  lazy: {
    display: 'block',
  },
}));

interface Props {
  className?: string,
  src: string,
  srcSet?: string,
  alt?: string,
  lazy?: boolean,
  lazyProps?: {
    width: string;
    height: string;
  };
}

export default function Image({
  src,
  srcSet,
  alt = '...',
  lazy = true,
  lazyProps = {
    width: 'auto',
    height: 'auto',
  },
  className,
}: Props) {
  const classes = useStyles();

  if (lazy) {
    return (
      <LazyLoadImage
        className={clsx(classes.root, classes.lazy, className)}
        alt={alt}
        src={src}
        srcSet={srcSet}
        effect="opacity"
        {...lazyProps}
      />
    );
  }

  return (
    <img
      className={clsx(classes.root, className)}
      alt={alt}
      src={src}
      srcSet={srcSet}
    />
  );
}
