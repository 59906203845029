import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

export interface Props {
  open: boolean;
  onConfirm: () => unknown;
  onCancel: () => unknown;
}

export default function SubmitIncidentDialog({ open, onCancel, onConfirm }: Props) {
  return (
    <Dialog onClose={onCancel} open={open}>
      <DialogTitle id="simple-dialog-title">Are you sure you want to submit the incident?</DialogTitle>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={onConfirm} color="primary" autoFocus>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
