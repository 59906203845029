import { memo, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import type { UserType } from '../../providers/Users/store/types';

interface Props {
  open: boolean;
  initialUser: UserType;
  onConfirm: (status: boolean) => unknown;
  onCancel: () => unknown;
}

const options = ['Active', 'Deactivated'];

function EditStatusDialog({ initialUser, open, onConfirm, onCancel }: Props) {
  const [status, setStatus] = useState(() => initialUser.active ? 'Active' : 'Deactivated');

  const handleSave = () => {
    onConfirm(status === 'Active');
  };

  const handleUpdate = (_: object, status: any) => {
    if (status) {
      setStatus(status);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      disableBackdropClick
    >
      <DialogTitle id="scroll-dialog-title">User Status</DialogTitle>
      <DialogContent>
        <Autocomplete
          options={options}
          value={status}
          onChange={handleUpdate}
          renderInput={props => <TextField {...props} variant="outlined" />}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Update Status
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default memo(EditStatusDialog);
