import { useContext } from 'react';
import Context from './Context';
export { default } from './Provider';

export function usePartNumbers() {
  const partNumbers = useContext(Context);

  if (!partNumbers) {
    throw new Error('Failed to find partNumbers context.');
  }

  return partNumbers;
}
