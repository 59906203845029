import { useContext } from 'react';
import Context from './Context';
export { default } from './Provider';

export function useProfile() {
  const profile = useContext(Context);

  if (!profile) {
    throw new Error('Failed to find profile context.');
  }

  return profile;
}
