import { InvestigationType } from '../providers/Investigations/store/types';

export const rootCauseCategories = [
  { id: 'operatorError', title: '1.1	Operator Error' },
  { id: 'trainingLevel', title: '1.2	Training Level' },
  { id: 'processError', title: '2.1	Process Error' },
  { id: 'wrongSetting', title: '2.2	Wrong Setting' },
  { id: 'documentation', title: '2.3	Documentation' },
  { id: 'communication', title: '2.4	Communication' },
  { id: 'wrongMaterial', title: '3.1	Wrong Material' },
  { id: 'substandardMaterial', title: '3.2 Sub-standard Material' },
  { id: 'supplierManufacturerError', title: '3.3 Supplier/Manufacturer Error ' },
  { id: 'noEquipment', title: '4.1	No equipment' },
  { id: 'outOfSpecEquipment', title: '4.2	Out of Spec Equipment' },
  { id: 'inappropriateWorkEnvironment', title: '5.1 Inappropriate Work Environment' },
  { id: 'wrongSoftware', title: '6.1 Wrong Software' },
];

type ArrayElement<ArrayType extends readonly unknown[]> = ArrayType[number];
export type RootCauseCategory = ArrayElement<typeof rootCauseCategories>;

export function sanitiseInvestigationForm({
  files,
  containmentActionClosed,
  rootCauseClosed,
  correctiveActionClosed,
  preventiveActionClosed,
  additionalNotesClosed,
  ...investigation
}: {
  id: string;
  assignedTo: string;
  incidentDescription: string;
  incidentJustified: boolean | null;
  containmentAction: string;
  containmentActionSignedBy: string | null;
  containmentActionSignedDate: string | null;
  rootCause: string;
  rootCauseCategory: RootCauseCategory | null;
  rootCauseSignedBy: string | null;
  rootCauseSignedDate: string | null;
  correctiveAction: string;
  correctiveActionSignedBy: string | null;
  correctiveActionSignedDate: string | null;
  verificationOfEffectiveness: string;
  verificationOfEffectivenessSignedBy: string | null;
  verificationOfEffectivenessSignedDate: string | null;
  preventiveAction: string;
  preventiveActionSignedBy: string | null;
  preventiveActionSignedDate: string | null;
  defectiveProductQuantity: string | null;
  additionalNotes: string;
  additionalNotesCopied?: boolean;
  additionalNotesSignedBy: string | null;
  additionalNotesSignedDate: string | null;
  costOfLabour: number | null;
  costOfMaterial: number | null;
  costAdjustment: number | null;
  containmentActionClosed: boolean;
  rootCauseClosed: boolean;
  correctiveActionClosed: boolean;
  verificationOfEffectivenessClosed: boolean;
  preventiveActionClosed: boolean;
  additionalNotesClosed: boolean;
  completed: boolean;
  completedSignedBy: string | null;
  completedSignedDate: string | null;
  attachments: {
    id: string;
    name: string;
    type: string;
    size: number;
    url: string;
  }[];
  files: File[];
  status: string;
  created_at: string;
  updated_at: string;
}) {
  return {
    ...investigation,
    status: investigation.completed ? 'completed' : 'opened',
  };
}

export function patchInvestigation(investigation: InvestigationType) {
  const rootCauseCategory = (investigation.rootCauseCategory &&
    rootCauseCategories.find(rootCauseCategory => rootCauseCategory.id === investigation.rootCauseCategory!.id)) ?? null;

  return {
    ...investigation,
    rootCauseCategory,
    incidentJustified: investigation.incidentJustified ?? null,
    costOfLabour: investigation.costOfLabour ?? null,
    costOfMaterial: investigation.costOfMaterial ?? null,
    costAdjustment: investigation.costAdjustment ?? null,
    containmentActionSignedDate: investigation.containmentActionSignedDate ?? null,
    rootCauseSignedDate: investigation.rootCauseSignedDate ?? null,
    correctiveActionSignedDate: investigation.correctiveActionSignedDate ?? null,
    verificationOfEffectivenessSignedDate: investigation.verificationOfEffectivenessSignedDate ?? null,
    preventiveActionSignedDate: investigation.preventiveActionSignedDate ?? null,
    containmentActionClosed: !!investigation.containmentActionSignedDate,
    rootCauseClosed: !!investigation.rootCauseSignedDate,
    correctiveActionClosed: !!investigation.correctiveActionSignedDate,
    verificationOfEffectivenessClosed: !!investigation.verificationOfEffectivenessSignedDate,
    preventiveActionClosed: !!investigation.preventiveActionSignedDate,
    defectiveProductQuantity: investigation.defectiveProductQuantity ?? '0',
    additionalNotes: investigation.additionalNotes ?? '',
    additionalNotesSignedBy: investigation.additionalNotesSignedBy ?? null,
    additionalNotesSignedDate: investigation.additionalNotesSignedDate ?? null,
    additionalNotesClosed: !!investigation.additionalNotesSignedDate,
    completed: !!investigation.completed,
    completedSignedBy: investigation.completedSignedBy ?? null,
    completedSignedDate: investigation.completedSignedDate ?? null,
    attachments: investigation.attachments ?? [],
    files: [] as File[],
  };
}
