import React from 'react';

type ContextType = null | {
  isLoading: boolean;
  products: string[];
  updateProducts: (products: string[]) => unknown;
};

export const Context = React.createContext<ContextType>(null);

export default Context;
