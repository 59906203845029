import { memo } from 'react';
import Chart from '../../../../components/Chart';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import ReportSpeedDial from '../../../../components/ReportSpeedDial';

interface Props {
  report: {
    id: string;
    charts: {
      id: string;
      title: string;
      chartType: 'barChart' | 'barChartWithLine' | 'pieChart';
      groupBy: string;
      value1: string;
      value2: string;
      xLabel: string;
      yLabel1: string;
      yLabel2: string;
      filter: any;
      sortBy: 'groupBy' | 'value1' | 'value2';
      limit: any;
      visible: boolean;
    }[];
  };
}

function Report({ report }: Props) {
  const currentUser = useCurrentUser();

  return (
    <>
      {
        report.charts.filter(chart => chart.visible).map(chart => (
          <Chart
            key={chart.id}
            id={chart.id}
            title={chart.title}
            chartType={chart.chartType}
            groupBy={chart.groupBy}
            value1={chart.value1}
            value2={chart.value2}
            xLabel={chart.xLabel}
            yLabel1={chart.yLabel1}
            yLabel2={chart.yLabel2}
            filter={(chart as any).filter}
            sortBy={(chart as any).sortBy}
            limit={(chart as any).limit}
            showEdit={currentUser?.role === 'admin' || currentUser?.role === 'quality'}
          />
        ))
      }
      {
        (currentUser?.role === 'admin' || currentUser?.role === 'quality') && (
          <ReportSpeedDial report={report} />
        )
      }
    </>
  );
}

export default memo(Report);
