export enum AuthActionType {
  SetAuth = 'SET_AUTH',
  UpdateAuth = 'UPDATE_AUTH',
}

export type AuthStateType = { isAuthenticated: false } | {
  isAuthenticated: true;
  email: string;
  username: string;
};

interface SetAuthAction {
  type: AuthActionType.SetAuth;
  auth: AuthStateType;
}

interface UpdateAuthAction {
  type: AuthActionType.UpdateAuth;
  auth: AuthStateType;
}

export type AuthActionTypes = SetAuthAction | UpdateAuthAction;
