import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chipActive: {
      backgroundColor: theme.palette.success.light,
      color: theme.palette.success.contrastText,
    },
  }),
);

interface Props {
  active: boolean;
}

export default function UserStatus({ active }: Props) {
  const classes = useStyles();

  if (active) {
    return <Chip label="Active" className={classes.chipActive} />;
  }

  return <Chip label="Deactivated" />;
}
