import { memo } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import DashboardBreadcrums from '../../components/DashboardBreadcrums';
import Section from '../../components/Section';
import { useIncidents } from '../../providers/Incidents';
import { IncidentDetails } from './components';

const useStyles = makeStyles(theme => {
  return ({
    pagePaddingTop: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      [theme.breakpoints.up('md')]: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
      },
    },
    sectionNoPaddingTop: {
      paddingTop: 0,
    },
  });
});

function Incident({ match }: RouteComponentProps<{ id: string }>) {
  const { incidents } = useIncidents();
  const classes = useStyles();

  const incidentId = match.params.id;
  const incident = incidents.incidents.find(incident => incident.id === incidentId);

  if (!incident) {
    return <Redirect to="/not-found" />;
  }

  return (
    <div>
      <Section className={classes.pagePaddingTop}>
        <DashboardBreadcrums
          breadcrums={[
            { name: 'Home', path: '/' },
            { name: 'Incidents', path: '/incidents' },
            { name: `${incident.incidentNumber ? incident.incidentNumber : 'Incident Details'}`, path: `/incident/${incident.id}` },
          ]}
        />
      </Section>
      <Section className={classes.sectionNoPaddingTop}>
        <div>
          <IncidentDetails incident={incident} />
        </div>
      </Section>
    </div>
  );
}

export default memo(Incident);
