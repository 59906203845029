import { Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useAuth } from '../../providers/Auth';
import { useUsers } from '../../providers/Users';
import DashboardBreadcrums from '../../components/DashboardBreadcrums';
import Section from '../../components/Section';
import SectionHeader from '../../components/SectionHeader';
import { UsersFab, UserList } from './components';

const useStyles = makeStyles(theme => {
  return ({
    pagePaddingTop: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      [theme.breakpoints.up('md')]: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
      },
    },
    sectionNoPaddingTop: {
      paddingTop: 0,
    },
  });
});

export default function Users() {
  const classes = useStyles();
  const { auth } = useAuth();
  const { users } = useUsers();
 
  const user = users.users.find(user => auth.isAuthenticated && user.user_id === auth.username);

  if (user?.role !== 'admin') {
    return <Redirect to="/home" />;
  }

  return (
    <div>
      <Section className={classes.pagePaddingTop}>
        <DashboardBreadcrums
          breadcrums={[
            { name: 'Home', path: '/' },
            { name: 'Users', path: '/users' },
          ]}
        />
      </Section>
      <Section className={classes.sectionNoPaddingTop}>
        <div>
          <SectionHeader title="Users" align="left" />
          <UserList />
          <UsersFab />
        </div>
      </Section>
    </div>
  );
}
