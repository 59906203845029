import React from 'react';
import type { Location } from './types';

type ContextType = null | {
  isLoading: boolean;
  locations: Location[];
  updateLocations: (locations: Location[]) => unknown;
};

export const Context = React.createContext<ContextType>(null);

export default Context;
