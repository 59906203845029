import React, { Fragment, useState, useMemo } from 'react';
import clsx from 'clsx';
import { Redirect } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Button, TextField, Typography, useMediaQuery } from '@material-ui/core';
import { TextField as FormTextField, CheckboxWithLabel } from 'formik-material-ui';
import { Autocomplete, AutocompleteRenderInputParams } from 'formik-material-ui-lab';
import { useAuth } from '../../../../providers/Auth';
import { useInvestigations } from '../../../../providers/Investigations';
import { useUsers } from '../../../../providers/Users';
import * as ApiClient from '../../../../services/ApiClient';
import { sanitiseInvestigationForm, rootCauseCategories } from '../../../../utils/investigations';
import { buildAttachment } from '../../../../utils/attachments';
import { formatDate } from '../../../../utils/incidents';
import Loading from '../../../../components/Loading';
import FileUpload from '../../../../components/FileUpload';
import Attachments from '../../../../components/Attachments';
import DiscardChangesDialog from '../DiscardChangesDialog';
import SaveChangesDialog from '../SaveChangesDialog';
import ConfirmReopenDialog from '../ConfirmReopenDialog';
import { useIncidents } from '../../../../providers/Incidents';
import { useTemplates } from '../../../../providers/Templates';
import { formatCurrency } from '../../../../utils/price';

const useStyles = makeStyles(theme => ({
  form: {
    '& .MuiTextField-root': {
      background: theme.palette.background.paper,
    },
    '& .MuiOutlinedInput-input': {
      background: theme.palette.background.paper,
    },
  },
  inputTitle: {
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
  copiedTitle: {
    color: theme.palette.warning.main,
  },
  signedLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  uploadButton: {
    display: 'flex',
    justifyContent: 'center',
    border: '1px solid transparent',
    background: theme.palette.alternate.dark,
    textTransform: 'lowercase',
    '& .icon-text': {
      width: 'auto',
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start',
    },
  },
  submitButton: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start',
    },
  },
}));

type FormState = {
  id: string;
  assignedTo: string;
  incidentDescription: string;
  incidentDescriptionCopied?: boolean;
  incidentJustified: boolean | null;
  containmentAction: string;
  containmentActionClosed: boolean;
  containmentActionCopied?: boolean;
  containmentActionSignedBy: string | null;
  containmentActionSignedDate: string | null;
  rootCause: string;
  rootCauseCopied?: boolean;
  rootCauseCategory: { id: string; title: string; } | null;
  rootCauseCategoryCopied?: boolean;
  rootCauseClosed: boolean;
  rootCauseSignedBy: string | null;
  rootCauseSignedDate: string | null;
  correctiveAction: string;
  correctiveActionCopied?: boolean;
  correctiveActionClosed: boolean;
  correctiveActionSignedBy: string | null;
  correctiveActionSignedDate: string | null;
  verificationOfEffectiveness: string;
  verificationOfEffectivenessCopied?: boolean;
  verificationOfEffectivenessClosed: boolean;
  verificationOfEffectivenessSignedBy: string | null;
  verificationOfEffectivenessSignedDate: string | null;
  preventiveAction: string;
  preventiveActionCopied?: boolean;
  preventiveActionClosed: boolean;
  preventiveActionSignedBy: string | null;
  preventiveActionSignedDate: string | null;
  defectiveProductQuantity: string | null;
  defectiveProductQuantityCopied?: boolean;
  additionalNotes: string;
  additionalNotesCopied?: boolean;
  additionalNotesClosed: boolean;
  additionalNotesSignedBy: string | null;
  additionalNotesSignedDate: string | null;
  costOfLabour: number | null;
  costOfLabourCopied?: boolean;
  costOfMaterial: number | null;
  costOfMaterialCopied?: boolean;
  costAdjustment: number | null;
  costAdjustmentCopied?: boolean;
  completed: boolean;
  completedSignedBy: string | null;
  completedSignedDate: string | null;
  attachments: {
    id: string;
    name: string;
    type: string;
    size: number;
    url: string;
  }[];
  files: File[];
  status: string;
  created_at: string;
  updated_at: string;
};

interface Props {
  investigation: FormState;
}

export default function EditInvestigationForm({ investigation }: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const { auth } = useAuth();
  const { incidents, updateIncident } = useIncidents();
  const { templates } = useTemplates();
  const { updateInvestigation } = useInvestigations();
  const { userMap } = useUsers();
  const [hasSubmitted, setHasSubmitted] = useState<string>();
  const [showDiscard, setShowDiscard] = useState(false);
  const [showSave, setShowSave] = useState(false);
  const [showConfirmReopenContainmentDialog, setShowConfirmReopenContainmentDialog] = useState(false);
  const [showConfirmReopenRootCauseDialog, setShowConfirmReopenRootCauseDialog] = useState(false);
  const [showConfirmReopenCorrectiveDialog, setShowConfirmReopenCorrectiveDialog] = useState(false);
  const [showConfirmReopenVerificationOfEffectivenessDialog, setShowConfirmReopenVerificationOfEffectivenessDialog] = useState(false);
  const [showConfirmReopenPreventiveDialog, setShowConfirmReopenPreventiveDialog] = useState(false);
  const [showConfirmReopenAdditionalNotesDialog, setShowConfirmReopenAdditionalNotesDialog] = useState(false);
  const [showConfirmReopenCompletedDialog, setShowConfirmReopenCompletedDialog] = useState(false);

  const investigationIncidents = useMemo(
    () => incidents.incidents.filter(incident => incident.incidentNumber && incident.investigationId === investigation.id),
    [incidents.incidents, investigation],
  );

  if (!auth.isAuthenticated) {
    return null;
  }

  if (hasSubmitted) {
    return <Redirect to={`/investigation/${hasSubmitted}`} />;
  }

  return (
    <div className={classes.form}>
      <Formik
        initialValues={investigation}
        onSubmit={
          async (values) => {
            const attachments = [...values.attachments];

            for (let file of values.files) {
              const attachment = buildAttachment(file);
              await ApiClient.uploadImage(attachment.key, file);
              attachments.push({
                id: attachment.id,
                name: attachment.name,
                type: attachment.type,
                size: attachment.size,
                url: attachment.url,
              });
            }

            const {
              incidentDescriptionCopied,
              containmentActionCopied,
              rootCauseCopied,
              rootCauseCategoryCopied,
              correctiveActionCopied,
              verificationOfEffectivenessCopied,
              preventiveActionCopied,
              defectiveProductQuantityCopied,
              additionalNotesCopied,
              costOfLabourCopied,
              costOfMaterialCopied,
              costAdjustmentCopied,
              ...cleanValues
            } = values;

            const investigation = sanitiseInvestigationForm({
              ...cleanValues,
              attachments,
            });

            const result = await ApiClient.updateInvestigation(investigation);

            updateInvestigation(result);

            if (investigation.completed) {
              incidents?.incidents
                .filter(incident => incident.investigationId === investigation.id && incident.status === 'under-investigation')
                .forEach(incident => {
                  updateIncident({ ...incident, status: 'investigation-completed' });
                });
            }      

            Promise.resolve().then(() => setHasSubmitted(result.id));
          }
        }
      >
        {
          ({ submitForm, isSubmitting, touched, errors, values, setFieldValue, setSubmitting }) => (
            <>
              <Form>
                <Grid container spacing={isMd ? 4 : 2}>
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle1"
                      color="textPrimary"
                      className={classes.inputTitle}
                    >
                      Related Incidents
                    </Typography>
                    <Typography
                      variant="body1"
                      color="textPrimary"
                    >
                      {
                        investigationIncidents.length === 0 ? '—' : investigationIncidents.map((incident, index) => (
                          <Fragment key={incident.id}>
                            { incident.incidentNumber }
                            {
                              (index < investigationIncidents.length - 1) && ' '
                            }
                          </Fragment>
                        ))
                      }
                    </Typography>
                  </Grid>
                  <Attachments title="Templates" attachments={templates} className={classes.inputTitle} />
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle1"
                      color="textPrimary"
                      className={clsx(classes.inputTitle, values.incidentDescriptionCopied && classes.copiedTitle)}
                    >
                      Incident Description *
                    </Typography>
                    <Field
                      component={FormTextField}
                      variant="outlined"
                      size="medium"
                      name="incidentDescription"
                      type="text"
                      fullWidth
                      multiline
                      disabled={isSubmitting || values.completed}
                      rows={8}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle1"
                      color="textPrimary"
                      className={classes.inputTitle}
                    >
                      Attachments
                    </Typography>
                    <Field
                      component={FileUpload}
                      name="files"
                      variant="outlined"
                      disabled={isSubmitting || values.completed}
                    />
                  </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        color="textPrimary"
                        className={clsx(classes.inputTitle, values.containmentActionCopied && classes.copiedTitle)}
                      >
                        {
                          !!values.incidentJustified ? 'Containment Action *' : 'Containment Action'
                        }
                      </Typography>
                      <Field
                        component={FormTextField}
                        variant="outlined"
                        size="medium"
                        name="containmentAction"
                        type="text"
                        fullWidth
                        multiline
                        disabled={isSubmitting || values.containmentActionClosed || values.completed}
                        rows={8}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Field
                        component={CheckboxWithLabel}
                        variant="outlined"
                        size="medium"
                        name="containmentActionClosed"
                        type="checkbox"
                        Label={{ label: 'Completed' }}
                        disabled={isSubmitting || values.containmentAction.length === 0 || values.completed}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          if (event.target.checked) {
                            setFieldValue('containmentActionClosed', true);
                            setFieldValue('containmentActionSignedBy', auth.username);
                            setFieldValue('containmentActionSignedDate', new Date().toISOString().substring(0, 10));
                          } else {
                            setShowConfirmReopenContainmentDialog(true);
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={10} className={classes.signedLabel}>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                      >
                        {
                          values.containmentActionClosed
                            ? `Signed by ${userMap.get(values.containmentActionSignedBy!)?.name} on ${formatDate(values.containmentActionSignedDate)}`
                            : ''
                        }
                      </Typography>
                    </Grid>
                    <ConfirmReopenDialog
                      open={showConfirmReopenContainmentDialog}
                      onConfirm={() => {
                        setShowConfirmReopenContainmentDialog(false);
                        setFieldValue('containmentActionClosed', false);
                        setFieldValue('containmentActionSignedBy', null);
                        setFieldValue('containmentActionSignedDate', null);
                      }}
                      onCancel={() => {
                        setShowConfirmReopenContainmentDialog(false);
                      }}
                    />
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        color="textPrimary"
                        className={clsx(classes.inputTitle, values.rootCauseCopied && classes.copiedTitle)}
                      >
                        {
                          !!values.incidentJustified ? 'Root cause *' : 'Root cause'
                        }
                      </Typography>
                      <Field
                        component={FormTextField}
                        variant="outlined"
                        size="medium"
                        name="rootCause"
                        type="text"
                        fullWidth
                        multiline
                        disabled={isSubmitting || values.rootCauseClosed || values.completed}
                        rows={8}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        color="textPrimary"
                        className={clsx(classes.inputTitle, values.rootCauseCategoryCopied && classes.copiedTitle)}
                      >
                        {
                          !!values.incidentJustified ? 'Root Cause Category *' : 'Root Cause Category'
                        }
                      </Typography>
                      <Field
                        name="rootCauseCategory"
                        component={Autocomplete}
                        options={rootCauseCategories}
                        getOptionLabel={(option: { title: string }) => option?.title ?? ''}
                        style={{ width: '100%' }}
                        disabled={isSubmitting || values.rootCauseClosed || values.completed}
                        renderInput={(params: AutocompleteRenderInputParams) => (
                          <TextField
                            {...params}
                            error={touched['rootCauseCategory'] && !!errors['rootCauseCategory']}
                            helperText={touched['rootCauseCategory'] && errors['rootCauseCategory']}
                            placeholder="Root Cause Category"
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Field
                        component={CheckboxWithLabel}
                        variant="outlined"
                        size="medium"
                        name="rootCauseClosed"
                        type="checkbox"
                        Label={{ label: 'Completed' }}
                        disabled={isSubmitting || values.rootCause.length === 0 || !values.rootCauseCategory || values.completed}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          if (event.target.checked) {
                            setFieldValue('rootCauseClosed', true);
                            setFieldValue('rootCauseSignedBy', auth.username);
                            setFieldValue('rootCauseSignedDate', new Date().toISOString().substring(0, 10));
                          } else {
                            setShowConfirmReopenRootCauseDialog(true);
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={10} className={classes.signedLabel}>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                      >
                        {
                          values.rootCauseClosed
                            ? `Signed by ${userMap.get(values.rootCauseSignedBy!)?.name} on ${formatDate(values.rootCauseSignedDate)}`
                            : ''
                        }
                      </Typography>
                    </Grid>
                    <ConfirmReopenDialog
                      open={showConfirmReopenRootCauseDialog}
                      onConfirm={() => {
                        setShowConfirmReopenRootCauseDialog(false);
                        setFieldValue('rootCauseClosed', false);
                        setFieldValue('rootCauseSignedBy', null);
                        setFieldValue('rootCauseSignedDate', null);
                      }}
                      onCancel={() => {
                        setShowConfirmReopenRootCauseDialog(false);
                      }}
                    />
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        color="textPrimary"
                        className={clsx(classes.inputTitle, values.correctiveActionCopied && classes.copiedTitle)}
                      >
                        {
                          !!values.incidentJustified ? 'Corrective Action *' : 'Corrective Action'
                        }
                      </Typography>
                      <Field
                        component={FormTextField}
                        variant="outlined"
                        size="medium"
                        name="correctiveAction"
                        type="text"
                        fullWidth
                        multiline
                        disabled={isSubmitting || values.correctiveActionClosed || values.completed}
                        rows={8}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Field
                        component={CheckboxWithLabel}
                        variant="outlined"
                        size="medium"
                        name="correctiveActionClosed"
                        type="checkbox"
                        Label={{ label: 'Completed' }}
                        disabled={isSubmitting || values.correctiveAction.length === 0 || values.completed}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          if (event.target.checked) {
                            setFieldValue('correctiveActionClosed', true);
                            setFieldValue('correctiveActionSignedBy', auth.username);
                            setFieldValue('correctiveActionSignedDate', new Date().toISOString().substring(0, 10));
                          } else {
                            setShowConfirmReopenCorrectiveDialog(true);
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={10} className={classes.signedLabel}>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                      >
                        {
                          values.correctiveActionClosed
                            ? `Signed by ${userMap.get(values.correctiveActionSignedBy!)?.name} on ${formatDate(values.correctiveActionSignedDate)}`
                            : ''
                        }
                      </Typography>
                    </Grid>
                    <ConfirmReopenDialog
                      open={showConfirmReopenCorrectiveDialog}
                      onConfirm={() => {
                        setShowConfirmReopenCorrectiveDialog(false);
                        setFieldValue('correctiveActionClosed', false);
                        setFieldValue('correctiveActionSignedBy', null);
                        setFieldValue('correctiveActionSignedDate', null);
                      }}
                      onCancel={() => {
                        setShowConfirmReopenCorrectiveDialog(false);
                      }}
                    />
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        color="textPrimary"
                        className={clsx(classes.inputTitle, values.verificationOfEffectivenessCopied && classes.copiedTitle)}
                      >
                        {
                          !!values.incidentJustified ? 'Verification of Effectiveness *' : 'Verification of Effectiveness'
                        }
                      </Typography>
                      <Field
                        component={FormTextField}
                        variant="outlined"
                        size="medium"
                        name="verificationOfEffectiveness"
                        type="text"
                        fullWidth
                        multiline
                        disabled={isSubmitting || values.verificationOfEffectivenessClosed || values.completed}
                        rows={8}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Field
                        component={CheckboxWithLabel}
                        variant="outlined"
                        size="medium"
                        name="verificationOfEffectivenessClosed"
                        type="checkbox"
                        Label={{ label: 'Completed' }}
                        disabled={isSubmitting || values.verificationOfEffectiveness.length === 0 || values.completed}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          if (event.target.checked) {
                            setFieldValue('verificationOfEffectivenessClosed', true);
                            setFieldValue('verificationOfEffectivenessSignedBy', auth.username);
                            setFieldValue('verificationOfEffectivenessSignedDate', new Date().toISOString().substring(0, 10));
                          } else {
                            setShowConfirmReopenVerificationOfEffectivenessDialog(true);
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={10} className={classes.signedLabel}>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                      >
                        {
                          values.verificationOfEffectivenessClosed
                            ? `Signed by ${userMap.get(values.verificationOfEffectivenessSignedBy!)?.name} on ${formatDate(values.verificationOfEffectivenessSignedDate)}`
                            : ''
                        }
                      </Typography>
                    </Grid>
                    <ConfirmReopenDialog
                      open={showConfirmReopenVerificationOfEffectivenessDialog}
                      onConfirm={() => {
                        setShowConfirmReopenVerificationOfEffectivenessDialog(false);
                        setFieldValue('verificationOfEffectivenessClosed', false);
                        setFieldValue('verificationOfEffectivenessSignedBy', null);
                        setFieldValue('verificationOfEffectivenessSignedDate', null);
                      }}
                      onCancel={() => {
                        setShowConfirmReopenVerificationOfEffectivenessDialog(false);
                      }}
                    />
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        color="textPrimary"
                        className={clsx(classes.inputTitle, values.preventiveActionCopied && classes.copiedTitle)}
                      >
                        {
                          !!values.incidentJustified ? 'Preventive Action *' : 'Preventive Action'
                        }
                      </Typography>
                      <Field
                        component={FormTextField}
                        variant="outlined"
                        size="medium"
                        name="preventiveAction"
                        type="text"
                        fullWidth
                        multiline
                        disabled={isSubmitting || values.preventiveActionClosed || values.completed}
                        rows={8}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Field
                        component={CheckboxWithLabel}
                        variant="outlined"
                        size="medium"
                        name="preventiveActionClosed"
                        type="checkbox"
                        Label={{ label: 'Completed' }}
                        disabled={isSubmitting || values.preventiveAction.length === 0 || values.completed}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          if (event.target.checked) {
                            setFieldValue('preventiveActionClosed', true);
                            setFieldValue('preventiveActionSignedBy', auth.username);
                            setFieldValue('preventiveActionSignedDate', new Date().toISOString().substring(0, 10));
                          } else {
                            setShowConfirmReopenPreventiveDialog(true);
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={10} className={classes.signedLabel}>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                      >
                        {
                          values.preventiveActionClosed
                            ? `Signed by ${userMap.get(values.preventiveActionSignedBy!)?.name} on ${formatDate(values.preventiveActionSignedDate)}`
                            : ''
                        }
                      </Typography>
                    </Grid>
                    <ConfirmReopenDialog
                      open={showConfirmReopenPreventiveDialog}
                      onConfirm={() => {
                        setShowConfirmReopenPreventiveDialog(false);
                        setFieldValue('preventiveActionClosed', false);
                        setFieldValue('preventiveActionSignedBy', null);
                        setFieldValue('preventiveActionSignedDate', null);
                      }}
                      onCancel={() => {
                        setShowConfirmReopenPreventiveDialog(false);
                      }}
                    />
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        color="textPrimary"
                        className={clsx(classes.inputTitle, values.additionalNotesCopied && classes.copiedTitle)}
                      >
                        Conclusion / Additional Notes
                      </Typography>
                      <Field
                        component={FormTextField}
                        variant="outlined"
                        size="medium"
                        name="additionalNotes"
                        type="text"
                        fullWidth
                        multiline
                        disabled={isSubmitting || values.additionalNotesClosed || values.completed}
                        rows={8}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Field
                        component={CheckboxWithLabel}
                        variant="outlined"
                        size="medium"
                        name="additionalNotesClosed"
                        type="checkbox"
                        Label={{ label: 'Completed' }}
                        disabled={isSubmitting || values.additionalNotes.length === 0 || values.completed}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          if (event.target.checked) {
                            setFieldValue('additionalNotesClosed', true);
                            setFieldValue('additionalNotesSignedBy', auth.username);
                            setFieldValue('additionalNotesSignedDate', new Date().toISOString().substring(0, 10));
                          } else {
                            setShowConfirmReopenAdditionalNotesDialog(true);
                          }
                        }}
                      />
                    </Grid>
                    <Grid item xs={10} className={classes.signedLabel}>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                      >
                        {
                          values.additionalNotesClosed
                            ? `Signed by ${userMap.get(values.additionalNotesSignedBy!)?.name} on ${formatDate(values.additionalNotesSignedDate)}`
                            : ''
                        }
                      </Typography>
                    </Grid>
                    <ConfirmReopenDialog
                      open={showConfirmReopenAdditionalNotesDialog}
                      onConfirm={() => {
                        setShowConfirmReopenAdditionalNotesDialog(false);
                        setFieldValue('additionalNotesClosed', false);
                        setFieldValue('additionalNotesSignedBy', null);
                        setFieldValue('additionalNotesSignedDate', null);
                      }}
                      onCancel={() => {
                        setShowConfirmReopenAdditionalNotesDialog(false);
                      }}
                    />
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Typography
                        variant="subtitle1"
                        color="textPrimary"
                        className={clsx(classes.inputTitle, values.defectiveProductQuantityCopied && classes.copiedTitle)}
                      >
                        Defective Product *
                      </Typography>
                      <Field
                        component={FormTextField}
                        placeholder="Defective Quantity"
                        variant="outlined"
                        size="medium"
                        name="defectiveProductQuantity"
                        type="number"
                        onChange={(event: any) => {
                          setFieldValue('defectiveProductQuantity', event.target.value.replace(/-/g, ''));
                        }}
                        fullWidth
                        disabled={isSubmitting}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Typography
                        variant="subtitle1"
                        color="textPrimary"
                        className={clsx(classes.inputTitle, values.costOfLabourCopied && classes.copiedTitle)}
                      >
                        Cost of Labour (£)
                      </Typography>
                      <Field
                        component={FormTextField}
                        placeholder="Cost of Labour"
                        variant="outlined"
                        size="medium"
                        name="costOfLabour"
                        type="number"
                        fullWidth
                        disabled={isSubmitting}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Typography
                        variant="subtitle1"
                        color="textPrimary"
                        className={clsx(classes.inputTitle, values.costOfMaterialCopied && classes.copiedTitle)}
                      >
                        Cost of Material (£)
                      </Typography>
                      <Field
                        component={FormTextField}
                        placeholder="Cost of Material"
                        variant="outlined"
                        size="medium"
                        name="costOfMaterial"
                        type="number"
                        fullWidth
                        disabled={isSubmitting}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Typography
                        variant="subtitle1"
                        color="textPrimary"
                        className={clsx(classes.inputTitle, values.costAdjustmentCopied && classes.copiedTitle)}
                      >
                        Cost Adjustment (£)
                      </Typography>
                      <Field
                        component={FormTextField}
                        placeholder="Cost Adjustment"
                        variant="outlined"
                        size="medium"
                        name="costAdjustment"
                        type="number"
                        fullWidth
                        disabled={isSubmitting}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <Typography
                        variant="subtitle1"
                        color="textPrimary"
                        className={classes.inputTitle}
                      >
                        Total Cost (£)
                      </Typography>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                      >
                        {
                          formatCurrency((+(values.costOfLabour ?? 0) + (values.costOfMaterial ?? 0) + (values.costAdjustment ?? 0)) || '—')
                        }
                      </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={isMd ? 4 : 2}>
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle1"
                      color="textPrimary"
                      className={classes.inputTitle}
                    >
                      Is the incident justified? *
                    </Typography>
                    <Field
                      name="incidentJustified"
                      component={Autocomplete}
                      options={[true, false]}
                      getOptionLabel={(option: boolean | null) => option === false ? 'No' : (option ? 'Yes' : '')}
                      style={{ width: '100%' }}
                      disabled={isSubmitting || values.completed}
                      renderInput={(params: AutocompleteRenderInputParams) => (
                        <TextField
                          {...params}
                          error={touched['incidentJustified'] && !!errors['incidentJustified']}
                          helperText={touched['incidentJustified'] && errors['incidentJustified']}
                          placeholder="Is the incident justified"
                          variant="outlined"
                          size="medium"
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={isMd ? 4 : 2}>
                  {
                    values.incidentJustified !== null && (
                      <>
                        <Grid item xs={2}>
                          <Field
                            component={CheckboxWithLabel}
                            variant="outlined"
                            size="medium"
                            name="completed"
                            type="checkbox"
                            Label={{ label: 'Is completed?' }}
                            disabled={isSubmitting || values.incidentDescription.trim().length === 0 || (values.incidentJustified && (!values.containmentActionClosed || !values.rootCauseClosed || !values.correctiveActionClosed || !values.verificationOfEffectivenessClosed || !values.preventiveActionClosed || !values.defectiveProductQuantity || values.defectiveProductQuantity?.trim().length === 0))}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              if (event.target.checked) {
                                setFieldValue('completed', true);
                                setFieldValue('completedSignedBy', auth.username);
                                setFieldValue('completedSignedDate', new Date().toISOString().substring(0, 10));
                              } else {
                                setShowConfirmReopenCompletedDialog(true);
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={10} className={classes.signedLabel}>
                          <Typography
                            variant="body1"
                            color="textPrimary"
                          >
                            {
                              values.completed
                                ? `Signed by ${userMap.get(values.completedSignedBy!)?.name} on ${formatDate(values.completedSignedDate)}`
                                : ''
                            }
                          </Typography>
                        </Grid>
                        <ConfirmReopenDialog
                          open={showConfirmReopenCompletedDialog}
                          onConfirm={() => {
                            setShowConfirmReopenCompletedDialog(false);
                            setFieldValue('completed', false);
                            setFieldValue('completedSignedBy', null);
                            setFieldValue('completedSignedDate', null);
                          }}
                          onCancel={() => {
                            setShowConfirmReopenCompletedDialog(false);
                          }}
                        />
                      </>
                    )
                  }
                  <Grid item xs={12} sm={6}>
                    <Button
                      size="large"
                      variant="outlined"
                      type="button"
                      color="primary"
                      name="saveDraft"
                      fullWidth
                      disabled={isSubmitting}
                      onClick={() => setShowDiscard(true)}
                      className={classes.submitButton}
                    >
                      Discard changes
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button
                      size="large"
                      variant="contained"
                      type="button"
                      color="primary"
                      name="submit"
                      fullWidth
                      disabled={isSubmitting}
                      onClick={() => setShowSave(true)}
                      className={classes.submitButton}
                    >
                      Save changes
                    </Button>
                  </Grid>
                </Grid>
              </Form>
              {
                isSubmitting && (
                  <Loading fullScreen />
                )
              }
              <DiscardChangesDialog
                open={showDiscard}
                onConfirm={() => {
                  setShowDiscard(false);
                  setHasSubmitted(values.id);
                }}
                onCancel={() => {
                  setShowDiscard(false);
                }}
              />
              <SaveChangesDialog
                open={showSave}
                onConfirm={async () => {
                  setSubmitting(true);
                  setShowSave(false);
                  await submitForm();
                }}
                onCancel={() => {
                  setShowSave(false);
                }}
              />
            </>
          )
        }
      </Formik>
    </div>
  );
}
