import { memo, useRef } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import SortableFilterList from '../SortableFilterList';

interface Item {
  id: string;
  title: string;
  visible: boolean;
}

interface Props<T extends Item> {
  open: boolean;
  initialItems: T[];
  onConfirm: (items: T[]) => unknown;
  onCancel: () => unknown;
}

function EditReportDialog<T extends Item>({ initialItems, open, onConfirm, onCancel }: Props<T>) {
  const items = useRef(initialItems.map(item => ({
    id: item.id,
    label: item.title,
    selected: item.visible,
  })));

  const handleSave = () => {
    const itemMap = new Map(initialItems.map(item => [item.id, item]));
    onConfirm(items.current.map(item => {
      const initialItem = itemMap.get(item.id)!;
      return {
        ...initialItem,
        visible: !!item.selected,
      };
    }));
  };

  const handleUpdate = (newItems: any) => {
    items.current = newItems;
  };

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      disableBackdropClick
    >
      <DialogTitle id="scroll-dialog-title">Intelligence Options</DialogTitle>
      <DialogContent dividers={true}>
        <SortableFilterList initialItems={items.current} onUpdate={handleUpdate as any} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default memo(EditReportDialog);
