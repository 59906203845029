import { memo, useEffect, useMemo } from 'react';
import { Field, FormikErrors, FormikTouched } from 'formik';
import { Autocomplete, AutocompleteRenderInputParams } from 'formik-material-ui-lab';
import { TextField } from '@material-ui/core';
import type { Subcategory, FurtherSubcategory } from '../../providers/Locations/types';

type FormState = {
  subcategory: Subcategory | null;
  furtherSubcategory: FurtherSubcategory | null;
}

type Props<T extends FormState> = {
  isSubmitting: boolean;
  touched: FormikTouched<T>;
  errors: FormikErrors<T>;
  values: T;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
};

function FurtherSubcategoryField<T extends FormState>({
  isSubmitting,
  touched,
  errors,
  values,
  setFieldValue,
}: Props<T>) {
  useEffect(() => {
    if (!values.furtherSubcategory) {
      return;
    }

    if (!values.subcategory || !values.subcategory.furtherSubcategories.includes(values.furtherSubcategory)) {
      setFieldValue('furtherSubcategory', null);
    }
  }, [values.subcategory, values.furtherSubcategory, setFieldValue]);

  const options = useMemo(() => {
    return values.subcategory?.furtherSubcategories ?? [];
  }, [values.subcategory]);

  return (
    <Field
      name="furtherSubcategory"
      component={Autocomplete}
      options={options}
      getOptionLabel={(option: { title: string }) => option?.title ?? ''}
      style={{ width: '100%' }}
      disabled={!options.length || isSubmitting}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          error={touched['furtherSubcategory'] && !!errors['furtherSubcategory']}
          helperText={touched['furtherSubcategory'] && errors['furtherSubcategory']}
          placeholder={!options.length ? 'Not applicable' : 'Subcategory Level 2'}
          variant="outlined"
        />
      )}
    />
  );
}

export default memo(FurtherSubcategoryField);
