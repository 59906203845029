import { memo, useRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import Tooltip from '../Tooltip';
import SortableFilterList from '../SortableFilterList';

interface Item {
  id: string;
  label: string;
  selected: boolean;
}

interface Props<T extends Item> {
  initialItems: T[];
  onUpdate: (items: T[]) => unknown;
}

function FilterMenu<T extends Item>({ initialItems, onUpdate }: Props<T>) {
  const [open, setOpen] = useState(false);
  const items = useRef<T[]>(initialItems);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleSave = () => {
    setOpen(false);
    if (items.current !== initialItems) {
      onUpdate(items.current);
    }
  };

  const handleUpdate = (newItems: T[]) => {
    items.current = newItems;
  };

  return (
    <div>
      <Tooltip
        title="Incident Filter Options"
        placement="top-start"
      >
        <IconButton
          aria-label="export"
          aria-controls="menu-table-export"
          aria-haspopup="true"
          onClick={handleClickOpen}
          color="inherit"
        >
          <ViewColumnIcon />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleCancel}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        disableBackdropClick
      >
        <DialogTitle id="scroll-dialog-title">Incident Filter Options</DialogTitle>
        <DialogContent dividers={true}>
          <SortableFilterList initialItems={initialItems} onUpdate={handleUpdate as any} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default memo(FilterMenu);
