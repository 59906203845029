import { Redirect, Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { Box, List, ListItem, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useAuth } from '../../providers/Auth';
import { useUsers } from '../../providers/Users';
import CardBase from '../../components/CardBase';
import DashboardBreadcrums from '../../components/DashboardBreadcrums';
import Section from '../../components/Section';
import SectionHeader from '../../components/SectionHeader';
import Products from './components/Products';
import PartNumbers from './components/PartNumbers';
import Locations from './components/Locations';
import Templates from './components/Templates';

const useStyles = makeStyles(theme => {
  return ({
    pagePaddingTop: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      [theme.breakpoints.up('md')]: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
      },
    },
    sectionNoPaddingTop: {
      paddingTop: 0,
    },
    menu: {
      height: 'auto',
    },
    list: {
      display: 'inline-flex',
      overflow: 'auto',
      flexWrap: 'nowrap',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: theme.spacing(-3),
        marginLeft: theme.spacing(-3),
      },
    },
    listItem: {
      marginRight: theme.spacing(2),
      flex: 0,
      [theme.breakpoints.up('md')]: {
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        borderLeft: '2px solid transparent',
      },
    },
    listItemActive: {
      [theme.breakpoints.up('md')]: {
        borderLeft: `2px solid ${theme.palette.primary.dark}`,
      },
      '& .menu__item': {
        color: theme.palette.text.primary,
      },
    },
  });
});

interface TabPanelProps {
  children: JSX.Element;
  value: string | string[] | number | null;
  index: string | string[] | number | null;
};

const useTabPanelStyles = makeStyles(() => ({
  tabPanel: {
    width: '100%',
  },
}));

function TabPanel({ children, value, index, ...other }: TabPanelProps) {
  const classes = useTabPanelStyles();
  return (
    <Box component="div" className={classes.tabPanel} hidden={value !== index} {...other}>
      {value === index && children}
    </Box>
  );
}

const subPages = [
  {
    id: 'products',
    href: '/admin/products',
    title: 'Products',
  },
  {
    id: 'part-numbers',
    href: '/admin/part-numbers',
    title: 'Part numbers',
  },
  {
    id: 'locations',
    href: '/admin/locations',
    title: 'Locations',
  },
  {
    id: 'templates',
    href: '/admin/templates',
    title: 'Templates',
  },
];

export default function Administration() {
  const classes = useStyles();
  const { auth } = useAuth();
  const { users } = useUsers();
  let pageId = window.location.pathname.substring(7) || 'products';
 
  const user = users.users.find(user => auth.isAuthenticated && user.user_id === auth.username);

  if (user?.role !== 'admin') {
    return <Redirect to="/home" />;
  }

  return (
    <div>
      <Section className={classes.pagePaddingTop}>
        <DashboardBreadcrums
          breadcrums={[
            { name: 'Home', path: '/' },
            { name: 'Administration', path: '/admin' },
          ]}
        />
      </Section>
      <Section className={classes.sectionNoPaddingTop}>
        <div>
          <SectionHeader title="Administration" align="left" />
          <Grid container spacing={4}>
            <Grid item xs={12} md={3}>
              <CardBase withShadow align="left" className={classes.menu}>
                <List disablePadding className={classes.list}>
                  {subPages.map((item, index) => (
                    <ListItem
                      key={index}
                      component={RouterLink}
                      to={item.href}
                      className={clsx(
                        classes.listItem,
                        pageId === item.id ? classes.listItemActive : {},
                      )}
                      disableGutters
                    >
                      <Typography
                        variant="subtitle1"
                        noWrap
                        color="textSecondary"
                        className="menu__item"
                      >
                        {item.title}
                      </Typography>
                    </ListItem>
                  ))}
                </List>
              </CardBase>
            </Grid>
            <Grid item xs={12} md={9}>
              <CardBase withShadow align="left">
                <>
                  <TabPanel value={pageId} index="products">
                    <Products />
                  </TabPanel>
                  <TabPanel value={pageId} index="part-numbers">
                    <PartNumbers />
                  </TabPanel>
                  <TabPanel value={pageId} index="locations">
                    <Locations />
                  </TabPanel>
                  <TabPanel value={pageId} index="templates">
                    <Templates />
                  </TabPanel>
                </>
              </CardBase>
            </Grid>
          </Grid>
        </div>
      </Section>
    </div>
  );
}
