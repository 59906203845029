import React from 'react';

type ContextType = null | {
  isLoading: boolean;
  partNumbers: { id: string; description: string; }[];
  partNumberDescriptionMap: Map<string, string>;
  updatePartNumbers: (partNumbers: { id: string; description: string; }[]) => unknown;
};

export const Context = React.createContext<ContextType>(null);

export default Context;
