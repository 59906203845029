import { Fragment, useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Link, Grid, Typography, useMediaQuery } from '@material-ui/core';
import ReactMarkdown from 'react-markdown';
import { useUsers } from '../../../../providers/Users';
import { useIncidents } from '../../../../providers/Incidents';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import { formatDate } from '../../../../utils/incidents';
import { formatCurrency } from '../../../../utils/price';
import SectionHeader from '../../../../components/SectionHeader';
import InvestigationStatus from '../../../../components/InvestigationStatus';
import InvestigationFab from '../../../../components/InvestigationFab';
import Attachments from '../../../../components/Attachments';
import { InvestigationType } from '../../../../providers/Investigations/store/types';

const useStyles = makeStyles(theme => ({
  inputTitle: {
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
  signed: {
    paddingTop: theme.spacing(2),
  },
  groupBox: {
    height: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2),
    background: theme.palette.alternate.dark,  
  },
}));

interface Props {
  investigation: InvestigationType;
}

export default function InvestigationDetails({ investigation }: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const currentUser = useCurrentUser();
  const { userMap } = useUsers();
  const { incidents } = useIncidents();

  const investigationIncidents = useMemo(
    () => incidents.incidents.filter(incident => incident.incidentNumber && incident.investigationId === investigation.id),
    [incidents.incidents, investigation],
  );

  if (!currentUser) {
    return null;
  }

  return (
    <div>
      <SectionHeader
        title={
          <>
            Investigation Details
            { ' ' }
            <InvestigationStatus value={investigation.status} />
          </>
        }
        align="left"
      />
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className={classes.inputTitle}
          >
            Related Incidents
          </Typography>
          <Typography
            variant="body1"
            color="textPrimary"
          >
            {
              investigationIncidents.length === 0 ? '—' : investigationIncidents.map((incident, index) => (
                <Fragment key={incident.id}>
                  <Link component={RouterLink} to={`/incident/${incident.id}`}>
                    { incident.incidentNumber }
                  </Link>
                  {
                    (index < investigationIncidents.length - 1) && ' '
                  }
                </Fragment>
              ))
            }
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className={classes.inputTitle}
          >
            Main Investigator
          </Typography>
          <Typography
            variant="body1"
            color="textPrimary"
          >
            <Link component={RouterLink} to={`/user/${investigation.assignedTo}`}>
              { userMap.get(investigation.assignedTo)?.name }
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className={classes.inputTitle}
          >
            Description of the incident
          </Typography>
          <ReactMarkdown
            source={investigation.incidentDescription || '—'}
            renderers={{ paragraph: Typography }}
          />
        </Grid>
        <Attachments attachments={investigation.attachments} className={classes.inputTitle} />
        <Grid item xs={12} md={6}>
          <div className={classes.groupBox}>
            <Typography
              variant="subtitle1"
              color="textPrimary"
              className={classes.inputTitle}
            >
              Containment Action
            </Typography>
            <ReactMarkdown
              source={investigation.containmentAction || '—'}
              renderers={{ paragraph: Typography }}
            />
            {
              investigation.containmentActionSignedBy && investigation.containmentActionSignedDate && (
                <Typography
                  variant="body2"
                  color="textPrimary"
                  className={classes.signed}
                >
                  {
                    `Completed by ${
                      userMap.get(investigation.containmentActionSignedBy)?.name
                    } on ${formatDate(investigation.containmentActionSignedDate)}`
                  }
                </Typography>
              )
            }
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className={classes.groupBox}>
            <Typography
              variant="subtitle1"
              color="textPrimary"
              className={classes.inputTitle}
            >
              Root Cause
            </Typography>
            <ReactMarkdown
              source={investigation.rootCause || '—'}
              renderers={{ paragraph: Typography }}
            />
            <Typography
              variant="subtitle1"
              color="textPrimary"
              className={classes.inputTitle}
            >
              Root Cause Category
            </Typography>
            <Typography
              variant="body1"
              color="textPrimary"
            >
              { investigation.rootCauseCategory?.title ?? '—' }
            </Typography>
            {
              investigation.rootCauseSignedBy && investigation.rootCauseSignedDate && (
                <Typography
                  variant="body2"
                  color="textPrimary"
                  className={classes.signed}
                >
                  {
                    `Completed by ${
                      userMap.get(investigation.rootCauseSignedBy)?.name
                    } on ${formatDate(investigation.rootCauseSignedDate)}`
                  }
                </Typography>
              )
            }
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className={classes.groupBox}>
            <Typography
              variant="subtitle1"
              color="textPrimary"
              className={classes.inputTitle}
            >
              Corrective Action
            </Typography>
            <ReactMarkdown
              source={investigation.correctiveAction || '—'}
              renderers={{ paragraph: Typography }}
            />
            {
              investigation.correctiveActionSignedBy && investigation.correctiveActionSignedDate && (
                <Typography
                  variant="body2"
                  color="textPrimary"
                  className={classes.signed}
                >
                  {
                    `Completed by ${
                      userMap.get(investigation.correctiveActionSignedBy)?.name
                    } on ${formatDate(investigation.correctiveActionSignedDate)}`
                  }
                </Typography>
              )
            }
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className={classes.groupBox}>
            <Typography
              variant="subtitle1"
              color="textPrimary"
              className={classes.inputTitle}
            >
              Verification of Effectiveness
            </Typography>
            <ReactMarkdown
              source={investigation.verificationOfEffectiveness || '—'}
              renderers={{ paragraph: Typography }}
            />
            {
              investigation.verificationOfEffectivenessSignedBy && investigation.verificationOfEffectivenessSignedDate && (
                <Typography
                  variant="body2"
                  color="textPrimary"
                  className={classes.signed}
                >
                  {
                    `Completed by ${
                      userMap.get(investigation.verificationOfEffectivenessSignedBy)?.name
                    } on ${formatDate(investigation.verificationOfEffectivenessSignedDate)}`
                  }
                </Typography>
              )
            }
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className={classes.groupBox}>
            <Typography
              variant="subtitle1"
              color="textPrimary"
              className={classes.inputTitle}
            >
              Preventive Action
            </Typography>
            <ReactMarkdown
              source={investigation.preventiveAction || '—'}
              renderers={{ paragraph: Typography }}
            />
            {
              investigation.preventiveActionSignedBy && investigation.preventiveActionSignedDate && (
                <Typography
                  variant="body2"
                  color="textPrimary"
                  className={classes.signed}
                >
                  {
                    `Completed by ${
                      userMap.get(investigation.preventiveActionSignedBy)?.name
                    } on ${formatDate(investigation.preventiveActionSignedDate)}`
                  }
                </Typography>
              )
            }
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className={classes.groupBox}>
            <Typography
              variant="subtitle1"
              color="textPrimary"
              className={classes.inputTitle}
            >
              Conclusion / Additional Notes
            </Typography>
            <ReactMarkdown
              source={investigation.additionalNotes || '—'}
              renderers={{ paragraph: Typography }}
            />
            {
              investigation.additionalNotesSignedBy && investigation.additionalNotesSignedDate && (
                <Typography
                  variant="body2"
                  color="textPrimary"
                  className={classes.signed}
                >
                  {
                    `Completed by ${
                      userMap.get(investigation.additionalNotesSignedBy)?.name
                    } on ${formatDate(investigation.additionalNotesSignedDate)}`
                  }
                </Typography>
              )
            }
          </div>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Typography
              variant="subtitle1"
              color="textPrimary"
              className={classes.inputTitle}
            >
              Cost of Labour (£)
            </Typography>
            <Typography
              variant="body1"
              color="textPrimary"
            >
              { formatCurrency(investigation.costOfLabour || '—') }
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Typography
              variant="subtitle1"
              color="textPrimary"
              className={classes.inputTitle}
            >
              Cost of Material (£)
            </Typography>
            <Typography
              variant="body1"
              color="textPrimary"
            >
              { formatCurrency(investigation.costOfMaterial || '—') }
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Typography
              variant="subtitle1"
              color="textPrimary"
              className={classes.inputTitle}
            >
              Cost Adjustment (£)
            </Typography>
            <Typography
              variant="body1"
              color="textPrimary"
            >
              { formatCurrency(investigation.costAdjustment || '—') }
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Typography
              variant="subtitle1"
              color="textPrimary"
              className={classes.inputTitle}
            >
              Total Cost (£)
            </Typography>
            <Typography
              variant="body1"
              color="textPrimary"
            >
              { formatCurrency((+(investigation.costOfLabour ?? 0) + (investigation.costOfMaterial ?? 0) + (investigation.costAdjustment ?? 0)) || '—') }
            </Typography>
          </Grid>
        </Grid>
        {
          investigation.incidentJustified !== false && (
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                color="textPrimary"
                className={classes.inputTitle}
              >
                The incident is justified.
              </Typography>
            </Grid>
          )
        }
        {
          investigation.incidentJustified === false && (
            <Grid item xs={12}>
              <Typography
                variant="subtitle1"
                color="textPrimary"
                className={classes.inputTitle}
              >
                The incident is not justified.
              </Typography>
            </Grid>
          )
        }
        {
          investigation.completedSignedBy && investigation.completedSignedDate && (
            <Grid item xs={12}>
              <Typography
                variant="body2"
                color="textPrimary"
                className={classes.signed}
              >
                {
                  `Completed by ${
                    userMap.get(investigation.completedSignedBy)?.name
                  } on ${formatDate(investigation.completedSignedDate)}`
                }
              </Typography>
            </Grid>
          )
        }
      </Grid>
      {
        currentUser.role !== 'user' && (
          <InvestigationFab investigationId={investigation.id} />
        )
      }
    </div>
  );
}
