import { IncidentsStateType, IncidentsActionType, IncidentsActionTypes } from './types';

export const initialState: IncidentsStateType = {
  isLoading: true,
  incidents: [],
};

export default function reducer(
  state: IncidentsStateType,
  action: IncidentsActionTypes,
) {
  switch (action.type) {
    case IncidentsActionType.AddIncident:
      return {
        ...state,
        incidents: [action.incident, ...state.incidents],
      };
    case IncidentsActionType.UpdateIncident:
      return {
        ...state,
        incidents: state.incidents.map(existingIncident => action.incident.id === existingIncident.id
          ? action.incident
          : existingIncident),
      };
    case IncidentsActionType.SynchroniseIncidents:
      return {
        ...state,
        incidents: action.incidents,
        isLoading: false,
      };
    case IncidentsActionType.ResetIncidents:
      return initialState;
    default:
      return state;
  }
}
