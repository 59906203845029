import { memo, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Section from '../../../../components/Section';
import SectionHeader from '../../../../components/SectionHeader';
import { useTemplates } from '../../../../providers/Templates';
import TemplatesForm from './TemplatesForm';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  sectionNoPaddingTop: {
    paddingTop: 0,
  },
}));

function Templates() {
  const classes = useStyles();
  const { templates } = useTemplates();

  const patchedTemplates = useMemo(() => ({
    attachments: templates ?? [],
    files: [] as File[],
  }), [templates]);

  return (
    <Box className={classes.root}>
      <Section className={classes.sectionNoPaddingTop}>
        <div>
          <SectionHeader title="Templates" align="left" />
          <TemplatesForm templates={patchedTemplates} />
        </div>
      </Section>
    </Box>
  );
}

export default memo(Templates);
