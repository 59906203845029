import { useState } from 'react';
import { Link as RouterLink, Redirect } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import { Link, Typography, Grid, Button } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import Loading from '../../../../components/Loading';
import { useNotifications } from '../../../../providers/Notifications';
import { forgotPasswordSubmit } from '../../../../services/ApiClient';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  inputTitle: {
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
}));

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
  newPassword: yup
    .string()
    .required('The new password is required.')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/,
      'The new password must have at least 8 characters, with at least one lowercase letter, one uppercase letter and one digit.'
    ),
  confirmNewPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'The passwords should match.')
    .required('The confirm password is required.')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/,
      'The confirm password must have at least 8 characters, with at least one lowercase letter, one uppercase letter and one digit.'
    ),
});

interface Props {
  email: string;
}

export default function ResetPasswordForm({ email }: Props) {
  const classes = useStyles();
  const showAlert = useNotifications();
  const [hasSubmitted, setHasSubmitted] = useState(false);

  if (hasSubmitted) {
    return <Redirect to={`/login`} />;
  }

  return (
    <div className={classes.root}>
      <Formik
        initialValues={
          {
            email,
            code: '',
            newPassword: '',
            confirmNewPassword: '',
          }
        }
        validationSchema={validationSchema}
        onSubmit={
          async ({ email, code, newPassword }) => {
            try {
              const result = await forgotPasswordSubmit(email, code, newPassword);

              showAlert({
                message: 'The password has been reset successfully.',
                severity: 'success',
              });

              console.log('RESULT', result);
              
              setHasSubmitted(true);
            } catch (error) {
              showAlert({
                message: 'Failed to reset the password.',
                severity: 'error',
              });
            }
          }
        }
      >
        {
          ({ submitForm, isSubmitting }) => (
            <>
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle1"
                      color="textPrimary"
                      className={classes.inputTitle}
                    >
                        Code
                    </Typography>
                    <Field
                      component={TextField}
                      placeholder="Code"
                      variant="outlined"
                      size="medium"
                      name="code"
                      type="text"
                      disabled={isSubmitting}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle1"
                      color="textPrimary"
                      className={classes.inputTitle}
                    >
                        New password
                    </Typography>
                    <Field
                      component={TextField}
                      placeholder="New password"
                      variant="outlined"
                      size="medium"
                      name="newPassword"
                      type="password"
                      disabled={isSubmitting}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle1"
                      color="textPrimary"
                      className={classes.inputTitle}
                    >
                        Confirm new password
                    </Typography>
                    <Field
                      component={TextField}
                      placeholder="Confirm new password"
                      variant="outlined"
                      size="medium"
                      name="confirmNewPassword"
                      type="password"
                      disabled={isSubmitting}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      size="large"
                      variant="contained"
                      type="button"
                      color="primary"
                      fullWidth
                      disabled={isSubmitting}
                      onClick={submitForm}
                    >
                      Reset Password
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle1"
                      color="textSecondary"
                      align="left"
                    >
                      Already have an account?
                      {' '}
                      <Link component={RouterLink} to="/login">
                        Log in.
                      </Link>
                      .
                    </Typography>
                  </Grid>
                </Grid>
              </Form>
              {
                isSubmitting && (
                  <Loading fullScreen />
                )
              }
            </>
          )
        }
      </Formik>
    </div>
  );
}
