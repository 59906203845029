import { PropsWithChildren, useEffect, useState } from 'react';
import Context from './Context';
import { useAuth } from '../Auth';

const initialState = {
  isLoading: true,
  partNumbers: [] as { id: string; description: string; }[],
};

export default function Provider({
  persistKey = 'partNumber',
  children,
}: PropsWithChildren<{ persistKey?: string }>) {
  const { auth } = useAuth();

  const data = localStorage.getItem(persistKey);
  const json = data && JSON.parse(data);

  const [partNumbers, setPartNumbers] = useState<typeof initialState>(() => json || initialState);

  useEffect(() => {
    try {
      if (auth.isAuthenticated) {
        localStorage.setItem(persistKey, JSON.stringify(partNumbers));
      }
    } catch (error) {
      console.warn(error);
    }
  }, [auth.isAuthenticated, partNumbers, persistKey]);

  useEffect(() => {
    if (!auth.isAuthenticated) {
      try {
        localStorage.removeItem(persistKey);
      } catch (error) {
        console.warn(error);
      }
      setPartNumbers(initialState);
    }
  }, [auth.isAuthenticated, persistKey]);

  const updatePartNumbers = (partNumbers: (typeof initialState)['partNumbers']) => {
    setPartNumbers(state => ({
      ...state,
      partNumbers,
      isLoading: false,
    }));

    // updateProfileServer(newProfile);
  }

  const [partNumberDescriptionMap] = useState(new Map(partNumbers.partNumbers.map(partNumber => [
    partNumber.id,
    partNumber.description,
  ])));

  return (
    <Context.Provider value={{ ...partNumbers, partNumberDescriptionMap, updatePartNumbers }}>
      {children}
    </Context.Provider>
  );
}
