import React, { memo, useCallback, useMemo, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import { useHistory, useLocation } from 'react-router';
import Tooltip from '../Tooltip';
import { applyFilter } from '../ValueFilter';
import { useUsers } from '../../providers/Users';
import { useInvestigations } from '../../providers/Investigations';
import { DecoratedData, Data, HeadCell } from './types';
import IncidentRow from './IncidentRow';
import FilterMenu from './FilterMenu';
import ExportMenu from './ExportMenu';
import FilterCell from './FilterCell';
import SearchBar from './SearchBar';

type FieldFilter = { id: keyof Partial<DecoratedData>, selected: boolean };

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof DecoratedData>(
  order: Order,
  orderBy: Key,
): (a: DecoratedData, b: DecoratedData) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells: HeadCell[] = [
  { id: 'incidentNumber', numeric: true, disablePadding: true, label: 'Incident Number' },
  { id: 'reportedBy', numeric: false, disablePadding: false, label: 'Reported By' },
  { id: 'incidentDate', numeric: false, disablePadding: false, label: 'Incident Date' },
  { id: 'product', numeric: false, disablePadding: false, label: 'Product' },
  { id: 'partNumber', numeric: true, disablePadding: false, label: 'Part Number' },
  { id: 'defectiveQuantity', numeric: true, disablePadding: false, label: 'Defective Quantity' },
  { id: 'grnNumber', numeric: true, disablePadding: false, label: 'GRN Number' },
  { id: 'orderNumber', numeric: true, disablePadding: false, label: 'Order Number' },
  { id: 'incidentLocation', numeric: false, disablePadding: false, label: 'Incident Location' },
  { id: 'category', numeric: false, disablePadding: false, label: 'Category' },
  { id: 'subcategory', numeric: false, disablePadding: false, label: 'Subcategory Level 1' },
  { id: 'furtherSubcategory', numeric: false, disablePadding: false, label: 'Subcategory Level 2' },
  { id: 'description', numeric: false, disablePadding: false, label: 'Description' },
  { id: 'investigationId', numeric: false, disablePadding: false, label: 'Investigation' },
  { id: 'incidentJustified', numeric: false, disablePadding: false, label: 'Justified' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
  { id: 'created_at', numeric: false, disablePadding: false, label: 'Created at' },
  { id: 'updated_at', numeric: false, disablePadding: false, label: 'Updated at' },
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof DecoratedData) => void;
  order: Order;
  orderBy: string;
  headCells: HeadCell[];
  rows: DecoratedData[];
  onUpdate: (items: Item[]) => unknown;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    classes,
    order,
    orderBy,
    onRequestSort,
    headCells,
    rows,
    onUpdate,
  } = props;

  const createSortHandler = (property: keyof DecoratedData) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  const getMinWidth = (id: string) => {
    switch (id) {
      case 'incidentNumber':
        return 100;
      case 'product':
        return 200;
      case 'reportedBy':
        return 200;
      case 'incidentLocation':
        return 240;
      case 'category':
        return 200;
      case 'subcategory':
        return 240;
      case 'furtherSubcategory':
        return 240;
      case 'description':
        return 350;
      case 'investigationId':
        return 220;
      case 'created_at':
      case 'updated_at':
        return 180;
      default:
        return 200;
    }
  };

  const handleUpdate = (newHeadCell: any) => {
    onUpdate(headCells.map(headCell => headCell.id === newHeadCell.id ? newHeadCell : headCell));
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell style={{ minWidth: 140 }} />
        {
          headCells.map(headCell => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === headCell.id ? order : false}
              style={{ minWidth: getMinWidth(headCell.id) }}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {
                  orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null
                }
              </TableSortLabel>
              <FilterCell
                cell={headCell}
                data={rows}
                id={headCell.id}
                onUpdate={handleUpdate}
              />
            </TableCell>
          ))
        }
      </TableRow>
    </TableHead>
  );
}

const MemoisedEnhancedTableHead = memo(EnhancedTableHead);

interface TableFooterProps {
  count: number;
  rowsPerPage: number;
  page: number;
  onChangePage: (_: unknown, newPage: number) => unknown;
  onChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => unknown;
}

function TableFooter({
  count,
  rowsPerPage,
  page,
  onChangePage,
  onChangeRowsPerPage,
}: TableFooterProps) {
  return (
    <TablePagination
      rowsPerPageOptions={[50, 100, 200]}
      component="div"
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
    />
  );
}

const MemoisedTableFooter = memo(TableFooter);

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    search: {
      flex: '1 1 100%',
      boxShadow: 'unset',
    },
  }),
);

interface Item {
  id: string;
  label: string;
  selected: boolean;
}

function EnhancedTableToolbar({
  data,
  filter,
  headCells,
  onUpdate,
  onReset,
  onUpdateSearch,
}: {
  data: DecoratedData[];
  filter: HeadCell[];
  headCells: Item[];
  onUpdate: (items: Item[]) => unknown;
  onReset: () => unknown;
  onUpdateSearch: (value: string) => unknown;
}) {
  const classes = useToolbarStyles();

  return (
    <Toolbar
      className={classes.root}
    >
      <SearchBar
        className={classes.search}
        onUpdate={onUpdateSearch}
      />
      <Tooltip
        title="Reset filters"
        placement="top-start"
      >
        <IconButton
          aria-label="reset"
          aria-controls="reset"
          aria-haspopup="true"
          onClick={onReset}
          color="inherit"
        >
          <ClearAllIcon />
        </IconButton>
      </Tooltip>
      <FilterMenu initialItems={headCells} onUpdate={onUpdate} />
      <ExportMenu data={data} filter={filter} />
    </Toolbar>
  );
};

const MemoisedEnhancedTableToolbar = memo(EnhancedTableToolbar);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }),
);

interface Props {
  rows: Data[];
  fieldFilter: FieldFilter[];
  onUpdate: (items: FieldFilter[]) => unknown;
  onReset: () => unknown;
  initialPage?: number;
  initialRowsPerPage?: number;
}

export default function IncidentTable({
  rows,
  fieldFilter,
  onUpdate,
  onReset,
  initialPage,
  initialRowsPerPage,
}: Props) {
  const classes = useStyles();
  const [orderBy, setOrderBy] = useState<{ order: Order; field: keyof DecoratedData }>({
    order: 'desc',
    field: 'incidentNumber',
  });
  const history = useHistory();
  const location = useLocation();
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(initialPage ?? 0);
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage ?? 100);
  const { userMap } = useUsers();
  const { investigationMap } = useInvestigations();

  const matchingRows = useMemo<Data[]>(() => {
    if (query === '') {
      return rows;
    }

    return rows.filter(row => {
      return Object.entries(row).some(([key, value]) => {
        if (key === 'reportedBy') {
          const user = userMap.get(value);

          return !!user && user.name.includes(query);
        } else if (typeof value === 'string') {
          return value.includes(query);
        } else if (typeof value === 'object') {
          return value !== null && !!value?.title?.includes(query);
        } else if (key === 'investigationId') {
          const investigation = investigationMap.get(value);

          return !!investigation && Object.entries(investigation).some(([key, value]) => {
            if (typeof value === 'string') {
              return value.includes(query);
            }

            return false;
          });
        }

        return false;
      });
    });
  }, [rows, investigationMap, query]);

  const decoratedRows = useMemo<DecoratedData[]>(() => matchingRows.map(row => {
    const investigation = investigationMap.get(row.investigationId as string);

    return {
      ...row,
      incidentJustified: !investigation || investigation.incidentJustified !== false,
    };
  }), [matchingRows, investigationMap]);

  const sortedRows = useMemo(() => {
    return stableSort<DecoratedData>(decoratedRows, getComparator(orderBy.order, orderBy.field))
  }, [decoratedRows, orderBy]);

  const filteredRows = useMemo(() => {
    if (sortedRows.length === 0) {
      return sortedRows;
    }

    return (fieldFilter as any).reduce(
      (incidents: any, filter: any) => filter.filter ? applyFilter(incidents, filter.filter, investigationMap) : incidents,
      sortedRows,
    ) as DecoratedData[];
  }, [sortedRows, fieldFilter, investigationMap]);

  const [filteredHeadCells, allHeadCells] = useMemo(
    () => {
      const headCellMap = new Map<string, HeadCell>(headCells.map(headCell => [headCell.id, headCell]));

      const allHeadCells = fieldFilter.map(field => ({
        ...field,
        ...headCellMap.get(field.id)!,
      }));

      return [
        allHeadCells.filter(headCell => headCell.selected),
        allHeadCells,
      ];
    },
    [fieldFilter],
  );

  const handleRequestSort = useCallback((_: React.MouseEvent<unknown>, property: keyof DecoratedData) => {
    setOrderBy(previousOrderBy => {
      const isAsc = previousOrderBy.field === property && previousOrderBy.order === 'asc';
      return {
        order: isAsc ? 'desc' : 'asc',
        field: property,
      };
    })
  }, [setOrderBy]);

  const handleChangePage = useCallback((_: unknown, newPage: number) => {
    setPage(newPage);

    const params = new URLSearchParams({
      page: `${newPage}`,
      rowsPerPage: `${rowsPerPage}`,
    });
    history.replace({ pathname: location.pathname, search: params.toString() });
  }, [history, location.pathname, rowsPerPage]);

  const handleChangeRowsPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(rowsPerPage);
    setPage(0);

    const params = new URLSearchParams({
      page: '0',
      rowsPerPage: `${rowsPerPage}`,
    });
    history.replace({ pathname: location.pathname, search: params.toString() });
  }, [history, location.pathname]);

  const handleUpdateSearch = useCallback((value: string) => {
    setQuery(value);
  }, []);

  return (
    <div className={classes.root}>
      <Paper variant="outlined" className={classes.paper}>
        <MemoisedEnhancedTableToolbar
          data={sortedRows}
          filter={filteredHeadCells}
          headCells={allHeadCells}
          onUpdate={onUpdate as any}
          onReset={onReset}
          onUpdateSearch={handleUpdateSearch}
        />
        <TableContainer style={{ maxHeight: 800 }}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="small"
            aria-label="enhanced table"
            stickyHeader
          >
            <MemoisedEnhancedTableHead
              classes={classes}
              order={orderBy.order}
              orderBy={orderBy.field}
              onRequestSort={handleRequestSort}
              headCells={filteredHeadCells}
              rows={sortedRows}
              onUpdate={onUpdate as any}
            />
            <TableBody>
              {
                filteredRows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(row => (
                    <IncidentRow
                      key={row.id}
                      row={row}
                      filteredHeadCells={filteredHeadCells}
                    />
                  ))
              }
            </TableBody>
          </Table>
        </TableContainer>
        <MemoisedTableFooter
          count={sortedRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}