import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DashboardBreadcrums from '../../components/DashboardBreadcrums';
import Section from '../../components/Section';
import Modules from './components/Modules';
import DraftIncidents from './components/DraftIncidents';

const useStyles = makeStyles(theme => {
  return ({
    pagePaddingTop: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      [theme.breakpoints.up('md')]: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
      },
    },
    sectionNoPaddingTop: {
      paddingTop: 0,
    },
  });
});

export default function Home() {
  const classes = useStyles();

  return (
    <div>
      <Section className={classes.pagePaddingTop}>
        <DashboardBreadcrums
          breadcrums={[
            { name: 'Home', path: '/' },
          ]}
        />
      </Section>
      <Section className={classes.sectionNoPaddingTop}>
        <Modules />
      </Section>
      <Section className={classes.sectionNoPaddingTop}>
        <DraftIncidents />
      </Section>
    </div>
  );
}
