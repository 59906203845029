import { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Section from '../../../../components/Section';
import SectionHeader from '../../../../components/SectionHeader';
import { useProducts } from '../../../../providers/Products';
import ProductTable from './ProductTable';
import ProductsFab from './ProductsFab';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  pagePaddingTop: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
  },
  sectionNoPaddingTop: {
    paddingTop: 0,
  },
}));

function Products() {
  const classes = useStyles();
  const { products } = useProducts();

  return (
    <Box className={classes.root}>
      <Section className={classes.sectionNoPaddingTop}>
        <div>
          <SectionHeader title="Products" align="left" />
          <ProductTable rows={products} />
          <ProductsFab />
        </div>
      </Section>
    </Box>
  );
}

export default memo(Products);
