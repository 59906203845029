import { forwardRef, memo } from 'react';
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Line,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Text,
} from 'recharts';

interface LabelProps {
  x: number;
  y: number;
  width: number;
  value: number;
}

function CustomLabel({ x, y, width, value }: LabelProps) {
  return (
    <Text 
      x={x} 
      y={y}
      dx={width / 2}
      dy={-10}
      fill="#000000"
      textAnchor="middle"
    >
      {value}
    </Text>
  );
}

function CustomLegendText(value: string) {  
  return <span style={{ color: '#000000' }}>{value}</span>;
}

interface AxisTickProps {
  x: number;
  y: number;
  payload: { value: number; };
}

function CustomAxisTick({ x, y, payload }: AxisTickProps) {
  return (
    <Text x={x} y={y} width={75} textAnchor="middle" verticalAnchor="start">
      {payload.value}
    </Text>
  );
}


function CustomAxisTickAlternative({ x, y, payload }: AxisTickProps) {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} width={50} textAnchor="end" fill="#666" transform="rotate(-35)">
        {payload.value}
      </text>
    </g>
  );
}

interface Props {
  data: {
    title: string;
    xLabel: string;
    yLabel1: string;
    yLabel2: string;
    values: {
      name: string;
      value1: number;
      value2: number;
    }[];
  };
}

export default memo(forwardRef(({ data }: Props, ref: any) => {
  return (
    <ResponsiveContainer width="99%" aspect={2}>
      <ComposedChart
        ref={ref}
        data={data.values}
        margin={{ top: 25, right: 25, left: 25, bottom: 25 }}
      >
        <CartesianGrid vertical={false} />
        <XAxis
          dataKey="name"
          name={data.xLabel}
          tick={data.values.length > 8 ? CustomAxisTickAlternative : CustomAxisTick}
          height={100}
          interval={0}
        />
        <YAxis orientation="left" yAxisId="left" dataKey="value1" name={data.yLabel1} />
        <YAxis orientation="right" yAxisId="right" dataKey="value2" name={data.yLabel2 + '2'} />
        <Tooltip />
        <Bar
          yAxisId="left"
          fill="rgb(17, 82, 147)"
          dataKey="value1"
          name={data.yLabel1}
          label={CustomLabel}
        />
        <Line
          yAxisId="right"
          stroke="rgb(76, 166, 50)"
          fill="rgb(76, 166, 50)"
          dataKey="value2"
          name={data.yLabel2}
          strokeWidth={5}
        />
        <Legend
          formatter={CustomLegendText}
          layout="vertical"
          verticalAlign="bottom"
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}));
