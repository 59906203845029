import { useContext } from 'react';
import Context from './Context';
export { default } from './Provider';

export function useAuth() {
  const auth = useContext(Context);

  if (!auth) {
    throw new Error('Failed to find auth context.');
  }

  return auth;
}
