import { PropsWithChildren } from 'react';
import { Link as RouterLink } from 'react-router-dom'
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, CardActionArea } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
  },
  withShadow: {
    boxShadow: `0 2px 10px 0 ${theme.palette.cardShadow}`,
  },
  noShadow: {
    boxShadow: 'none',
  },
  noBorder: {
    border: 0,
  },
  noBg: {
    background: 'transparent',
  },
  liftUp: {
    transition:
      'box-shadow .25s ease,transform .25s ease,-webkit-transform .25s ease',
    '&:hover': {
      boxShadow:
        '0 1.5rem 2.5rem rgba(22,28,45,.1),0 .3rem 0.5rem -.50rem rgba(22,28,45,.05) !important',
      transform: 'translate3d(0,-5px,0)',
    },
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(4, 2),
    '&:last-child': {
      padding: theme.spacing(4, 2),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(6, 3),
      '&:last-child': {
        padding: theme.spacing(6, 3),
      },
    },
  },
  left: {
    alignItems: 'flex-start',
  },
  right: {
    alignItems: 'flex-end',
  },
  center: {
    alignItems: 'center',
  },
}));

interface Props {
  className?: string;
  withShadow?: boolean;
  noShadow?: boolean;
  noBorder?: boolean;
  noBg?: boolean;
  liftUp?: boolean;
  align?: 'left' | 'right' | 'center';
  cardContentProps?: object;
  to?: string;
}

export default function CardBase({
  withShadow,
  noShadow,
  noBorder,
  noBg,
  liftUp,
  children,
  align = 'center',
  className,
  cardContentProps = {},
  to,
}: PropsWithChildren<Props>) {
  const classes = useStyles();

  return (
    <Card
      className={clsx(
        classes.root,
        withShadow ? classes.withShadow : {},
        noShadow ? classes.noShadow : {},
        noBorder ? classes.noBorder : {},
        noBg ? classes.noBg : {},
        liftUp ? classes.liftUp : {},
        className,
      )}
    >
      {
        to === undefined ? (
          <CardContent
            className={clsx(classes.content, classes[align])}
            {...cardContentProps}
          >
            {children}
          </CardContent>
        ) : (
          <CardActionArea component={RouterLink} to={to}> 
            <CardContent
              className={clsx(classes.content, classes[align])}
              {...cardContentProps}
            >
              {children}
            </CardContent>
          </CardActionArea>
        )
      }
    </Card>
  );
}
