import { AuthActionType, AuthStateType } from './types';

export function setAuth(auth: AuthStateType) {
  return {
    type: AuthActionType.SetAuth, 
    auth,
  };
}

export function updateAuth(auth: AuthStateType) {
  return {
    type: AuthActionType.UpdateAuth, 
    auth,
  };
}
