import { useContext } from 'react';
import Context from './Context';
export { default } from './Provider';

export function useIncidents() {
  const incidents = useContext(Context);

  if (!incidents) {
    throw new Error('Failed to find incidents context.');
  }

  return incidents;
}
