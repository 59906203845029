import React from 'react';
import { AuthStateType } from './store/types';

type ContextType = null | {
  auth: AuthStateType;
  setAuth: (auth: AuthStateType) => unknown;
  updateAuth: (auth: AuthStateType) => unknown;
};

export const Context = React.createContext<ContextType>(null);

export default Context;
