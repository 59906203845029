import { MenuStateType, MenuActionType, MenuActionTypes } from './types';

export default function reducer(state: MenuStateType, action: MenuActionTypes) {
  const { type, payload } = action;

  switch (type) {
    case MenuActionType.SetMiniMode:
      return { ...state, miniMode: payload };
    case MenuActionType.SetMenuOpen:
      return { ...state, menuOpen: payload };
    case MenuActionType.SetMobileMenuOpen:
      return { ...state, mobileMenuOpen: payload };
    case MenuActionType.SetMiniSwitchVisibility:
      return { ...state, miniSwitchVisibility: payload };
    default:
      return state;
  }
}
