import React from 'react';
import { UsersStateType, UserType } from './store/types';

type ContextType = null | {
  users: UsersStateType;
  setUsers: (users: UserType[]) => unknown;
  updateUser: (user: UserType) => unknown;
  addUser: (user: UserType) => unknown;
  userMap: Map<string, UserType>,
};

export const Context = React.createContext<ContextType>(null);

export default Context;
