import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { LoginForm } from './components';
import Image from '../../components/Image';
import SectionHeader from '../../components/SectionHeader';
import HeroShaped from '../../components/HeroShaped';

const useStyles = makeStyles(theme => {
  const toolbar = theme.mixins.toolbar as any;

  return ({
    root: {
      '& .hero-shaped': {
        borderBottom: 0,
      },
      '& .hero-shaped__wrapper': {
        [theme.breakpoints.up('md')]: {
          minHeight: `calc(100vh - ${toolbar['@media (min-width:600px)'].minHeight}px)`,
        },
      },
    },
    formContainer: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        maxWidth: 500,
        margin: `0 auto`,
      },
    },
    image: {
      objectFit: 'cover',
    },
  });
});

export default function Login() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <HeroShaped
        leftSide={
          <div className={classes.formContainer}>
            <SectionHeader
              title="Login"
              subtitle="Please enter your credentials."
              subtitleProps={{
                variant: 'body1',
                color: 'textPrimary',
              }}
              align="left"
            />
            <LoginForm />
          </div>
        }
        rightSide={
          <Image
            src="https://source.unsplash.com/random"
            className={classes.image}
            lazy={false}
          />
        }
      />
    </div>
  );
}
