import { makeStyles } from '@material-ui/core/styles';
import DashboardBreadcrums from '../../components/DashboardBreadcrums';
import Section from '../../components/Section';
import SectionHeader from '../../components/SectionHeader';
import { ReportIncidentForm } from './components';

const useStyles = makeStyles(theme => {
  return ({
    pagePaddingTop: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      [theme.breakpoints.up('md')]: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
      },
    }, 
    sectionNoPaddingTop: {
      paddingTop: 0,
    },
  });
});

export default function ReportIncident() {
  const classes = useStyles();

  return (
    <div>
      <Section className={classes.pagePaddingTop}>
        <DashboardBreadcrums
          breadcrums={[
            { name: 'Home', path: '/' },
            { name: 'Incidents', path: '/incidents' },
            { name: 'Report Incident', path: '/incident/new' },
          ]}
        />
      </Section>
      <Section className={classes.sectionNoPaddingTop}>
        <div>
          <SectionHeader
            title="Report an Incident"
            subtitle="Please make sure to fill all mandatory fields marked by *."
            subtitleProps={{
              variant: 'body1',
              color: 'textPrimary',
            }}
            align="left"
          />
          <ReportIncidentForm />
        </div>
      </Section>
    </div>
  );
}
