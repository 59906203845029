import React from 'react';
import { InvestigationsStateType, InvestigationType } from './store/types';

type ContextType = null | {
  investigations: InvestigationsStateType;
  addInvestigation: (investigation: InvestigationType) => unknown;
  updateInvestigation: (investigation: InvestigationType) => unknown;
  synchroniseInvestigations: (investigations: InvestigationType[]) => unknown;
  investigationMap: Map<string, InvestigationType>,
};

export const Context = React.createContext<ContextType>(null);

export default Context;
