import { AuthStateType, AuthActionType, AuthActionTypes } from './types';

export default function reducer(state: AuthStateType = {
  isAuthenticated: false,
}, action: AuthActionTypes) {
  const { type, auth } = action;

  switch (type) {
    case AuthActionType.SetAuth:
      return auth;
    case AuthActionType.UpdateAuth:
      return { ...state, ...auth };
    default:
      return state;
  }
}
