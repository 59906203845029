import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chipDraft: {
      backgroundColor: theme.palette.grey[900],
      color: theme.palette.common.white,
    },
    chipSubmitted: {
      backgroundColor: theme.palette.warning.light,
      color: theme.palette.warning.contrastText,
    },
    chipAckowledged: {
      backgroundColor: theme.palette.info.dark,
      color: theme.palette.info.contrastText,
    },
    chipPending: {
      backgroundColor: theme.palette.error.dark,
      color: theme.palette.error.contrastText,
    },
    chipInvestigation: {
      backgroundColor: theme.palette.info.light,
      color: theme.palette.info.contrastText,
    },
    chipRejected: {
      backgroundColor: theme.palette.error.light,
      color: theme.palette.error.contrastText,
    },
    chipCompleted: {
      backgroundColor: theme.palette.success.dark,
      color: theme.palette.success.contrastText,
    },
    chipClosed: {
      backgroundColor: theme.palette.success.light,
      color: theme.palette.success.contrastText,
    },
  }),
);

interface Props {
  value: string;
}

export default function IncidentStatus({ value }: Props) {
  const classes = useStyles();

  switch (value) {
    case 'draft':
      return <Chip label="Draft" className={classes.chipDraft} />;
    case 'cancelled':
      return <Chip label="Cancelled" />;
    case 'submitted':
      return <Chip label="Submitted" className={classes.chipSubmitted} />;
    case 'pending-update':
      return <Chip label="Pending Update" className={classes.chipPending} />;
    case 'acknowledged':
      return <Chip label="Acknowledged" className={classes.chipAckowledged} />;
    case 'rejected':
      return <Chip label="Rejected" className={classes.chipRejected} />;
    case 'under-investigation':
      return <Chip label="Under Investigation" className={classes.chipInvestigation} />;
    case 'investigation-completed':
      return <Chip label="Investigation Completed" className={classes.chipCompleted} />;
    case 'closed':
      return <Chip label="Closed" className={classes.chipClosed} />;
  }

  return null;
}
