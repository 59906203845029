import React from 'react';

type Profile = {
  notifications: {
    submitted: boolean;
    acknowledged: boolean;
    rejected: boolean;
    'pending-update': boolean;
    closed: boolean;
  };
  draftIncidentsFilter?: { id: string, selected: boolean }[];
  incidentsFilter?: { id: string, selected: boolean }[];
};

type ContextType = {
  isLoading: boolean;
  profile: Profile;
  updateProfile: (profile: Profile, skipUpdate?: boolean) => unknown;
};

export const Context = React.createContext<ContextType>({
  isLoading: true,
  profile: {
    notifications: {
      submitted: true,
      acknowledged: true,
      rejected: true,
      'pending-update': true,
      closed: true,
    },
  },
  updateProfile: () => {},
});

export default Context;
