import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import { Link, Typography, Grid, Button } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import Loading from '../../../../components/Loading';
import { forgotPassword } from '../../../../services/ApiClient';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  inputTitle: {
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
}));

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
});

interface Props {
  onForgotPasswordSubmit: (email: string, destination: string) => unknown;
}

export default function ResetPasswordForm({ onForgotPasswordSubmit }: Props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Formik
        initialValues={
          {
            email: '',
          }
        }
        validationSchema={validationSchema}
        onSubmit={
          async ({ email }) => {
            const result = await forgotPassword(email);

            const destination = result.CodeDeliveryDetails.Destination;
        
            onForgotPasswordSubmit(email, destination);
          }
        }
      >
        {
          ({ submitForm, isSubmitting }) => (
            <>
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle1"
                      color="textPrimary"
                      className={classes.inputTitle}
                    >
                      Email
                    </Typography>
                    <Field
                      component={TextField}
                      placeholder="Email"
                      variant="outlined"
                      size="medium"
                      name="email"
                      type="email"
                      disabled={isSubmitting}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      size="large"
                      variant="contained"
                      type="button"
                      color="primary"
                      fullWidth
                      disabled={isSubmitting}
                      onClick={submitForm}
                    >
                      Request Password
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle1"
                      color="textSecondary"
                      align="left"
                    >
                      Already have an account?
                      {' '}
                      <Link component={RouterLink} to="/login">
                        Log in.
                      </Link>
                      .
                    </Typography>
                  </Grid>
                </Grid>
              </Form>
              {
                isSubmitting && (
                  <Loading fullScreen />
                )
              }
            </>
          )
        }
      </Formik>
    </div>
  );
}
