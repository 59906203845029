export enum MenuActionType {
  SetMiniMode = 'SET_MINI_MODE',
  SetMenuOpen = 'SET_MENU_OPEN',
  SetMobileMenuOpen = 'SET_MOBILE_MENU_OPEN',
  SetMiniSwitchVisibility = 'SET_MINI_SWITCH_VISIBILITY',
};

export interface MenuActionTypes {
  type: MenuActionType;
  payload: boolean;
}

export interface MenuStateType {
  miniMode: boolean;
  menuOpen: boolean;
  mobileMenuOpen: boolean;
  miniSwitchVisibility: boolean;
}
