import { memo, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import Loading from '../Loading';
import { useNotifications } from '../../providers/Notifications';
import { useUsers } from '../../providers/Users'
import type { UserType } from '../../providers/Users/store/types';
import * as ApiClient from '../../services/ApiClient';
import EditStatusDialog from './EditStatusDialog';
import EditRoleDialog from './EditRoleDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 0,
      flexGrow: 1,
    },
    speedDial: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    tooltip: {
      fontSize: theme.typography.pxToRem(15),
    },
  }),
);

const useTooltipStyles = makeStyles((theme: Theme) =>
  createStyles({
    tooltip: {
      fontSize: theme.typography.pxToRem(15),
    },
  }),
);

interface Props {
  user: UserType;
}

function UserSpeedDial({ user }: Props) {
  const classes = useStyles();
  const tooltipClasses = useTooltipStyles();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);  
  const [showEdit, setShowEdit] = useState(false);
  const [showRole, setShowRole] = useState(false);
  const showAlert = useNotifications();
  const { updateUser } = useUsers();

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleUpdateUser = async (user: UserType) => {
    try {  
      const result = await ApiClient.updateUser(user);

      showAlert({
        message: 'User updated successfully.',
        severity: 'success',
      });

      updateUser(result);
    } catch (error) {
      showAlert({
        message: 'Failed to update the user.',
        severity: 'error',
      });
    }
  }

  return (
    <div className={classes.root}>
      <SpeedDial
        ariaLabel="User actions"
        className={classes.speedDial}
        icon={<MoreVertIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        direction="up"
      >
        <SpeedDialAction
          TooltipClasses={tooltipClasses}
          icon={<SupervisorAccountIcon />}
          tooltipTitle="Update Role"
          onClick={() => setShowRole(true)}
        />
        <SpeedDialAction
          TooltipClasses={tooltipClasses}
          icon={<EditIcon />}
          tooltipTitle="Update Status"
          onClick={() => setShowEdit(true)}
        />
      </SpeedDial>
      {
        loading && (
          <Loading fullScreen />
        )
      }
      <EditStatusDialog
        open={showEdit}
        initialUser={user}
        onCancel={() => setShowEdit(false)}
        onConfirm={async (active: boolean) => {
          setShowEdit(false)
          setLoading(true);
          await handleUpdateUser({ ...user, active });
          setLoading(false);
        }}
      />
      <EditRoleDialog
        open={showRole}
        initialUser={user}
        onCancel={() => setShowRole(false)}
        onConfirm={async (role: string) => {
          setShowRole(false)
          setLoading(true);
          await handleUpdateUser({ ...user, role });
          setLoading(false);
        }}
      />
    </div>
  );
}

export default memo(UserSpeedDial);