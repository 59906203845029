import { memo, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '../../../../components/Tooltip';
import Loading from '../../../../components/Loading';
import { useUsers } from '../../../../providers/Users';
import { useNotifications } from '../../../../providers/Notifications';
import * as ApiClient from '../../../../services/ApiClient';
import AddUserDialog from '../AddUserDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 0,
      flexGrow: 1,
    },
    fab: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  }),
);

function UsersFab() {
  const classes = useStyles();
  const showAlert = useNotifications();
  const [showAdd, setShowAdd] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { addUser } = useUsers();

  return (
    <>
      <div className={classes.root}>
        <Tooltip
          title="Add User"
          placement="top-start"
        >
          <Fab
            color="primary"
            aria-label="Add"
            className={classes.fab}
            onClick={() => setShowAdd(true)}
          >
            <AddIcon />
          </Fab>
        </Tooltip>
      </div>
      {
        isLoading && (
          <Loading fullScreen />
        )
      }
      <AddUserDialog
        open={showAdd}
        onConfirm={async (user: { name: string; email: string; }) => {
          setShowAdd(false);
          setIsLoading(true);

          try {
            const result = await ApiClient.addUser(user);

            addUser(result);

            showAlert({
              message: 'Added user successfully.',
              severity: 'success',
            });
          } catch (error) {
            showAlert({
              message: 'Failed to add user.',
              severity: 'error',
            });
          }

          setIsLoading(false);
        }}
        onCancel={() => setShowAdd(false)}
      />
    </>
  );
}

export default memo(UsersFab);
