import { API, Auth, Storage } from 'aws-amplify';

const {
  REACT_APP_AUTH_REGION,
  REACT_APP_AUTH_USER_POOL_ID,
  REACT_APP_AUTH_IDENTITY_POOL_ID,
  REACT_APP_AUTH_APP_CLIENT_ID,
  REACT_APP_API_NAME,
  REACT_APP_API_ENDPOINT,
  REACT_APP_STORAGE_REGION,
  REACT_APP_STORAGE_BUCKET,
  REACT_APP_STORAGE_IDENTITY_POOL_ID,
} = process.env

export function initialise() {
  Auth.configure({
    Auth: {
      mandatorySignIn: true,
      region: REACT_APP_AUTH_REGION,
      userPoolId: REACT_APP_AUTH_USER_POOL_ID,
      identityPoolId: REACT_APP_AUTH_IDENTITY_POOL_ID,
      userPoolWebClientId: REACT_APP_AUTH_APP_CLIENT_ID,
    },
  });
}

export async function configure() {
  const awsConfiguration = {
    API: {
      endpoints: [
        {
          name: REACT_APP_API_NAME,
          endpoint: REACT_APP_API_ENDPOINT,
          custom_header: async () => {
            const currentUser = await Auth.currentSession();
            return {
              Authorization: currentUser.getIdToken().getJwtToken(),
            };
          },
        },
      ],
    },
    Storage: {
      region: REACT_APP_STORAGE_REGION,
      bucket: REACT_APP_STORAGE_BUCKET,
      identityPoolId: REACT_APP_STORAGE_IDENTITY_POOL_ID
    },
  };

  await API.configure(awsConfiguration)
  await Storage.configure(awsConfiguration)
}

export async function login(email: string, password: string) {
  return Auth.signIn(email, password);
}

export async function logout() {
  sessionStorage.clear();
  return Auth.signOut();
}

export async function completeNewPassword(user: any, password: string) {
  return Auth.completeNewPassword(user, password, user.challengeParam.requiredAttributes);
}

export async function forgotPassword(email: string) {
  return Auth.forgotPassword(email);
}

export async function forgotPasswordSubmit(email: string, code: string, newPassword: string) {
  return Auth.forgotPasswordSubmit(email, code, newPassword);
}

export async function changePassword(password: string, newPassword: string) {
  return Auth.currentAuthenticatedUser()
    .then(user => Auth.changePassword(user, password, newPassword));
}

export async function currentAuthenticatedUser() {
  return Auth.currentAuthenticatedUser();
}

export async function loadUsers() {
  try {
    await configure();

    return await API.get(
      REACT_APP_API_NAME,
      '/v1/users',
      {},
    );
  } catch (error) {
    console.log('error', error)
    return null
  }
}

type UserType = {
  user_id: string;
  name: string;
  email: string;
  role: string;
  active: boolean;
}

export async function updateUser(user: UserType) {
  await configure();

  const result = API.put(
    REACT_APP_API_NAME,
    `/v1/users/${user.user_id}`,
    { body: user },
  );

  return result;
}

export async function addUser({ email, name }: { email: string; name: string; }) {
  await configure();

  const result = API.post(
    REACT_APP_API_NAME,
    '/v1/users',
    { body: { email, name } },
  );

  return result;
}

type LegacyIncidentType = {
  id: string;
  incidentNumber: string | null;
  reportedBy: string;
  incidentDate: string;
  product: string | null;
  assemblyPartNumber: string | null;
  assemblyDefectiveQuantity: string | null;
  assemblyGrnNumber: string;
  assemblyOrderNumber: string;
  componentPartNumber?: string | null;
  componentDefectiveQuantity?: string | null;
  componentGrnNumber?: string;
  componentOrderNumber?: string;
  incidentLocation: { id: string; title: string; } | null;
  category: { id: string; title: string; } | null;
  subcategory: { id: string; title: string; } | null;
  furtherSubcategory: { id: string; title: string; } | null;
  description: string;
  investigationId: string | null;
  attachments: {
    id: string;
    name: string;
    type: string;
    size: number;
    url: string;
  }[];
  status: string;
  created_at: string;
  updated_at: string;
};

type IncidentType = {
  id: string;
  incidentNumber: string | null;
  reportedBy: string;
  incidentDate: string;
  product: string | null;
  partNumber: string | null;
  defectiveQuantity: string | null;
  grnNumber: string;
  orderNumber: string;
  incidentLocation: { id: string; title: string; } | null;
  category: { id: string; title: string; } | null;
  subcategory: { id: string; title: string; } | null;
  furtherSubcategory: { id: string; title: string; } | null;
  description: string;
  investigationId: string | null;
  attachments: {
    id: string;
    name: string;
    type: string;
    size: number;
    url: string;
  }[];
  status: string;
  created_at: string;
  updated_at: string;
};

function convertLegacyIncident({
  componentPartNumber,
  componentDefectiveQuantity,
  componentGrnNumber,
  componentOrderNumber,
  assemblyPartNumber,
  assemblyDefectiveQuantity,
  assemblyGrnNumber,
  assemblyOrderNumber,
  ...incident 
}: LegacyIncidentType): IncidentType {
  return {
    partNumber: assemblyPartNumber,
    defectiveQuantity: assemblyDefectiveQuantity,
    grnNumber: assemblyGrnNumber,
    orderNumber: assemblyOrderNumber,
    ...incident,
  };
}

export async function loadIncidents() {
  try {
    await configure();

    const incidents = await API.get(
      REACT_APP_API_NAME,
      '/v1/incidents',
      {},
    );

    return (incidents || []).map(convertLegacyIncident);
  } catch (error) {
    console.error('error', error)
    return null;
  }
}

export async function createIncident(incident: Omit<IncidentType, 'incidentNumber' | 'created_at' | 'updated_at'>) {
  try {
    await configure();

    const result = API.post(
      REACT_APP_API_NAME,
      '/v1/incidents',
      { body: incident },
    );

    return result;
  } catch (error) {
    console.error('error', error)
    return null;
  }
}

export async function updateIncident(incident: Omit<IncidentType, 'incidentNumber' | 'created_at' | 'updated_at'>) {
  try {
    await configure();

    const result = API.put(
      REACT_APP_API_NAME,
      `/v1/incidents/${incident.id}`,
      { body: incident },
    );

    return result;
  } catch (error) {
    console.error('error', error)
    return null;
  }
}

type InvestigationType = {
  id: string;
  assignedTo: string;
  incidentDescription: string;
  incidentJustified: boolean | null;
  containmentAction: string;
  containmentActionSignedBy: string | null;
  containmentActionSignedDate: string | null;
  rootCause: string;
  rootCauseCategory: { id: string; title: string; } | null;
  rootCauseSignedBy: string | null;
  rootCauseSignedDate: string | null;
  correctiveAction: string;
  correctiveActionSignedBy: string | null;
  correctiveActionSignedDate: string | null;
  verificationOfEffectiveness: string;
  verificationOfEffectivenessSignedBy: string | null;
  verificationOfEffectivenessSignedDate: string | null;
  preventiveAction: string;
  preventiveActionSignedBy: string | null;
  preventiveActionSignedDate: string | null;
  defectiveProductQuantity: string | null;
  additionalNotes: string;
  additionalNotesSignedBy: string | null;
  additionalNotesSignedDate: string | null;
  costOfLabour: number | null;
  costOfMaterial: number | null;
  costAdjustment: number | null;
  completed: boolean;
  completedSignedBy: string | null;
  completedSignedDate: string | null;
  attachments: {
    id: string;
    name: string;
    type: string;
    size: number;
    url: string;
  }[];
  status: string;
  created_at: string;
  updated_at: string;
};

export async function loadInvestigations() {
  try {
    await configure();

    const investigations = await API.get(
      REACT_APP_API_NAME,
      '/v1/investigations',
      {},
    );

    return (investigations || []) as InvestigationType[];
  } catch (error) {
    console.error('error', error)
    return null;
  }
}

export async function createInvestigation(investigation: Omit<InvestigationType, 'created_at' | 'updated_at'>) {
  try {
    await configure();

    const result = API.post(
      REACT_APP_API_NAME,
      '/v1/investigations',
      { body: investigation },
    );

    return result;
  } catch (error) {
    console.error('error', error)
    return null;
  }
}

export async function updateInvestigation(investigation: Omit<InvestigationType, 'created_at' | 'updated_at'>) {
  try {
    await configure();

    const result = API.put(
      REACT_APP_API_NAME,
      `/v1/investigations/${investigation.id}`,
      { body: investigation },
    );

    return result;
  } catch (error) {
    console.error('error', error)
    return null;
  }
}

export async function uploadImage(name: string, file: File) {
  try {
    await configure();

    console.log('name...', name, file);
    return Storage.put(name, file, {
      contentType: file.type,
      level: 'public',
    });
  } catch (error) {
    console.error('error', error)
    return null;
  }
}

export async function loadProfile() {
  try {
    await configure();

    const profile = await API.get(
      REACT_APP_API_NAME,
      '/v1/profile',
      {},
    );

    return profile;
  } catch (error) {
    console.log('error', error)
    return null
  }
}

type Profile = {
  notifications: {
    submitted: boolean;
    acknowledged: boolean;
    rejected: boolean;
    'pending-update': boolean;
    closed: boolean;
  };
};

export async function updateProfile(profile: Profile) {
  try {
    await configure();

    const result = API.put(
      REACT_APP_API_NAME,
      `/v1/profile`,
      { body: profile },
    );

    return result;
  } catch (error) {
    console.error('error', error)
    return null;
  }
}

type Chart = {
  id: string;
  title: string;
  chartType: 'barChart' | 'barChartWithLine' | 'pieChart';
  groupBy: string;
  value1: string;
  value2: string;
  xLabel: string;
  yLabel1: string;
  yLabel2: string;
  filter: any;
  sortBy: 'groupBy' | 'value1' | 'value2';
  limit: any;
  visible: boolean;
}

type Report = {
  id: string;
  charts: Chart[];
}

const legacyMap = {
  assemblyPartNumber: 'partNumber',
  assemblyDefectiveQuantity: 'defectiveQuantity',
  assemblyGrnNumber: 'grnNumber',
  assemblyOrderNumber: 'orderNumber',
  componentPartNumber: 'partNumber',
  componentDefectiveQuantity: 'defectiveQuantity',
  componentGrnNumber: 'grnNumber',
  componentOrderNumber: 'orderNumber',
} as { [key: string]: string };

function convertLegacyReport(report: Report) {
  report.charts.forEach(chart => {
    (['groupBy', 'value1', 'value2'] as const).forEach(key => {
      const value = chart[key];

      if (value in legacyMap) {
        chart[key] = legacyMap[value];
      }
    });

    chart.filter?.forEach((filter: any) => {
      if (filter.key in legacyMap) {
        filter.key = legacyMap[filter.key];
      }
    });
  });
}

export async function loadReports() {
  try {
    await configure();

    const reports = await API.get(
      REACT_APP_API_NAME,
      '/v1/reports',
      {},
    );

    reports.forEach(convertLegacyReport);

    return reports;
  } catch (error) {
    console.log('error', error)
    return null
  }
}

export async function updateReport(report: Report) {
  await configure();

  const result = API.put(
    REACT_APP_API_NAME,
    `/v1/reports/${report.id}`,
    { body: report },
  );

  return result;
}

export async function loadSelf() {
  try {
    await configure();

    const self = await API.get(
      REACT_APP_API_NAME,
      '/v1/self',
      {},
    );

    self.incidents = self.incidents.map(convertLegacyIncident);

    self.reports.forEach(convertLegacyReport);

    return self;
  } catch (error) {
    console.log('error', error)
    return null;
  }
}

export async function createProduct(product: string) {
  await configure();

  const result = API.post(
    REACT_APP_API_NAME,
    '/v1/products',
    { body: product },
  );

  return result;
}

export async function createPartNumber(partNumber: { id: string; description: string; }) {
  await configure();

  const result = API.post(
    REACT_APP_API_NAME,
    '/v1/partNumbers',
    { body: partNumber },
  );

  return result;
}

type FurtherSubcategory = { id: string; title: string; };
type Subcategory = { id: string; title: string; furtherSubcategories: FurtherSubcategory[]; };
type Category = { id: string; title: string; subcategories: Subcategory[]; };
type Location = { id: string; title: string; categories: Category[]; };

export async function updateLocations(locations: Location[]) {
  await configure();

  const result = API.put(
    REACT_APP_API_NAME,
    '/v1/locations',
    { body: { locations } },
  );

  return result;
}

export async function updateTemplates(templates: {
  id: string;
  name: string;
  type: string;
  size: number;
  url: string;
}[]) {
  await configure();

  const result = API.put(
    REACT_APP_API_NAME,
    '/v1/templates',
    { body: { templates } },
  );

  return result;
}
