import { memo, ReactNode, useEffect } from 'react';
import { useAuth } from './providers/Auth';
import { useIncidents } from './providers/Incidents';
import { useInvestigations } from './providers/Investigations';
import { useProfile } from './providers/Profile';
import { useUsers } from './providers/Users';
import { useReports } from './providers/Reports';
import { usePartNumbers } from './providers/PartNumbers';
import { useProducts } from './providers/Products';
import { useLocations } from './providers/Locations';
import { useTemplates } from './providers/Templates';
import Loading from './components/Loading';
import { loadSelf } from './services/ApiClient';

interface Props {
  children: ReactNode;
}

function ApiSynchroniser({ children }: Props) {
  const { auth } = useAuth();
  const { incidents, synchroniseIncidents } = useIncidents();
  const { investigations, synchroniseInvestigations } = useInvestigations();
  const { users, setUsers } = useUsers();
  const { isLoading: isLoadingReports, updateReport } = useReports();
  const { isLoading: isLoadingProfile, updateProfile } = useProfile();
  const { isLoading: isLoadingPartNumbers, updatePartNumbers } = usePartNumbers();
  const { isLoading: isLoadingProducts, updateProducts } = useProducts();
  const { isLoading: isLoadingLocations, updateLocations } = useLocations();
  const { isLoading: isLoadingTemplates, updateTemplates } = useTemplates();

  useEffect(() => {

    if (!auth.isAuthenticated) {
      return;
    }
    
    let mounted = true;

    const fetch = () => {
      loadSelf().then((data) => {
        if (mounted && data) {
          setUsers(data.users);
          synchroniseInvestigations(data.investigations);
          synchroniseIncidents(data.incidents);
          updateReport(data.reports[0]);
          updateProfile(data.preferences, true);
          updateProducts(data.products);
          updatePartNumbers(data.partNumbers);
          updateLocations(data.locations);
          updateTemplates(data.templates);
        }
      });
    };

    fetch();

    const backgroundFetcher = setInterval(fetch, 5 * 60 * 1000);

    return () => {
      mounted = false;
      clearInterval(backgroundFetcher);
    };
  }, [auth.isAuthenticated]);

  if (auth.isAuthenticated &&
    (incidents.isLoading ||
      investigations.isLoading ||
      users.isLoading ||
      isLoadingProfile ||
      isLoadingReports ||
      isLoadingPartNumbers ||
      isLoadingProducts ||
      isLoadingLocations ||
      isLoadingTemplates)) {
    console.warn(
      'Loading',
      auth.isAuthenticated,
      incidents.isLoading,
      investigations.isLoading,
      users.isLoading,
      isLoadingProfile,
      isLoadingReports,
      isLoadingPartNumbers,
      isLoadingProducts,
      isLoadingLocations,
      isLoadingTemplates,
    );

    return <Loading />;
  }

  return (
    <>
      {children ?? null}
    </>
  );
}

export default memo(ApiSynchroniser);
