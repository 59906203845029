import React, { memo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

interface Props {
  breadcrums: {
    name: string;
    path: string;
  }[];
}

function DashboardBreadcrums({ breadcrums }: Props) {
  return (
    <Breadcrumbs aria-label="breadcrumb">
      {
        breadcrums.map(({ name, path }, key) => key + 1 === breadcrums.length ? (
          <Typography key={key} color="textPrimary">{name}</Typography>
        ) : (
          <Link key={key} component={RouterLink} color="inherit" to={path}>
            {name}
          </Link>
        ))
      }
    </Breadcrumbs>
  );
}

export default memo(DashboardBreadcrums);
