import { memo, useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { useProfile } from '../../../../providers/Profile';
import useCurrentUser from '../../../../hooks/useCurrentUser';

const useStyles = makeStyles(theme => ({
  inputTitle: {
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
}));

const notificationLabels = {
  submitted: 'Submit Incident Report.',
  acknowledged: 'Incident Acknowledged.',
  rejected: 'Incident Rejected.',
  'pending-update': 'Request more information for the Incident.',
  closed: 'Incident Closed.',
} as const;

function Notifications() {
  const classes = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const user = useCurrentUser();
  const { isLoading, profile, updateProfile } = useProfile();

  const [state, setState] = useState(profile.notifications);

  useEffect(() => {
    setState(profile.notifications);
  }, [isLoading, profile.notifications]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    updateProfile({
      ...profile,
      notifications: {
        ...state,
        [event.target.name]: event.target.checked,
      },
    });
  };

  if (!user) {
    return null;
  }

  return (
    <div>
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className={classes.inputTitle}
          >
            Notifications
          </Typography>
          <Typography
            variant="body1"
            color="textPrimary"
            component="div"
          >
            {

            }
            <FormGroup>
              {
                Object.entries(notificationLabels).map(([key, label]) => (
                  <FormControlLabel
                    key={key}
                    control={
                      <Switch
                        color="primary"
                        checked={state[key as keyof typeof state]}
                        onChange={handleChange}
                        name={key}
                      />
                    }
                    label={label}
                  />
                ))
              }
            </FormGroup>
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

export default memo(Notifications);
