import React from 'react';
import { MenuStateType } from './store/types';

type ContextType = null | MenuStateType & {
  isDesktop: boolean;
  isAuthMenuOpen: boolean;
  setAuthMenuOpen: (menuOpen: boolean) => unknown;
  setMiniMode: (payload: boolean) => unknown;
  setMenuOpen: (payload: boolean) => unknown;
  setMobileMenuOpen: (payload: boolean) => unknown;
  setMiniSwitchVisibility: (payload: boolean) => unknown;
};

export const Context = React.createContext<ContextType>(null);

export default Context;
