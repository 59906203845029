import { memo, useEffect, useMemo } from 'react';
import { Field, FormikErrors, FormikTouched } from 'formik';
import { Autocomplete, AutocompleteRenderInputParams } from 'formik-material-ui-lab';
import { TextField } from '@material-ui/core';
import { notProductRelated } from '../../utils/incidents';
import { usePartNumbers } from '../../providers/PartNumbers';

type FormState = {
  product: null | string;
  partNumber: null | string;
}

type Props<T extends FormState> = {
  isSubmitting: boolean;
  touched: FormikTouched<T>;
  errors: FormikErrors<T>;
  values: T;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
};

function PartNumberField<T extends FormState>({
  isSubmitting,
  touched,
  errors,
  values,
  setFieldValue,
}: Props<T>) {
  const { partNumbers } = usePartNumbers();
  useEffect(() => {
    if (values.partNumber === 'N/A' && values.product && values.product !== notProductRelated) {
      setFieldValue('partNumber', null);
    }
  }, [values.product, values.partNumber, setFieldValue]);

  const options = useMemo(() => {
    return [
      ...(values.product === null || values.product === notProductRelated ? ['N/A'] : []),
      ...partNumbers.map(partNumber => partNumber.id),
    ];
  }, [values.product, partNumbers]);

  return (
    <Field
      name="partNumber"
      component={Autocomplete}
      options={options}
      style={{ width: '100%' }}
      disabled={isSubmitting}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          error={touched['partNumber'] && !!errors['partNumber']}
          helperText={touched['partNumber'] && errors['partNumber']}
          placeholder="Type in full item part number or 'N/A' for None Product Related Incident"
          variant="outlined"
          size="medium"
        />
      )}
    />
  );
}

export default memo(PartNumberField);
