import { memo } from 'react';
import { v1 as uuid } from 'uuid';
import { Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import DashboardBreadcrums from '../../components/DashboardBreadcrums';
import Section from '../../components/Section';
import SectionHeader from '../../components/SectionHeader';
import { useReports } from '../../providers/Reports';
import NewChart from './components/NewChart';

const useStyles = makeStyles(theme => {
  return ({
    pagePaddingTop: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      [theme.breakpoints.up('md')]: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
      },
    }, 
    sectionNoPaddingTop: {
      paddingTop: 0,
    },
  });
});

function NewIntelligence() {
  const classes = useStyles();

  const { reports } = useReports();
  const [report] = reports;

  const chart = {
    id: uuid(),
    title: '',
    groupBy: 'incidentDate',
    value1: 'incidentDate',
    value2: 'incidentDate',
    xLabel: '',
    yLabel1: '',
    yLabel2: '',
    chartType: 'barChart' as const,
    sortBy: 'groupBy' as const,
    filter: [],
    visible: true,
    limit: '',
  }

  if (!chart) {
    return <Redirect to={`/intelligence`} />;
  }

  return (
    <div>
      <Section className={classes.pagePaddingTop}>
        <DashboardBreadcrums
          breadcrums={[
            { name: 'Home', path: '/' },
            { name: 'Intelligence', path: '/intelligence' },
            { name: 'Add New Chart', path: '/intelligence/new' },
          ]}
        />
      </Section>
      <Section className={classes.sectionNoPaddingTop}>
        <div>
          <SectionHeader title="Add New Chart" align="left" />
          <NewChart initialChart={chart} report={report} />
        </div>
      </Section>
    </div>
  );
}

export default memo(NewIntelligence);
