import { ChangeEvent, memo } from 'react';
import { FieldProps, getIn } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import FormControl, { FormControlProps } from '@material-ui/core/FormControl';
import InputLabel, { InputLabelProps } from '@material-ui/core/InputLabel';
import Input, { InputProps } from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteIcon from '@material-ui/icons/Delete';
import { useNotifications } from '../../providers/Notifications';

const useStyles = makeStyles(theme => ({
  gridContainer: {
    marginTop: 50,
  },
  inputFile: {
    display: 'none',
  },
  labelButton: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start',
    },
  },
}));

export interface Props extends FieldProps {
  disabled?: boolean;
  InputProps?: Omit<InputProps, 'name' | 'type' | 'label'>;
  InputLabelProps?: InputLabelProps;
  FormControlProps?: FormControlProps;
}

function FileUpload({
  field,
  form,
  disabled = false,
  InputProps: inputProps,
  InputLabelProps: inputLabelProps,
  FormControlProps: formControlProps,
}: Props) {
  const { isSubmitting, touched, errors, setFieldValue, values } = form;
  const classes = useStyles();
  const showAlert = useNotifications();
  const error = getIn(touched, field.name) && getIn(errors, field.name);

  const handleDeleteAttachment = (attachmentToDelete: {
    id: string;
    name: string;
    type: string;
    size: number;
    url: string;
  }) => {
    setFieldValue('attachments', values.attachments.filter((attachment: {
      id: string;
      name: string;
      type: string;
      size: number;
      url: string;
    }) => attachment !== attachmentToDelete));
  };

  const handleDeleteFile = (fileToDelete: File) => {
    setFieldValue(field.name, values.files.filter((file: File) => file !== fileToDelete));
  };

  return (
    <FormControl {...formControlProps}>
      <Grid container className={classes.gridContainer}>
        <Grid item xs={12}>
          <InputLabel shrink error={!!error} {...inputLabelProps} htmlFor="attachments">
            <Button
              className={classes.labelButton}
              size="large"
              variant="outlined"
              component="span"
              color="primary"
              disabled={isSubmitting}
            >
              Upload
            </Button>
          </InputLabel>
          <Input
            className={classes.inputFile}
            error={!!error}
            inputProps={{
              type: 'file',
              disabled: disabled || isSubmitting,
              name: field.name,
              multiple: true,
              id: 'attachments',
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChange: (event: ChangeEvent<any>) => {
                if (inputProps?.onChange) {
                  inputProps.onChange(event);
                } else {
                  const newFiles = Array.from(event.currentTarget.files) as File[];
                  const filteredNewFiles = newFiles.filter(file => file.size < 20 * 1024 * 1024);

                  if (filteredNewFiles.length !== newFiles.length) {
                    const count = newFiles.length - filteredNewFiles.length;
                    showAlert({
                      message: `${count} file${count === 1 ? ' is' : 's are'} too large. Please keep the files under 20MB.`,
                      severity: 'error',
                    });                
                  }

                  setFieldValue(
                    field.name,
                    Array.from(new Map([...values.files, ...filteredNewFiles].map(file => [file.name, file])))
                      .map(([, file]) => file),
                  );
                }
              },
            }}
            {...inputProps}
          />
          {error && <FormHelperText error>{error}</FormHelperText>}
        </Grid>
        <Grid item xs={12}>
          <List dense={true}>
          {
              values.attachments.map((attachment: {
                id: string;
                name: string;
                type: string;
                size: number;
                url: string;
              }, index: number) => (
                <ListItem key={index}>
                  <ListItemAvatar>
                    <Avatar>
                      <AttachFileIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={ attachment.name }
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteAttachment(attachment)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))
            }
            {
              values.files.map((file: File, index: number) => (
                <ListItem key={index}>
                  <ListItemAvatar>
                    <Avatar>
                      <AttachFileIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={ file.name }
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteFile(file)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))
            }
          </List>
        </Grid>
      </Grid>
    </FormControl>
  );
}

export default memo(FileUpload);
