import { memo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Tooltip from '../../../../components/Tooltip';

const useStyles = makeStyles(theme => ({
  inputTitle: {
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
}));

export interface Props {
  open: boolean;
  onConfirm: (user: { name: string, email: string }) => unknown;
  onCancel: () => unknown;
}

function AddUserDialog({ open, onCancel, onConfirm }: Props) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const classes = useStyles();

  const handleNameChange = (event: any) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event: any) => {
    setEmail(event.target.value);
  };

  return (
    <Dialog onClose={onCancel} open={open}>
      <DialogTitle id="simple-dialog-title">Please insert the new user details?</DialogTitle>
      <DialogContent>
        <Tooltip
          disableFocusListener
          disableTouchListener
          title="Name"
          placement="top-start"
        >
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className={classes.inputTitle}
          >
            Name *
          </Typography>
        </Tooltip>
        <TextField
          autoFocus
          variant="outlined"
          fullWidth
          value={name}
          onChange={handleNameChange}
        />
        <Tooltip
          disableFocusListener
          disableTouchListener
          title="Email"
          placement="top-start"
        >
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className={classes.inputTitle}
          >
            Email *
          </Typography>
        </Tooltip>
        <TextField
          autoFocus
          variant="outlined"
          fullWidth
          value={email}
          onChange={handleEmailChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            onConfirm({ name, email });
            setName('');
            setEmail('');
          }}
          color="primary"
          disabled={name.trim().length === 0 || email.trim().length === 0}
        >
          Add User
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default memo(AddUserDialog);
