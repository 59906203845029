import { InvestigationsActionType, InvestigationType } from './types';

export function addInvestigation(investigation: InvestigationType) {
  return {
    type: InvestigationsActionType.AddInvestigation as const, 
    investigation,
  };
}

export function updateInvestigation(investigation: InvestigationType) {
  return {
    type: InvestigationsActionType.UpdateInvestigation as const, 
    investigation,
  };
}

export function synchronise(investigations: InvestigationType[]) {
  return {
    type: InvestigationsActionType.SynchroniseInvestigations as const,
    investigations,
  };
}

export function reset() {
  return {
    type: InvestigationsActionType.ResetInvestigations as const,
  };
}
