import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import useCurrentUser from '../../../../hooks/useCurrentUser';

const useStyles = makeStyles(theme => ({
  inputTitle: {
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
}));

export default function UserDetails() {
  const classes = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const user = useCurrentUser();

  if (!user) {
    return null;
  }

  return (
    <div>
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className={classes.inputTitle}
          >
            Name
          </Typography>
          <Typography
            variant="body1"
            color="textPrimary"
          >
            { user.name }
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className={classes.inputTitle}
          >
            Email
          </Typography>
          <Typography
            variant="body1"
            color="textPrimary"
          >
            { user.email }
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className={classes.inputTitle}
          >
            Role
          </Typography>
          <Typography
            variant="body1"
            color="textPrimary"
          >
            { user.role.charAt(0).toUpperCase() + user.role.slice(1) }
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}
