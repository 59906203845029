import { useContext } from 'react';
import Context from './Context';
export { default } from './Provider';

export function useTemplates() {
  const templates = useContext(Context);

  if (!templates) {
    throw new Error('Failed to find templates context.');
  }

  return templates;
}
