import { memo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Tooltip from '../../../../components/Tooltip';

const useStyles = makeStyles(theme => ({
  inputTitle: {
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
}));

export interface Props {
  open: boolean;
  onConfirm: (partNumber: { id: string, description: string }) => unknown;
  onCancel: () => unknown;
}

function AddProductDialog({ open, onCancel, onConfirm }: Props) {
  const [id, setId] = useState('');
  const [description, setDescription] = useState('');
  const classes = useStyles();

  const handleIdChange = (event: any) => {
    setId(event.target.value);
  };

  const handleDescriptionChange = (event: any) => {
    setDescription(event.target.value);
  };

  return (
    <Dialog onClose={onCancel} open={open}>
      <DialogTitle id="simple-dialog-title">What is id and description of the new part number?</DialogTitle>
      <DialogContent>
        <Tooltip
          disableFocusListener
          disableTouchListener
          title="Part Number"
          placement="top-start"
        >
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className={classes.inputTitle}
          >
            Part Number *
          </Typography>
        </Tooltip>
        <TextField
          autoFocus
          variant="outlined"
          fullWidth
          value={id}
          onChange={handleIdChange}
        />
        <Tooltip
          disableFocusListener
          disableTouchListener
          title="Part Number Description"
          placement="top-start"
        >
          <Typography
            variant="subtitle1"
            color="textPrimary"
            className={classes.inputTitle}
          >
            Part Number Description *
          </Typography>
        </Tooltip>
        <TextField
          autoFocus
          variant="outlined"
          fullWidth
          value={description}
          onChange={handleDescriptionChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            onConfirm({ id, description });
            setId('');
            setDescription('');
          }}
          color="primary"
          disabled={id.trim().length === 0 || description.trim().length === 0}
        >
          Add Part Number
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default memo(AddProductDialog);
