import { memo, useEffect, useMemo } from 'react';
import { Field, FormikErrors, FormikTouched } from 'formik';
import { Autocomplete, AutocompleteRenderInputParams } from 'formik-material-ui-lab';
import { TextField } from '@material-ui/core';
import type { Category, Location as IncidentLocation } from '../../providers/Locations/types';

type FormState = {
  category: Category | null;
  incidentLocation: IncidentLocation | null;
}

type Props<T extends FormState> = {
  isSubmitting: boolean;
  touched: FormikTouched<T>;
  errors: FormikErrors<T>;
  values: T;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
};

function CategoryField<T extends FormState>({
  isSubmitting,
  touched,
  errors,
  values,
  setFieldValue,
}: Props<T>) {
  useEffect(() => {
    if (!values.category) {
      return;
    }

    if (!values.incidentLocation || !values.incidentLocation.categories.includes(values.category)) {
      setFieldValue('category', null);
    }
  }, [values.incidentLocation, values.category, setFieldValue]);

  const options = useMemo(() => {
    return values.incidentLocation?.categories ?? [];
  }, [values.incidentLocation]);

  return (
    <Field
      name="category"
      component={Autocomplete}
      options={options}
      getOptionLabel={(option: { title: string }) => option?.title ?? ''}
      style={{ width: '100%' }}
      disabled={!options.length || isSubmitting}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          error={touched['category'] && !!errors['category']}
          helperText={touched['category'] && errors['category']}
          placeholder={!options.length ? 'Not applicable' : 'Category'}
          variant="outlined"
        />
      )}
    />
  );
}

export default memo(CategoryField);
