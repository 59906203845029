import { render } from 'react-dom';
import NoSsr from '@material-ui/core/NoSsr';
import App from './App';
import reportWebVitals from './reportWebVitals';

render(
  (
    <NoSsr>
      <App />
    </NoSsr>
  ),
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
