import { memo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Section from '../../../../components/Section';
import SectionHeader from '../../../../components/SectionHeader';
import { usePartNumbers } from '../../../../providers/PartNumbers';
import PartNumberTable from './PartNumberTable';
import PartNumbersFab from './PartNumbersFab';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  pagePaddingTop: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
  },
  sectionNoPaddingTop: {
    paddingTop: 0,
  },
}));

function PartNumbers() {
  const classes = useStyles();
  const { partNumbers } = usePartNumbers();

  return (
    <Box className={classes.root}>
      <Section className={classes.sectionNoPaddingTop}>
        <div>
          <SectionHeader title="Part Numbers" align="left" />
          <PartNumberTable rows={partNumbers} />
          <PartNumbersFab />
        </div>
      </Section>
    </Box>
  );
}

export default memo(PartNumbers);
