import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid } from '@material-ui/core';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import HelpIcon from '@material-ui/icons/Help';
import ListIcon from '@material-ui/icons/List';
import PeopleIcon from '@material-ui/icons/People';
import BarChartIcon from '@material-ui/icons/BarChart';
import SettingsIcon from '@material-ui/icons/Settings';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import SectionHeader from '../../../../components/SectionHeader';
import CardBase from '../../../../components/CardBase';
import DescriptionListIcon from '../../../../components/DescriptionListIcon';

const useStyles = makeStyles(theme => ({
  image: {
    width: 60,
    height: 60,
    objectFit: 'contain',
    marginBottom: theme.spacing(5),
  },
  descriptionListIcon: {
    fontWeight: 400,
    fontSize: 16,
  },
  cardBase: {
    borderRadius: theme.spacing(2),
    background: theme.palette.alternate.main,
  },
}));

export default function Modules() {
  const classes = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const currentUser = useCurrentUser();

  const modules = [
    {
      title: 'Report Incident',
      to: '/incident/new',
      icon: <ReportProblemIcon fontSize="large" />,
      visible: true,
    },
    {
      title: 'Incidents',
      to: '/incidents',
      icon: <ListIcon fontSize="large" />,
      visible: true,
    },
    {
      title: 'Intelligence',
      to: '/intelligence',
      icon: <BarChartIcon fontSize="large" />,
      visible: true,
    },
    {
      title: 'Users',
      to: '/users',
      icon: <PeopleIcon fontSize="large" />,
      visible: currentUser?.role === 'admin',
    },
    {
      title: 'Help',
      to: '/help',
      icon: <HelpIcon fontSize="large" />,
      visible: true,
    },
    {
      title: 'Administration',
      to: '/admin',
      icon: <SettingsIcon fontSize="large" />,
      visible: currentUser?.role === 'admin',
    },
  ];

  return (
    <div>
      <SectionHeader title="Modules" align="left" />
      <Grid container spacing={isMd ? 4 : 2}>
        {
          modules.map(module => module.visible && (
            <Grid item xs={6} md={3} xl={2} key={module.title}>
              <CardBase
                noBorder
                noShadow
                liftUp
                className={classes.cardBase}
                to={module.to}
              >
                <DescriptionListIcon
                  className={classes.descriptionListIcon}
                  icon={module.icon}
                  title={module.title}
                />
              </CardBase>
            </Grid>
          ))
        }
      </Grid>
    </div>
  );
}
