import { memo, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '../../../../components/Tooltip';
import Loading from '../../../../components/Loading';
import { useProducts } from '../../../../providers/Products';
import { useNotifications } from '../../../../providers/Notifications';
import { createProduct } from '../../../../services/ApiClient';
import AddProductDialog from './AddProductDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 0,
      flexGrow: 1,
    },
    fab: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  }),
);

function ProductsFab() {
  const classes = useStyles();
  const showAlert = useNotifications();
  const [showAdd, setShowAdd] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { updateProducts, products } = useProducts();

  return (
    <div className={classes.root}>
      <Tooltip
        title="Add Product"
        placement="top-start"
      >
        <Fab
          color="primary"
          aria-label="Add"
          className={classes.fab}
          onClick={() => setShowAdd(true)}
        >
          <AddIcon />
        </Fab>
      </Tooltip>
      {
        isLoading && (
          <Loading fullScreen />
        )
      }
      <AddProductDialog
        open={showAdd}
        onConfirm={async (name: string) => {
          setShowAdd(false);
          setIsLoading(true);

          try {
            const result = await createProduct(name);

            updateProducts(products.concat(result));

            showAlert({
              message: 'Add product successfully.',
              severity: 'success',
            });
          } catch (error) {
            showAlert({
              message: 'Failed to add product.',
              severity: 'error',
            });
          }

          setIsLoading(false);
        }}
        onCancel={() => setShowAdd(false)}
      />
    </div>
  );
}

export default memo(ProductsFab);
