import { memo, useState, useCallback, useRef } from 'react';
import html2canvas from 'html2canvas';
import { Redirect } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import { saveAs } from 'file-saver';
import CardContent from '@material-ui/core/CardContent';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import EditIcon from '@material-ui/icons/Edit';
import BarChart from './BarChart';
import BarChartWithLine from './BarChartWithLine';
import PieChart from './PieChart';
import useData from './useData';

interface Props {
  id: string;
  title: string;
  chartType: 'barChart' | 'barChartWithLine' | 'pieChart';
  groupBy: string;
  value1: string;
  value2: string;
  xLabel: string;
  yLabel1: string;
  yLabel2: string;
  filter: any;
  sortBy: 'groupBy' | 'value1' | 'value2';
  limit: any;
  showEdit?: boolean;
}

function Chart({
  id,
  title,
  chartType,
  groupBy,
  value1,
  value2,
  xLabel,
  yLabel1,
  yLabel2,
  filter,
  sortBy,
  limit,
  showEdit,
}: Props) {
  const ref = useRef<HTMLElement>(null);
  const [redirect, setRedirect] = useState(false);
  const data = useData(
    xLabel,
    yLabel1,
    yLabel2,
    groupBy,
    value1,
    value2,
    filter,
    sortBy,
    limit,
  );

  const handleEditClick = useCallback(() => {
    setRedirect(true);
  }, [])

  const handleDownload = useCallback(async () => {
    if (ref.current !== null && (ref.current as any).container) {
      const bounds = (ref.current as any).container.getBoundingClientRect();
      const data = await html2canvas((ref.current as any).container as HTMLElement, {
        scrollY: -window.scrollY,
        windowWidth: bounds.width,
        windowHeight: bounds.height,
        width: bounds.width,
        height: bounds.height,
      }).then((canvas: HTMLCanvasElement) => {
        const canvasWithTitle = document.createElement('canvas');

        canvasWithTitle.width = canvas.width;
        canvasWithTitle.height = canvas.height + 72;
  
        const context = canvasWithTitle.getContext('2d')!;
        
        context.save();

        context.fillStyle = 'white';
        context.fillRect(0, 0, canvasWithTitle.width, canvasWithTitle.height);
    
        context.save();

        context.scale(window.devicePixelRatio, window.devicePixelRatio);

        context.font = '21px Lato';
        context.fillStyle = 'black';
        context.textAlign = 'center';
        context.fillText(title, 1 / window.devicePixelRatio * canvas.width / 2, 1 / window.devicePixelRatio * 4);
  
        context.restore();

        context.translate(0, 72);

        context.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, canvasWithTitle.width, canvasWithTitle.height - 72);

        context.restore();
  
        return canvasWithTitle.toDataURL('image/png', 1.0);
      });

      saveAs(data, `${title}.png`);
    }
  }, [title]);

  if (redirect === true) {
    return <Redirect to={`/intelligence/${id}/edit`} />;
  }

  return (
    <Card>
      <CardHeader
        title={title}
        action={
          <>
            {
              !!showEdit && (
                <IconButton
                  aria-label="Edit"
                  color="inherit"
                  onClick={handleEditClick}
                >
                  <EditIcon />
                </IconButton>
              )
            }
            <IconButton
              aria-label="Download"
              color="inherit"
              onClick={handleDownload}
            >
              <SaveAltIcon />
            </IconButton>
          </>
        }
      />
      <CardContent>
        {
          chartType === 'barChart' && (
            <BarChart data={data} ref={ref} />
          )
        }
        {
          chartType === 'barChartWithLine' && (
            <BarChartWithLine data={data}  ref={ref} />
          )
        }
        {
          chartType === 'pieChart' && (
            <PieChart data={data} ref={ref} />
          )
        }
      </CardContent>
    </Card>
  );
}

export default memo(Chart);
