import { memo, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Tooltip from '../../../../components/Tooltip';
import Loading from '../../../../components/Loading';
import { useLocations } from '../../../../providers/Locations';
import { useNotifications } from '../../../../providers/Notifications';
import * as ApiClient from '../../../../services/ApiClient';
import AddLocationDialog from './AddLocationDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 0,
      flexGrow: 1,
    },
    fab: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  }),
);

function LocationsFab() {
  const classes = useStyles();
  const showAlert = useNotifications();
  const [showAdd, setShowAdd] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { updateLocations, locations } = useLocations();

  return (
    <>
      <div className={classes.root}>
        <Tooltip
          title="Add Location"
          placement="top-start"
        >
          <Fab
            color="primary"
            aria-label="Add"
            className={classes.fab}
            onClick={() => setShowAdd(true)}
          >
            <AddIcon />
          </Fab>
        </Tooltip>
      </div>
      {
        isLoading && (
          <Loading fullScreen />
        )
      }
      <AddLocationDialog
        open={showAdd}
        onConfirm={async (title: string) => {
          setShowAdd(false);
          setIsLoading(true);

          try {
            if (title.replace(/[^\w]+/g, '_') === '' || locations.map(value => value.id).includes(title.replace(/[^\w]+/g, '_'))) {
              throw new Error();
            }

            const newLocations = locations.concat({
              id: title.replace(/[^\w]+/g, '_'),
              title,
              categories: [],
            });

            const result = await ApiClient.updateLocations(newLocations);

            updateLocations(result);

            showAlert({
              message: 'Added location successfully.',
              severity: 'success',
            });
          } catch (error) {
            showAlert({
              message: 'Failed to add location.',
              severity: 'error',
            });
          }

          setIsLoading(false);
        }}
        onCancel={() => setShowAdd(false)}
      />
    </>
  );
}

export default memo(LocationsFab);
