import { PropsWithChildren, useEffect, useMemo, useReducer } from 'react';
import { useAuth } from '../Auth';
import Context from './Context';
import * as actions from './store/actions';
import reducer, { initialState } from './store/reducer';
import { InvestigationType } from './store/types';

export default function Provider({
  persistKey = 'investigations',
  children,
}: PropsWithChildren<{ persistKey?: string }>) {
  const { auth } = useAuth();

  const data = localStorage.getItem(persistKey);
  const json = data && JSON.parse(data);

  const [investigations, dispatch] = useReducer(reducer, json || initialState);

  useEffect(() => {
    try {
      if (auth.isAuthenticated) {
        localStorage.setItem(persistKey, JSON.stringify(investigations));
      }
    } catch (error) {
      console.warn(error);
    }
  }, [auth.isAuthenticated, investigations, persistKey]);

  useEffect(() => {
    if (!auth.isAuthenticated) {
      try {
        localStorage.removeItem(persistKey);
      } catch (error) {
        console.warn(error);
      }
      dispatch(actions.reset());
    }
  }, [auth.isAuthenticated, persistKey]);

  const investigationMap = useMemo(() => {
    return new Map((investigations?.investigations ?? []).map(investigation => [investigation.id, investigation] as const));
  }, [investigations?.investigations]);

  const addInvestigation = (investigation: InvestigationType) => {
    dispatch(actions.addInvestigation(investigation));
  };

  const updateInvestigation = (investigation: InvestigationType) => {
    dispatch(actions.updateInvestigation(investigation));
  };

  const synchroniseInvestigations = (investigations: InvestigationType[]) => {
    dispatch(actions.synchronise(investigations));
  };

  return (
    <Context.Provider value={{
      investigations,
      addInvestigation,
      updateInvestigation,
      synchroniseInvestigations,
      investigationMap,
    }}>
      {children}
    </Context.Provider>
  );
}
