import { withStyles, Theme } from '@material-ui/core/styles';
import { Tooltip as RawTooltip } from '@material-ui/core';

const Tooltip = withStyles((theme: Theme) => ({
  tooltip: {
    fontSize: theme.typography.pxToRem(15),
  },
}))(RawTooltip);

export default Tooltip;
