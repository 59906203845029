import { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Button, TextField, Typography, useMediaQuery } from '@material-ui/core';
import { TextField as FormTextField } from 'formik-material-ui';
import { Autocomplete, AutocompleteRenderInputParams } from 'formik-material-ui-lab';
import { useAuth } from '../../../../providers/Auth';
import { useIncidents } from '../../../../providers/Incidents';
import { useNotifications } from '../../../../providers/Notifications';
import { useProducts } from '../../../../providers/Products';
import { usePartNumbers } from '../../../../providers/PartNumbers';
import { useLocations } from '../../../../providers/Locations';
import type { Location as IncidentLocation, Category, Subcategory, FurtherSubcategory } from  '../../../../providers/Locations/types';
import * as ApiClient from '../../../../services/ApiClient';
import {
  notProductRelated,
  sanitiseIncidentForm,
} from '../../../../utils/incidents';
import { buildAttachment } from '../../../../utils/attachments';
import Loading from '../../../../components/Loading';
import Tooltip from '../../../../components/Tooltip';
import PastOrPresentDatePicker from '../../../../components/PastOrPresentDatePicker';
import PartNumberField from '../../../../components/PartNumberField';
import SubcategoryField from '../../../../components/SubcategoryField';
import FurtherSubcategoryField from '../../../../components/FurtherSubcategoryField';
import CategoryField from '../../../../components/CategoryField';
import FileUpload from '../../../../components/FileUpload';
import DiscardChangesDialog from '../DiscardChangesDialog';
import SaveChangesDialog from '../SaveChangesDialog';

const useStyles = makeStyles(theme => ({
  form: {
    '& .MuiTextField-root': {
      background: theme.palette.background.paper,
    },
    '& .MuiOutlinedInput-input': {
      background: theme.palette.background.paper,
    },
  },
  inputTitle: {
    fontWeight: 700,
    marginBottom: theme.spacing(1),
  },
  uploadButton: {
    display: 'flex',
    justifyContent: 'center',
    border: '1px solid transparent',
    background: theme.palette.alternate.dark,
    textTransform: 'lowercase',
    '& .icon-text': {
      width: 'auto',
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start',
    },
  },
  submitButton: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start',
    },
  },
  closeContainer: {
    marginTop: theme.spacing(12),
  },
  closeButton: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start',
    },
  }
}));

const validationSchema = yup.object({
  incidentDate: yup
    .date()
    .required('Incident date is required'),
  product: yup
    .string()
    .required('Product is required'),
  partNumber: yup
    .string()
    .required('Part number is required'),
  defectiveQuantity: yup
    .number()
    .min(0)
    .required('Defective quantity is required'),
  grnNumber: yup
    .string()
    .trim()
    .matches(/^(S\d{5})|(GRN\d{8})$/, 'GRN number does not have the correct format')
    .required('GRN number is required'),
  orderNumber: yup
    .string()
    .required('Order number is required'),
  incidentLocation: yup
    .string()
    .required('Incident location is required'),
  category: yup
    .string()
    .required('Category is required'),
  subcategory: yup
    .string()
    .required('Subcategory level 1 is required'),
  furtherSubcategory: yup
    .string()
    .required('Subcategory level 2 is required'),
  description: yup
    .string()
    .required('Description is required'),
});

type FormState = {
  id: string;
  reportedBy: string;
  incidentDate: Date;
  product: null | string;
  partNumber: null | string;
  defectiveQuantity: null | string;
  grnNumber: string;
  orderNumber: string;
  incidentLocation: IncidentLocation | null;
  category: Category | null;
  subcategory: Subcategory | null;
  furtherSubcategory: FurtherSubcategory | null;
  description: string;
  investigationId: string | null;
  attachments: {
    id: string;
    name: string;
    type: string;
    size: number;
    url: string;
  }[];
  files: File[];
  incidentAcknowledged?: string;
  status: string;
};

interface Props {
  incident: FormState;
}

export default function EditIncidentForm({ incident }: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const showAlert = useNotifications();
  const { auth } = useAuth();
  const { updateIncident } = useIncidents();
  const { products } = useProducts();
  const { partNumberDescriptionMap } = usePartNumbers();
  const { locations } = useLocations();
  const [hasSubmitted, setHasSubmitted] = useState<string>();
  const [showDiscard, setShowDiscard] = useState(false);
  const [showSave, setShowSave] = useState(false);

  if (!auth.isAuthenticated) {
    return null;
  }

  if (hasSubmitted) {
    return <Redirect to={`/incident/${hasSubmitted}`} />;
  }

  return (
    <div className={classes.form}>
      <Formik
        initialValues={incident}
        validate={
          async (values) => {
            const validate = await validationSchema.validate(values, { abortEarly: false })
              .catch(error => {
                const result: { [path: string]: string } = {};

                error.inner.forEach((error: { path: string; message: string; }) => {
                  switch (error.path) {
                    case 'product': {
                      result[error.path] = 'Please select a product from the list.';
                      return;                        
                    }
                    case 'defectiveQuantity': {
                      result[error.path] = 'Please insert a non-negative number.';
                      return;
                    }
                    case 'partNumber': {
                      result[error.path] = 'Please select a value from the list.';
                      return;                        
                    }
                    case 'grnNumber': {
                      if (values.grnNumber === '' && (!values.product || values.product === 'Not Product Related')) {
                        return;
                      }
                      break;
                    }
                    case 'orderNumber': {
                      if (values.orderNumber === '' && (!values.product || values.product === 'Not Product Related')) {
                        return;
                      }
                      break;
                    }
                    case 'incidentLocation': {
                      if (!values[error.path]) {
                        result[error.path] = 'Please select an incident location from the list.';
                      }
                      return;
                    }
                    case 'category': {
                      if (!values[error.path] && (values?.incidentLocation as any)?.categories.length) {
                        result[error.path] = 'Please select an category from the list.';
                      }
                      return;
                    }
                    case 'subcategory': {
                      if (!values[error.path] && (values?.category as any)?.subcategories.length) {
                        result[error.path] = 'Please select an subcategory level 1 from the list.';
                      }
                      return;
                    }
                    case 'furtherSubcategory': {
                      if (!values[error.path] && (values?.subcategory as any)?.furtherSubcategories.length) {
                        result[error.path] = 'Please select an subcategory level 2 from the list.';
                      }
                      return;
                    }
                  }
                  result[error.path] = error.message;
                });

                if ((values.status === 'under-investigation' || values.status === 'investigation-completed' || values.status === 'closed') && !values.investigationId) {
                  result.investigationId = 'Please select a value from the list.';
                }

                return result;
              });

            console.log('validate...', validate);

            return validate;
          }
        }
        onSubmit={
          async (values) => {
            const attachments = [...values.attachments];

            for (let file of values.files) {
              const attachment = buildAttachment(file);
              await ApiClient.uploadImage(attachment.key, file);
              attachments.push({
                id: attachment.id,
                name: attachment.name,
                type: attachment.type,
                size: attachment.size,
                url: attachment.url,
              });
            }

            const { incidentAcknowledged, ...formValues } = values;
        
            const incident = sanitiseIncidentForm({
              ...formValues,
              attachments,
            });
        
            const result = await ApiClient.updateIncident(incident);

            showAlert({
              message: 'Updated incident successfully.',
              severity: 'success',
            });

            updateIncident(result);

            Promise.resolve().then(() => setHasSubmitted(result.id));
          }
        }
      >
        {
          ({ submitForm, isSubmitting, touched, errors, values, setFieldValue, setSubmitting }) => (
            <>
              <Form>
                <Grid container spacing={isMd ? 4 : 2}>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Tooltip
                      disableFocusListener
                      disableTouchListener
                      title="Incident Date"
                      placement="top-start"
                    >
                      <Typography
                        variant="subtitle1"
                        color="textPrimary"
                        className={classes.inputTitle}
                      >
                        Incident Date *
                      </Typography>
                    </Tooltip>
                    <Field
                      name="incidentDate"
                      component={PastOrPresentDatePicker}
                      style={{ width: '100%' }}
                      disableToolbar
                      variant="inline"
                      format="dd-MM-yyyy"
                      size="medium"
                      inputVariant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Tooltip
                      disableFocusListener
                      disableTouchListener
                      title="Product"
                      placement="top-start"
                    >
                      <Typography
                        variant="subtitle1"
                        color="textPrimary"
                        className={classes.inputTitle}
                      >
                        Product *
                      </Typography>
                    </Tooltip>
                    <Field
                      name="product"
                      component={Autocomplete}
                      options={[notProductRelated, ...products]}
                      style={{ width: '100%' }}
                      renderInput={(params: AutocompleteRenderInputParams) => (
                        <TextField
                          {...params}
                          error={touched['product'] && !!errors['product']}
                          helperText={touched['product'] && errors['product']}
                          placeholder="Product"
                          variant="outlined"
                          size="medium"
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={isMd ? 4 : 2}>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      color="textSecondary"
                    >
                      Defective item
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Tooltip
                      disableFocusListener
                      disableTouchListener
                      title="Type in full item part number or 'N/A' for None Product Related Incident"
                      placement="top-start"
                    >
                      <Typography
                        variant="subtitle1"
                        color="textPrimary"
                        className={classes.inputTitle}
                      >
                        Part Number *
                      </Typography>
                    </Tooltip>
                    <PartNumberField
                      isSubmitting={isSubmitting}
                      touched={touched}
                      errors={errors}
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Typography
                      variant="subtitle1"
                      color="textPrimary"
                      className={classes.inputTitle}
                    >
                      Part Number Description
                    </Typography>
                    <Typography
                      variant="body1"
                      color="textPrimary"
                    >
                      { (values.partNumber && partNumberDescriptionMap.get(values.partNumber!)) || '—' }
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Tooltip
                      disableFocusListener
                      disableTouchListener
                      title="Defective Quantity"
                      placement="top-start"
                    >
                      <Typography
                        variant="subtitle1"
                        color="textPrimary"
                        className={classes.inputTitle}
                      >
                        Defective Quantity *
                      </Typography>
                    </Tooltip>
                    <Field
                      component={FormTextField}
                      placeholder="Defective Quantity"
                      variant="outlined"
                      size="medium"
                      name="defectiveQuantity"
                      type="number"
                      onChange={(event: any) => {
                        setFieldValue('defectiveQuantity', event.target.value.replace(/-/g, ''));
                      }}
                      fullWidth
                      disabled={isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Tooltip
                      disableFocusListener
                      disableTouchListener
                      title="Type in number with 'GRN'"
                      placement="top-start"
                    >
                      <Typography
                        variant="subtitle1"
                        color="textPrimary"
                        className={classes.inputTitle}
                      >
                        {
                          (!values.product || values.product === 'Not Product Related')
                            ? 'GRN Number'
                            : 'GRN Number *'
                        }
                      </Typography>
                    </Tooltip>
                    <Field
                      component={FormTextField}
                      placeholder="Type in number with 'GRN'"
                      variant="outlined"
                      size="medium"
                      name="grnNumber"
                      type="text"
                      fullWidth
                      disabled={isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Tooltip
                      disableFocusListener
                      disableTouchListener
                      title="Type in number startin with 'WO', 'SO' or 'PO'"
                      placement="top-start"
                    >
                      <Typography
                        variant="subtitle1"
                        color="textPrimary"
                        className={classes.inputTitle}
                      >
                        {
                          (!values.product || values.product === 'Not Product Related')
                            ? 'Order Number'
                            : 'Order Number *'
                        }
                      </Typography>
                    </Tooltip>
                    <Field
                      component={FormTextField}
                      placeholder="Type in number startin with 'WO', 'SO' or 'PO'"
                      variant="outlined"
                      size="medium"
                      name="orderNumber"
                      type="text"
                      fullWidth
                      disabled={isSubmitting}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={isMd ? 4 : 2}>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Tooltip
                      disableFocusListener
                      disableTouchListener
                      title="Place of observation, not the source of the problem"
                      placement="top-start"
                    >
                      <Typography
                        variant="subtitle1"
                        color="textPrimary"
                        className={classes.inputTitle}
                      >
                        Location of the Incident *
                      </Typography>
                    </Tooltip>
                    <Field
                      name="incidentLocation"
                      component={Autocomplete}
                      options={locations}
                      getOptionLabel={(option: { title: string }) => option?.title ?? ''}
                      style={{ width: '100%' }}
                      disabled={isSubmitting}
                      renderInput={(params: AutocompleteRenderInputParams) => (
                        <TextField
                          {...params}
                          error={touched['incidentLocation'] && !!errors['incidentLocation']}
                          helperText={touched['incidentLocation'] && errors['incidentLocation']}
                          placeholder="Place of observation, not the source of the problem"
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Typography
                      variant="subtitle1"
                      color="textPrimary"
                      className={classes.inputTitle}
                    >
                      Category *
                    </Typography>
                    <CategoryField
                      isSubmitting={isSubmitting}
                      touched={touched}
                      errors={errors}
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Typography
                      variant="subtitle1"
                      color="textPrimary"
                      className={classes.inputTitle}
                    >
                      Subcategory Level 1 *
                    </Typography>
                    <SubcategoryField
                      isSubmitting={isSubmitting}
                      touched={touched}
                      errors={errors}
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Typography
                      variant="subtitle1"
                      color="textPrimary"
                      className={classes.inputTitle}
                    >
                      Subcategory Level 2 *
                    </Typography>
                    <FurtherSubcategoryField
                      isSubmitting={isSubmitting}
                      touched={touched}
                      errors={errors}
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle1"
                      color="textPrimary"
                      className={classes.inputTitle}
                    >
                      Attachments
                    </Typography>
                    <Field
                      component={FileUpload}
                      name="files"
                      variant="outlined"
                      disabled={isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle1"
                      color="textPrimary"
                      className={classes.inputTitle}
                    >
                      Description of the incident *
                    </Typography>
                    <Field
                      component={FormTextField}
                      placeholder="Explain briefly details of the incident"
                      variant="outlined"
                      size="medium"
                      name="description"
                      type="text"
                      fullWidth
                      multiline
                      disabled={isSubmitting}
                      rows={8}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button
                      size="large"
                      variant="outlined"
                      type="button"
                      color="primary"
                      name="saveDraft"
                      fullWidth
                      disabled={isSubmitting}
                      onClick={() => setShowDiscard(true)}
                      className={classes.submitButton}
                    >
                      Discard changes
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button
                      size="large"
                      variant="contained"
                      type="button"
                      color="primary"
                      name="submit"
                      fullWidth
                      disabled={isSubmitting}
                      onClick={() => setShowSave(true)}
                      className={classes.submitButton}
                    >
                      Save changes
                    </Button>
                  </Grid>
                </Grid>
              </Form>
              {
                isSubmitting && (
                  <Loading fullScreen />
                )
              }
              <DiscardChangesDialog
                open={showDiscard}
                onConfirm={() => {
                  setShowDiscard(false);
                  setHasSubmitted(values.id);
                }}
                onCancel={() => {
                  setShowDiscard(false);
                }}
              />
              <SaveChangesDialog
                open={showSave}
                onConfirm={async () => {
                  setSubmitting(true);
                  setShowSave(false);
                  await submitForm();
                }}
                onCancel={() => {
                  setShowSave(false);
                }}
              />
            </>
          )
        }
      </Formik>
    </div>
  );
}
