import React, { PropsWithChildren, useEffect, useReducer } from 'react';
import { Hub, Logger } from 'aws-amplify';
import Context from './Context';
import * as actions from './store/actions';
import reducer from './store/reducer';
import { AuthStateType } from './store/types';
import { configure, currentAuthenticatedUser, initialise } from '../../services/ApiClient';

const logger = new Logger('Auth_Watcher');

export default function Provider({
  persistKey = 'auth',
  children,
}: PropsWithChildren<{ persistKey?: string }>) {
  const data = localStorage.getItem(persistKey);
  const json = data && JSON.parse(data);

  /*
  if (json?.isAuthenticated) {
    configure();
  }
  */

  const [auth, dispatch] = useReducer(reducer, json || {
    isAuthenticated: false,
  });

  useEffect(() => {
    try {
      localStorage.setItem(persistKey, JSON.stringify(auth));
    } catch (error) {
      console.warn(error);
    }
  }, [auth, persistKey]);

  const setAuth = (auth: AuthStateType) => {
    dispatch(actions.setAuth(auth));
  }

  const updateAuth = (auth: AuthStateType) => {
    dispatch(actions.updateAuth(auth));
  }

  useEffect(() => {
    const onHubCapsule = async (capsule: any) => {
      switch (capsule.payload.event) {
        case 'signIn': {
          logger.error('user signed in', capsule.payload);
          const user = await currentAuthenticatedUser();
          configure();
          console.log('user!!!', user);
          setAuth({
            isAuthenticated: true,
            email: user.attributes.email,
            username: user.username,
          });
          break;
        }
        case 'signUp':
          logger.error('user signed up');
          break;
        case 'signOut':
          sessionStorage.clear();
          logger.error('user signed out');
          setAuth({ isAuthenticated: false });
          break;
        case 'signIn_failure':
          logger.error('user sign in failed');
          break;
        case 'configured': {
          try {
            const user = await currentAuthenticatedUser();
            configure();
            console.log('user!!!', user);
          } catch (error) {
            logger.error(error);
            sessionStorage.clear();
            setAuth({ isAuthenticated: false });
          }
          logger.error('the Auth module is configured');
          break;
        }
      }
    };
    
    Hub.listen('auth', onHubCapsule);

    return () => {
      Hub.remove('auth', onHubCapsule);
    };
  }, []);

  useEffect(() => {
    initialise();
  }, []);

  return (
    <Context.Provider value={{ auth, setAuth, updateAuth }}>
      {children}
    </Context.Provider>
  );
}
