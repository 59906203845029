import { useContext } from 'react';
import Context from './Context';
export { default } from './Provider';

export function useUsers() {
  const users = useContext(Context);

  if (!users) {
    throw new Error('Failed to find users context.');
  }

  return users;
}
