import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import IncidentTable from '../../../../components/IncidentTable';
import { useIncidents } from '../../../../providers/Incidents';
import { useProfile } from '../../../../providers/Profile';
import useCurrentUser from '../../../../hooks/useCurrentUser';

function getFilter(key: string) {
  switch (key) {
    case 'incidentDate':
      return {
        key,
        type: 'date' as const,
        allowEmpty: true,
        allowAll: true,
        filter: null,
      };
    case 'incidentLocation':
    case 'category':
    case 'subcategory':
    case 'furtherSubcategory':
      return {
        key,
        type: 'object' as const,
        allowEmpty: true,
        filter: null,
      };
    case 'created_at':
    case 'updated_at':
      return {
        key,
        type: 'dateTime' as const,
        allowEmpty: true,
        allowAll: true,
        filter: null,
      };
    case 'description':
      return {
        key,
        type: 'string' as const,
        allowEmpty: true,
        contains: '',
      };
    case 'investigationId':
      return {
        key,
        type: 'status' as const,
        allowEmpty: true,
        allowAll: true,
        filter: null,
      };
    case 'incidentJustified':
      return {
        key,
        type: 'boolean' as const,
        filter: null,
      };
    default:
      return {
        key,
        type: 'category' as const,
        allowEmpty: true,
        allowAll: true,
        filter: null,
      };
    };
}

const defaultFilter = [
  { id: 'incidentNumber', selected: true },
  { id: 'reportedBy', selected: true },
  { id: 'incidentDate', selected: true },
  { id: 'product', selected: true },
  { id: 'partNumber', selected: true },
  { id: 'defectiveQuantity', selected: true },
  { id: 'grnNumber', selected: true },
  { id: 'orderNumber', selected: true },
  { id: 'incidentLocation', selected: true },
  { id: 'category', selected: true },
  { id: 'subcategory', selected: true },
  { id: 'furtherSubcategory', selected: true },
  { id: 'description', selected: true },
  { id: 'investigationId', selected: true },
  { id: 'incidentJustified', selected: true },
  { id: 'status', selected: true},
  { id: 'created_at', selected: true },
  { id: 'updated_at', selected: true },
];

function getStoredFilters() {
  const storedFilters = sessionStorage.getItem('incidentFilters-incidentList');

  if (storedFilters) {
    return JSON.parse(storedFilters);
  }

  return null;
}

export default function IncidentList() {
  const currentUser = useCurrentUser();
  const { updateProfile, profile } = useProfile();
  const { incidents } = useIncidents();
  const location = useLocation();
  const { page: initialPage, rowsPerPage: initialRowsPerPage } = useMemo(() => {
    const queryParams = new URLSearchParams(location.search);
    const page = queryParams.get('page') ?? undefined;
    const rowsPerPage = queryParams.get('rowsPerPage') ?? undefined;

    return { page, rowsPerPage };
  }, [location]);

  const [filters, setFilters] = useState(() => getStoredFilters() || defaultFilter.map(({ id }) => [
    id,
    getFilter(id),
  ]));

  const incidentList = useMemo(
    () => incidents.incidents.filter(incident => currentUser?.role === 'admin' || incident.status !== 'draft'),
    [incidents.incidents, currentUser],
  );

  const fieldFilter = useMemo(
    () => {
      const filterMap = new Map(filters as any);
      return (profile.incidentsFilter || defaultFilter).map(filter => ({
        ...filter,
        filter: filterMap.get(filter.id),
      }));
    },
    [profile, filters],
  );

  const handleUpdate = (newFieldFilter: { id: string; selected: boolean, filter: any }[]) => {
    const newFilters = newFieldFilter.map(filter => [filter.id, filter.filter]);
    setFilters(newFilters);
    sessionStorage.setItem('incidentFilters-incidentList', JSON.stringify(newFilters)); 

    updateProfile({
      ...profile,
      incidentsFilter: newFieldFilter.map(({ id, selected }) => ({ id, selected })),
    });
  };

  const handleResetFilters = () => {
    sessionStorage.removeItem('incidentFilters-incidentList');
    setFilters(defaultFilter.map(({ id }) => [
      id,
      getFilter(id),
    ]));
  };

  return (
    <div>
      <IncidentTable
        rows={incidentList}
        fieldFilter={fieldFilter as any}
        onUpdate={handleUpdate as any}
        onReset={handleResetFilters}
        initialPage={initialPage !== undefined ? +initialPage : undefined}
        initialRowsPerPage={initialRowsPerPage !== undefined ? +initialRowsPerPage : undefined}
      />
    </div>
  );
}
