import { Redirect, RouteComponentProps } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import DashboardBreadcrums from '../../components/DashboardBreadcrums';
import Section from '../../components/Section';
import { useInvestigations } from '../../providers/Investigations';
import { InvestigationDetails } from './components';

const useStyles = makeStyles(theme => {
  return ({
    pagePaddingTop: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      [theme.breakpoints.up('md')]: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
      },
    }, 
    sectionNoPaddingTop: {
      paddingTop: 0,
    },
  });
});

export default function Investigation({ match }: RouteComponentProps<{ id: string }>) {
  const { investigations } = useInvestigations();
  const classes = useStyles();

  const investigationId = match.params.id;
  const investigation = investigations.investigations.find(investigation => investigation.id === investigationId);

  if (!investigation) {
    return <Redirect to="/not-found" />;
  }

  return (
    <div>
      <Section className={classes.pagePaddingTop}>
        <DashboardBreadcrums
          breadcrums={[
            { name: 'Home', path: '/' },
            { name: 'Incidents', path: '/incidents' },
            { name: 'Investigation Details', path: `/investigation/${investigation.id}` },
          ]}
        />
      </Section>
      <Section className={classes.sectionNoPaddingTop}>
        <div>
          <InvestigationDetails investigation={investigation} />
        </div>
      </Section>
    </div>
  );
}
